import React from 'react';
import { Button, Input, message, Tooltip, Form, Switch, Transfer, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import AddProj from './AddProj';
import { PubSub } from 'pubsub-js';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            visible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
            status: -1
        }
        this.messageSocket = null;
        // 
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '项目名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '检查类型',
                dataIndex: 'type_name',
                align: 'center',
            }, {
                title: '录入类型',
                dataIndex: 'add_type',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.add_type == 1 && <Button type='primary' size='small'>选项</Button>}
                        {item.add_type == 2 && <Button type='primary' size='small' className='fu1'>数值</Button>}
                        {item.add_type == 3 && <Button type='primary' size='small' className='fu2'>文本</Button>}
                    </React.Fragment>
                )
            }, {
                title: '默认值',
                dataIndex: 'default_value',
                align: 'center',
            }, {
                title: '项目简介',
                dataIndex: 'interduce',
                align: 'center',
                ellipsis: {
                    showTitle: false,
                },
                render: interduce => (
                    <Tooltip title={interduce}>
                        <p className='xxxxx'>{interduce || '-'}</p>
                    </Tooltip>
                )
            }, {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                render: (_, item) => (
                    <Switch checked={item.status == 1 ? true : false} checkedChildren='启用' unCheckedChildren='禁用' onClick={() => {
                        let req = {
                            id: item.id,
                            status: item.status == 1 ? 0 : 1,
                        }
                        global.Ajax.hhtc_request('inspect/changeStatus', req).then(res => {
                            if (res.code == 1) {
                                this.refresh()
                            } else {
                                message.error(res.msg, 1.5)
                            }
                        })
                    }} />
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>修改</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    componentDidMount() {
        // 订阅//订阅 'message' 发布的发布的消息
        this.messageSocket = PubSub.subscribe('newCheckType', (topic, message) => {
            //message 为接收到的消息  这里进行业务处理
            this.getType()
        })
        // 获取检查类型
        this.getType()
    }
    componentWillUnmount() {
        // 取消订阅
        PubSub.unsubscribe(this.messageSocket);
    }
    getType() {
        global.Ajax.hhtc_request('inspect/addInspectGetType', {show_all: 1,}).then(res => {
            if (res.code == 1) {
                let arryNew = []
                res.data.map(item => {
                    arryNew.push(Object.assign({}, { value: item.id, label: item.name }))
                })
                this.setState({
                    checkTypeList: arryNew
                })
                this.seltRef && this.seltRef.setState({
                    data: arryNew
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            type_id: this.state.type_id || '',
            add_type: this.state.add_type || '',
            status: this.state.status,
            show_all: 1,
        }
        global.Ajax.hhtc_request('inspect/inspectItemList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
    }
    // 删除
    del(data) {
        var url = 'inspect/delInspectItem', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入项目名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Select
                        options={this.state.checkTypeList}
                        placeholder='请选择检查类型'
                        className='pubSelt marginr12 borderbai'
                        style={{ width: 160 }}
                        allowClear
                        onChange={(type_id) => {
                            type_id = type_id ? type_id : '';
                            this.setState({
                                type_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    {/* <SelectList
                        ref={dom => this.seltRef = dom}
                        placeholder='请选择检查类型'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(type_id) => {
                            type_id = type_id ? type_id : '';
                            this.setState({
                                type_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    /> */}
                    <SelectList
                        data={[
                            { value: 1, label: '选项' },
                            { value: 2, label: '数值' },
                            { value: 3, label: '文本' },
                        ]}
                        placeholder='请选择录入类型'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(add_type) => {
                            add_type = add_type ? add_type : '';
                            this.setState({
                                add_type,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        data={[
                            { value: 1, label: '启用' },
                            { value: 0, label: '禁用' },
                        ]}
                        placeholder='请选择状态'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(status) => {
                            status = status != undefined ? status : -1;
                            this.setState({
                                status,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加项目</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>体检项目列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加项目 */}
                <CustomModal
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}项目`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    width={800}
                    className='addprojbox'
                >
                    <AddProj
                        ref={dom => this.projRef = dom}
                        checkTypeList={this.state.checkTypeList}
                        type={this.state.type}
                        editData={this.state.editData || {}}
                        refresh={() => {
                            this.onCancel()
                            this.refresh()
                        }}
                    />
                </CustomModal>
                {/* 同步学校列表 */}
                <CustomModal
                    visible={this.state.visible}
                    width={800}
                    title='同步学校列表'
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addShow')}>添加学校</Button>
                    <div className='pubList' style={{ flex: 1, margin: '9px 0 0', height: 'auto', }}>
                        <div className='head flex'>
                            <p className='rowFlex'>学校名称</p>
                            <p className='rowFlex'>选项类型</p>
                            <p className='row15'>操作</p>
                        </div>
                        {[1, 1, 1, 1].map((item, index) => (
                            <div className='flex' key={String(index)}>
                                <p className='rowFlex'>选项名称</p>
                                <p className='rowFlex'>
                                    <Button type='primary' size='small'>午检</Button>
                                </p>
                                <div className='row15 flexAllCenter pubbtnbox'>
                                    <p onClick={this.del.bind(this)}>删除</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </CustomModal>
                {/* 添加学校 */}
                <CustomModal
                    width={720}
                    title='添加学校'
                    visible={this.state.addShow}
                    onCancel={this.onClose.bind(this)}
                    className='addbyybox'
                >
                    <Form
                        ref={dom => this.formAddRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='company'
                                label='选项类型'
                                rules={[{ required: true, message: '请选择选项类型！' }]}
                                className='item48'
                            >
                                <SelectList
                                    data={[]}
                                    placeholder='请选择选项类型'
                                />
                            </Form.Item>
                            <Form.Item
                                name='banji'
                                label='选择学校'
                                rules={[{ required: true, message: '请选择学校！' }]}
                            >
                                <Transfer
                                    dataSource={this.state.mockData}
                                    showSearch
                                    targetKeys={this.state.targetKeys}
                                    render={item => item.title}
                                    operations={['添加', '删除']}
                                    onChange={this.handleChange}
                                    selectAllLabels={[({ selectedCount, totalCount }) => (
                                        <span>待选学校({totalCount}所)</span>
                                    ), ({ selectedCount, totalCount }) => (
                                        <span>已选学校({selectedCount}所)</span>
                                    )]}
                                />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    handleChange = (targetKeys, direction, moveKeys) => {
        this.setState({ targetKeys });
    };
    onFinish = (req) => {
        console.log(req)
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
            visible: false,
        })
    }
    onClose() {
        this.setState({
            addShow: false,
        })
    }
}