import React from 'react';
import { Button, Input, message, Tooltip, Form, Switch, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: [
                { value: 1, label: '选项' },
                { value: 2, label: '数值' },
                { value: 3, label: '文本' },
            ],
            visible: false,
            checkTypeList: props.checkTypeList || [],
            xxList: [],
            typeList: []
        }
    }
    componentDidMount() {
        // 获取分类
        global.Ajax.hhtc_request('patrol/getPatrolSelectLists',{}).then(res=>{
            if(res.code==1){
                this.setState({
                    typeList: res.data,
                })
            }
        })
        // 
        if (this.props.type == 'edit') {
            let data = this.props.editData;
            let type_config = '';
            if (data.add_type == 1) {  //选项
                type_config = JSON.parse(data.type_config)
            } else if (data.add_type == 2) {  //数值
                type_config = JSON.parse(JSON.stringify(data.type_config.split(',')));
                type_config = {
                    min: type_config[0],
                    max: type_config[1],
                }
            } else if (data.add_type == 2) {  //文本
                type_config = JSON.parse(JSON.stringify(data.type_config.split(',')));
            }
            this.formRef.setFieldsValue({
                name: data.name,
                type_id: data.type_id,
                required: data.required == 1 ? true : false,
                add_type: data.add_type,
                type_config,
                default_value: data.default_value,
                status: data.status == 1 ? true : false,
                interduce: data.interduce,
            })
            this.setState({
                checktype: data.add_type,
                xxList: type_config
            })
        }
    }
    // 编辑
    edit(data, index) {
        this.setState({
            editData: data,
            type: 'edit',
            visible: true,
            editIndex: index
        })
        setTimeout(() => {
            this.formAddRef.setFieldsValue(data)
        }, 100);
    }
    // 删除
    del(index) {
        let xxList = this.state.xxList;
        xxList.splice(index, 1)
        this.setState({ xxList })
    }
    onValuesChange = (res, allValues) => {
        let key = (Object.keys(res))[0];
        if (this.state.checktype == 2) {
            if (key == 'type_config') {
                let values = allValues.type_config;
                let key1 = (Object.keys(res[key]))[0];
                let value = Helper.getNumber(res[key][key1]);
                values[key1] = value;
                this.formRef.setFieldsValue({
                    type_config: values
                })
            } else if (key == 'default_value') {
                let value = Helper.getNumber(res[key]);
                this.formRef.setFieldsValue({
                    [key]: value
                })
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{ flex: '96px' }}
                    ref={dom => this.formRef = dom}
                    initialValues={{
                        status: true,
                        required: true,
                    }}
                    onValuesChange={this.onValuesChange}
                >
                    <div className='flwp'>
                        <Form.Item
                            name='name'
                            label='项目名称'
                            rules={[{ required: true, message: '请输入项目名称！' }]}
                            className='item48'
                            // style={{ marginRight: '5%' }}
                        >
                            <Input autoComplete='off' placeholder='请输入项目名称' />
                        </Form.Item>
                        <Form.Item
                            name='type'
                            label='项目类型'
                            rules={[{ required: true, message: '请选择项目类型！' }]}
                            className='item48'
                        >
                            <Select
                                options={this.state.typeList}
                                fieldNames={{label: 'name',value: 'id'}}
                                placeholder='请选择项目类型'
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name='add_type'
                            label='录入类型'
                            rules={[{ required: true, message: '请选择录入类型！' }]}
                            className='item48'
                            style={{ marginRight: '2%' }}
                        >
                            <SelectList
                                data={this.state.type}
                                placeholder='请选择录入类型'
                                onChange={(checktype) => {
                                    this.setState({
                                        checktype,
                                        xxList: []
                                    })
                                    this.formRef.setFieldsValue({
                                        default_value: undefined,
                                    })
                                }}
                            />
                        </Form.Item>
                        {/* 录入类型--选项 */}
                        {this.state.checktype == 1 && <React.Fragment>
                            <Button type='primary' onClick={() => {
                                this.setState({ visible: true, })
                            }}>添加选项</Button>
                            <div style={{ width: '100%', marginBottom: 24 }}>
                                <div className='flex'>
                                    <p style={{ color: '#666', width: 96, textAlign: 'right' }}><span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: 4, lineHeight: '40px' }}>*</span>选项列表：</p>
                                    <div className='pubList' style={{ flex: 1, margin: 0, height: 'auto', }}>
                                        <div className='head flex'>
                                            <p className='rowFlex'>选项名称</p>
                                            <p className='rowFlex'>选项类型</p>
                                            <p className='rowFlex'>是否为默认值</p>
                                            <p className='rowFlex'>操作</p>
                                        </div>
                                        <div className='boxx'>
                                            {this.state.xxList.map((item, index) => (
                                                <div className='flex' key={String(index)}>
                                                    <p className='rowFlex'>{item.name}</p>
                                                    <p className='rowFlex'>
                                                        {item.type == 1 && <Button type='primary' size='small'>正常</Button>}
                                                        {item.type == 0 && <Button type='primary' size='small' className='fu1'>异常</Button>}
                                                    </p>
                                                    <p className='rowFlex'>
                                                        <Switch onChange={this.onChange.bind(this, index)} checked={item.defaultValue} checkedChildren='是' unCheckedChildren='否' />
                                                    </p>
                                                    <div className='rowFlex flexAllCenter pubbtnbox'>
                                                        <p onClick={this.edit.bind(this, item, index)}>修改</p>
                                                        <p onClick={this.del.bind(this, index)}>删除</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Form.Item
                                name='default_value'
                                rules={[{ required: true, message: '请添加默认值选项！' }]}
                                style={{ paddingLeft: 96, marginBottom: 0 }}
                                className='noHeight'
                            >
                                <Input placeholder='请添加选项' />
                            </Form.Item>
                        </React.Fragment>}
                        {/* 录入类型--数值 */}
                        {this.state.checktype == 2 && <React.Fragment>
                            <Form.Item
                                name='type_config'
                                label='正常值范围'
                                rules={[{ required: true, message: '请输入正常值范围！' }]}
                                className='item48'
                            >
                                <Input.Group compact>
                                    <Form.Item
                                        name={['type_config', 'min']}
                                        noStyle
                                    >
                                        <Input autoComplete='off' style={{ width: 121, textAlign: 'center', borderRight: 0 }} placeholder="最小值" />
                                    </Form.Item>
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none', background: 'transparent'
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Form.Item
                                        name={['type_config', 'max']}
                                        noStyle
                                    >
                                        <Input
                                            className="site-input-right"
                                            style={{
                                                width: 121,
                                                textAlign: 'center', borderLeft: 0
                                            }}
                                            placeholder="最大值" autoComplete='off'
                                        />
                                    </Form.Item>
                                </Input.Group>
                                {/* <Input autoComplete='off' placeholder='请输入正常值范围' /> */}
                            </Form.Item>
                            <Form.Item
                                name='default_value'
                                label='默认值'
                                // rules={[{ required: true, message: '请输入默认值！' }]}
                                className='item48'
                                rules={[{ required: true, message: '请输入默认值！' }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || (value * 1 >= (getFieldValue('type_config').min) * 1 && value * 1 <= (getFieldValue('type_config').max) * 1)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入正常值范围内的数值！'));
                                    }
                                })]}
                            >
                                <Input autoComplete='off' placeholder='请输入默认值' />
                            </Form.Item>
                        </React.Fragment>}
                        {/* 录入类型--文本 */}
                        {this.state.checktype == 3 && <React.Fragment>
                            <Form.Item
                                name='default_value'
                                label='文本'
                                rules={[{ required: true, message: '请输入文本内容！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入文本内容' />
                            </Form.Item>
                        </React.Fragment>}
                        <Form.Item
                            name='interduce'
                            label='项目简介'
                            rules={[{ required: true, message: '请输入项目简介' }]}
                            style={{ width: '100%' }}
                        >
                            <Input.TextArea rows={8} className='txtarea' placeholder='请输入项目简介' />
                        </Form.Item>
                        {/* <Form.Item
                            name='required'
                            label='是否必填'
                            style={{ width: '100%' }}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren='是' unCheckedChildren='否' />
                        </Form.Item> */}
                        <Form.Item
                            name='status'
                            label='是否启用'
                            style={{ width: '100%' }}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren='启用' unCheckedChildren='禁用' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </div>
                </Form>
                {/* 添加类型 */}
                <CustomModal
                    width={360}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}选项`}
                    onCancel={this.onCancel.bind(this)}
                    visible={this.state.visible}
                >
                    <Form
                        labelCol={{ flex: '82px' }}
                        initialValues={{
                            defaultValue: false,
                        }}
                        onFinish={this.add}
                        ref={dom => this.formAddRef = dom}
                    >
                        <Form.Item
                            name='type'
                            label='选项类型'
                            rules={[{ required: true, message: '请选择选项类型！' }]}
                        >
                            <SelectList
                                data={[
                                    { value: 1, label: '正常' },
                                    { value: 0, label: '异常' },
                                ]}
                                placeholder='请选择选项类型'
                            />
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label='选项名称'
                            rules={[{ required: true, message: '请输入选项名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入选项名称' />
                        </Form.Item>
                        <Form.Item
                            name='defaultValue'
                            label='默认值'
                            rules={[{ required: true, message: '选择默认值' }]}
                            style={{ width: '100%' }}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren='是' unCheckedChildren='否' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.required = req.required ? 1 : 0;
            req.status = req.status ? 1 : 0;
            if (req.add_type == 3) {  //文本
                req.type_config = '';
            } else if (req.add_type == 2) {  //数值
                req.type_config = req.type_config.min + ',' + req.type_config.max;
            } else if (req.add_type == 1) {  //选项
                req.type_config = JSON.stringify(this.state.xxList);
            }
            let url = 'patrol/addPatrol';
            if (this.props.type == 'edit') {
                url = 'patrol/editPatrol';
                req.id = this.props.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.props.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            visible: false,
            type: '',
            editData: {},
            editIndex: undefined,
        })
    }
    add = (req) => {
        console.log(req)
        let xxList = this.state.xxList, defaultValue = '';
        if (req.defaultValue) {
            defaultValue = req.name;
            this.formRef.setFieldsValue({
                default_value: req.name,
            })
            for (let i in xxList) {
                xxList[i].defaultValue = false;
            }
        }
        if (this.state.type == 'edit') {
            xxList[this.state.editIndex] = req;
        } else {
            xxList.push(req);
        }
        this.setState({ xxList, defaultValue })
        this.onCancel()
    }
    onChange(index) {
        let xxList = this.state.xxList;
        for (let i in xxList) {
            xxList[i].defaultValue = false;
        }
        xxList[index].defaultValue = true;
        console.log(xxList[index])
        this.formRef.setFieldsValue({
            default_value: xxList[index].name,
        })
        this.setState({ xxList })
    }
}