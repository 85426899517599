import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, TimePicker,Modal } from 'antd'
import moment from 'moment'

const format = 'HH:mm';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}
	componentDidMount(){
		this.refresh()
	}
	refresh(){
		global.Ajax.hhtc_request('timeset/getTimeSetList',{}).then(res=>{
			if(res.code==1&&res.data.length>0){
				this.formRef.setFieldsValue({
					time1: [moment(res.data[0].morning_stime,format),moment(res.data[0].morning_etime,format)],
					time2: [moment(res.data[0].noon_stime,format),moment(res.data[0].noon_etime,format)]
				})
			}
		})
	}
	save() {
		this.formRef.submit()
	}
	onFinish=(req)=>{
		this.setState({
			loading: true,
		},()=>{
			req.morning_stime=req.time1[0].format('HH:mm');
			req.morning_etime=req.time1[1].format('HH:mm');
			req.noon_stime=req.time2[0].format('HH:mm');
			req.noon_etime=req.time2[1].format('HH:mm');
			delete req.time1;
			delete req.time2;
			global.Ajax.hhtc_request('timeset/setconfig',req).then(res=>{
				if(res.code==1){
					message.success(res.msg,1.5)
				}else{
					Modal.error({
						content: res.msg
					})
				}
				setTimeout(() => {
					this.setState({
						loading: false,
					})
				}, 1500);
			})
		})
	}
	render() {
		return (
			<div className='uploadBox'>
				<div className='editPwdBox box'>
					<h2 className='pubTit'>晨午检时间配置</h2>
					<Form
						onFinish={this.onFinish}
						ref={dom => this.formRef = dom}
					>
						<Form.Item
							name='time1'
							label='晨检时间'
							rules={[{ required: true, message: '请设置晨检时间！' }]}
						>
							<TimePicker.RangePicker format={format} />
						</Form.Item>
						<Form.Item
							name='time2'
							label='午检时间'
							rules={[{ required: true, message: '请设置午检时间！' }]}
						>
							<TimePicker.RangePicker format={format} />
						</Form.Item>
					</Form>
					{/* <div className='flexCenter'>
						<p>晨检时间：</p>

					</div>
					<div className='flexCenter'>
						<p>午检时间：</p>
						<TimePicker.RangePicker format={format} />
					</div> */}
					<div className='btnbox flexCenter flexEnd' style={{ marginTop: 106 }}>
						<Button className='huibtn marginr8' onClick={this.refresh.bind(this)}>重置</Button>
						<Button loading={this.state.loading} type='primary' onClick={this.save.bind(this, 1)}>保存</Button>
					</div>
				</div>
			</div>
		)
	}
}