import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox } from 'antd'
import Helper from '../../class/Helper';
import { exportWord } from 'mhtml-to-word';  //导出word插件

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: props.data,
            list: []
        }
    }
    componentDidMount() {
        global.Ajax.hhtc_request('inspectnotice/logItemList', { log_id: this.props.data.log_id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    list: res.data,
                })
            }
        })
    }
    render() {
        let { info, list } = this.state;
        return (
            <div id='huahai1'>
                <div id='div_content' className='exportbox'>
                    <h1 style={{ fontSize: 20, fontWeight: 'bold', color: '#2C2C2C', textAlign: 'center', fontFamily: '微软雅黑', marginBottom: 30 }}>体检报告</h1>
                    <table id='table1' style={{ width: '100%', borderCollapse: 'collapse', verticalAlign: 'middle', fontFamily: '微软雅黑' }}>
                        <tbody>
                            <tr>
                                <td colSpan={9} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', borderRight: '1px solid #333', fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'center', height: '32px' }}>基本信息</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', width: '80px' }}>姓名</td>
                                <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>{info.student_name}</td>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>性别</td>
                                <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>{Helper.getSex(info.idcard)}</td>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>年龄</td>
                                <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', borderRight: '1px solid #333' }}>{Helper.getAge(info.idcard)}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>就读班级</td>
                                <td colSpan={5} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>{info.school_name}{info.class_name}</td>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>体检日期</td>
                                <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', borderRight: '1px solid #333' }}>{info.date}</td>
                            </tr>
                            {/* <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', width: '80px' }}>体重(kg)</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>20</td>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>身高(cm)</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>100</td>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>身高体重比(BMI)</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', borderRight: '1px solid #333' }}>1.22</td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>体格发育评价</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10 }}>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginBottom: -3 }}></span>
                                <span>正常</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>低体重</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>消瘦</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>生长缓慢</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>超重</span>
                            </td>
                        </tr> */}
                            {this.state.list.map((item, index) => (
                                <React.Fragment key={String(index)}>
                                    <tr>
                                        <td colSpan={9} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', borderRight: '1px solid #333', fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'center', height: '32px' }}>{item.type_name}</td>
                                    </tr>
                                    {item.datas.map((row, k) => (
                                        <tr key={'d' + k}>
                                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', width: '120px' }}>{row.inspect_name}</td>
                                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10 }}>{row.inspect_value}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}

                            {/* <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', borderBottom: '1px solid #333', width: '200px' }}>视力</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10, borderBottom: '1px solid #333' }}>左 5.0&emsp;/&emsp;右 5.1</td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', borderBottom: '1px solid #333', width: '200px' }}>龋齿数&emsp;/&emsp;牙齿数（颗）</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10, borderBottom: '1px solid #333' }}>0&emsp;/&emsp;23</td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>胸部</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10 }}>
                                <img alt='' src={require('../../imgs/gou.png').default} style={{ width: 16, marginRight: 4 }} />
                                <span>未见异常</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>血红蛋白值</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10 }}>
                                <span style={{ display: 'inline-block', borderBottom: '1px solid #333', width: 64 }}>187</span>
                                <span>g/L</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginBottom: -3, marginLeft: 24, }}></span>
                                <span>未检查</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>拒检查</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>未出结果</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>结果丢失</span>
                            </td>
                        </tr> */}
                            {/* <tr>
                                <td colSpan={9} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', borderRight: '1px solid #333', fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'center', height: '32px' }}>其他信息</td>
                            </tr> */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', borderBottom: '1px solid #333' }}>体检医生</td>
                                <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10, borderBottom: '1px solid #333' }}>{info.doctor_names}</td>
                            </tr>


                            {/* <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>额温</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>36.7°</td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>口检</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>红眼</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>指甲</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>腮部</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>精神</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>咳嗽</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px'}}>外伤</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10}}>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginBottom: -3}}></span>
                                <span>未见异常</span>
                                <span style={{display: 'inline-block',width: 16,height: 16,border: '1px solid #000',marginRight: 4,marginLeft: 24,marginBottom: -3}}></span>
                                <span>异常</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',textAlign: 'center',height: '32px',borderBottom: '1px solid #333',width: '200px'}}>检查人员&emsp;/&emsp;检查日期</td>
                            <td colSpan={8} style={{borderLeft: '1px solid #333',borderTop: '1px solid #333',fontSize: 14,color: '#333',borderRight: '1px solid #333',paddingLeft: 10,borderBottom: '1px solid #333'}}>栗子&emsp;/&emsp;2020-12-23 12:23:34</td>
                        </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    //  获取当前使用浏览器
    getExplorer() {
        var explorer = window.navigator.userAgent;
        //ie 
        if (explorer.indexOf("MSIE") >= 0) {
            return 'ie';
        } else if (explorer.indexOf("Firefox") >= 0) {
            return 'Firefox';
        } else if (explorer.indexOf("Chrome") >= 0) {
            return 'Chrome';
        } else if (explorer.indexOf("Opera") >= 0) {
            return 'Opera';
        } else if (explorer.indexOf("Safari") >= 0) {
            return 'Safari';
        }
    }
    export = () => {
        if (this.getExplorer() == 'ie' || this.getExplorer() == undefined) {  //ie浏览器
            this.exportWordIE()
        } else {
            exportWord({
                selector: '.exportbox',
                style: "",
                filename: '体检报告',
            })
        }
    }
    exportWordIE() {
        var oWD;
        try {
            oWD = new window.ActiveXObject("Word.Application");
        } catch (e) {
            alert("无法启动Excel!请修改IE配置\n\n如果您确定您的电脑中已经安装了Excel，" + "那么请调整IE的安全级别。\n\n具体操作：\n\n" + "工具 → Internet选项 → 安全 → 自定义级别 → 对没有标记为安全的ActiveX进行初始化和脚本运行 → 启用");
            return false;
        }
        var oDC = oWD.Documents.Add("", 0, 1);
        var oRange = oDC.Range(0, 1);

        var sel = document.body.createTextRange();
        var curTbl = document.getElementById('div_content');
        sel.moveToElementText(curTbl);
        sel.select();
        sel.execCommand('Copy');
        oRange.Paste();
        oWD.Application.Visible = true;
        setTimeout(() => {
            this.props.onCancel()
        }, 200);
    }
}