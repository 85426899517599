import React from 'react';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils'
import UploadList from './UploadList';
import Table from 'braft-extensions/dist/table';
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'
var mammoth = require("mammoth");

const options = {
    defaultColumns: 4, // 默认列数
    defaultRows: 5, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: ['id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: ['id-2']  // 指定该模块对哪些BraftEditor无效
};
BraftEditor.use(Table(options));

export default class Ueditor extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			editorState: BraftEditor.createEditorState(null),
			fullControls: [
				'fullscreen', 'undo', 'redo', 'separator',
				'font-size', 'line-height', 'letter-spacing', 'separator',
				'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
				'superscript', 'subscript', 'remove-styles', 'emoji', 'separator',
				'text-indent', 'text-align', 'separator',
				'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
				'link', 'separator',
				'hr', 'separator',
				'table','separator',
				'clear','separator',
			],
			controls: [{
				key: "fullscreen",
				title: "开启全屏以使用更多功能"
			}, 'bold', 'italic', 'text-color'],
			isFullscreen: false
		}
	}
	uploadHandler = (url) => {
		this.setState({
			editorState: ContentUtils.insertMedias(this.state.editorState, [{
				type: 'IMAGE',
				url: url
			}])
		})

	}
	getContent() {
		return this.state.editorState.toHTML();
	}
	setContent(html) {
		this.setState({
			editorState: BraftEditor.createEditorState(html)
		})
	}
	render() {
		const extendControls = [
			{
				key: 'antd-uploader',
				type: 'component',
				component: (
					<button type="button" className="control-item button upload-button" data-title="插入图片" onClick={()=>{
						this.uplist.setState({
							visible: true,
						})
					}}>插入图片</button>
				)
			}
		]
		return (
			<React.Fragment>
				<BraftEditor
					className="no-drag"
					ref="ueditor"
					value={this.state.editorState}
					controls={this.state.isFullscreen ? this.state.fullControls : this.state.controls}
					extendControls={extendControls}
					placeholder="全屏输入功能更佳"
					onChange={(e) => {
						// var rows = JSON.parse(e.toRAW());
						// console.log(rows)
						// if (rows.entityMap) {
						// 	for (var i in rows.entityMap) {
						// 		var image = rows.entityMap[i].data.url;
						// 		var houz=(image.split('.'))[(image.split('.')).length-1];
						// 		var options = {
						// 			convertImage: mammoth.images.imgElement(function(image) {
						// 				return image.read("base64").then(function(imageBuffer) {
						// 					console.log("data:" + houz + ";base64," + imageBuffer)
						// 					return {
						// 						src: "data:" + houz + ";base64," + imageBuffer
						// 					};
						// 				});
						// 			})
						// 		}
						// 		console.log(image)
						// 		console.log(options)
						// 	}
						// }
						this.setState({
							editorState: e
						},()=>{
							this.props.changeValue&&this.props.changeValue(this.state.editorState)
						})
					}}
					onFullscreen={(e) => {
						this.setState({
							isFullscreen: e
						})
					}}
					{... this.props}
				/>
				{/* 文件库 */}
				<UploadList fileNum={1} ref={dom => this.uplist = dom} type={1} onOk={this.onOk.bind(this)} />
			</React.Fragment>
		)
	}
	onOk(data){
		this.uploadHandler(data[0])
        this.uplist.onCancel()
	}
}