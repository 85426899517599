import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';

const { RangePicker } = DatePicker;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '类型',
                dataIndex: 'type',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.type == 1 && <Button type='primary' size='small'>晨检</Button>}
                        {item.type == 2 && <Button type='primary' size='small' className='fu1'>午检</Button>}
                    </React.Fragment>
                )
            }, {
                title: '学生人数',
                dataIndex: 'student_count',
                align: 'center',
            }, {
                title: '请假人数',
                dataIndex: 'leave_count',
                align: 'center',
            }, {
                title: '应检查人数',
                dataIndex: 'check_count',
                align: 'center',
            }, {
                title: '未检查人数',
                dataIndex: 'unchecked_count',
                align: 'center',
            }, {
                title: '异常人数',
                dataIndex: 'error_count',
                align: 'center',
            }, {
                title: '检查日期',
                dataIndex: 'atime',
                align: 'center',
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            school_id: this.state.school_id || '',
            type: this.state.type || '',
            status: this.state.status || '',
            date: this.state.date || '',
        }
        global.Ajax.hhtc_request('dayinspect/schoolDayInspectList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(school_id) => {
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <DatePicker
                        className='borderbai marginr12'
                        onChange={(date, dateString) => {
                            this.setState({
                                date: dateString
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        data={[
                            { value: 1, label: '晨检' },
                            { value: 2, label: '午检' },
                        ]}
                        placeholder='请选择类型'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(type) => {
                            this.setState({
                                type,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    {/* <SelectList
                        data={[
                            {value: 0,label: '正常'},
                            {value: 1,label: '异常'},
                        ]}
                        placeholder='请选择状态'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(status) => {
                            this.setState({
                                status,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    /> */}
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学校晨午检记录</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
            </React.Fragment>
        )
    }
}