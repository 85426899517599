import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
        }
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(page, callback) {
        var orderBy = '';
        if (this.state.orderBy != '') {
            orderBy = this.state.orderBy;
        }
        var req = {
            page: page,
            size: this.state.size,
            orderBy,
        }
        global.Ajax.hhtc_request('physique/phyList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='rowFlex'>年龄</p>
                <p className='rowFlex'>月龄</p>
                <p className='rowFlex'>性别</p>
                <p className='rowFlex'>类型</p>
                <p className='rowFlex'>-3SD</p>
                <p className='rowFlex'>-2SD</p>
                <p className='rowFlex'>-1SD</p>
                <p className='rowFlex'>中位数</p>
                <p className='rowFlex'>+1SD</p>
                <p className='rowFlex'>+2SD</p>
                <p className='rowFlex'>+3SD</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p className='rowFlex'>{item.month_age == 0 ? '出生' : (item.month_age % 12 == 0 ? item.month_age / 12 + '岁' : '')}</p>
                        <p className='rowFlex'>{item.month_age}</p>
                        <p className='rowFlex'>{item.sex==1?'男':'女'}</p>
                        <p className='rowFlex'>{item.py_type==1?'身高':'体重'}</p>
                        <p className='rowFlex'>{item.minus3sd}</p>
                        <p className='rowFlex'>{item.minus2sd}</p>
                        <p className='rowFlex'>{item.minus1sd}</p>
                        <p className='rowFlex'>{item.sd}</p>
                        <p className='rowFlex'>{item.int1sd}</p>
                        <p className='rowFlex'>{item.int2sd}</p>
                        <p className='rowFlex'>{item.int3sd}</p>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    render() {
        return (
            <React.Fragment>
                <div className='bgbai'>
                    <h2 className='pubTit'>体格数据</h2>
                    <TabbleList
                        ref={dom => this.tabblelist = dom}
                        size={this.state.size}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        renderHead={this.renderHead.bind(this)}  //表头
                        renderItem={this.renderItem.bind(this)}  //表内容
                    />
                </div>
            </React.Fragment>
        )
    }
}