import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, DatePicker, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import Detail from './Detail';  //检查项目列表
import CustomTable from '../../common/Table';
import Excel from "exceljs";

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            visible: false,
            editData: {}
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: (_, item) => (
                    <Tooltip title={item.school_name}>
                        <p className='xxxxx'>{item.school_name}</p>
                    </Tooltip>
                )
            }, {
                title: '行政区域',
                dataIndex: 'address',
                align: 'center',
            }, {
                title: '检查日期',
                dataIndex: 'date',
                align: 'center',
            }, {
                title: '合格类别数',
                dataIndex: 'qualified',
                align: 'center',
            }, {
                title: '不合格类别数',
                dataIndex: 'unqualified',
                align: 'center',
            }, {
                title: '提交时间',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '提交人',
                dataIndex: 'teacher_name',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.chahgeVisible.bind(this, 'visible', item)}>检查项目详情</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || '',
        }
        global.Ajax.hhtc_request('patrol/schoolPatrolLog', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    chahgeVisible(key, data) {
        this.setState({
            [key]: true,
            editData: data
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入项目名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allschool'
                        className='borderbai marginr12'
                        placeholder='请选择学校'
                        style={{ width: 160 }}
                        onChange={(school_id) => {
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={() => {
                        // 获取分类
                        global.Ajax.hhtc_request('patrol/getPatrolSelectLists', {}).then(res => {
                            if (res.code == 1) {
                                this.setState({
                                    typeList: res.data,
                                })
                            }
                        })
                        this.setState({
                            exportVisible: true,
                        })
                    }}>导出</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>检查报告记录列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 检查项目列表 */}
                <CustomModal
                    visible={this.state.visible}
                    title={`详情`}
                    width={942}
                    onCancel={this.onCancel.bind(this)}
                    className='infoboxx addprojbox'
                >
                    <Detail data={this.state.editData} />
                    <Button onClick={this.onCancel.bind(this)} type='primary' style={{ display: 'block', margin: '30px 0 0 auto' }}>确定</Button>
                </CustomModal>
                {/* 导出 */}
                <CustomModal
                    visible={this.state.exportVisible}
                    title='导出'
                    width={520}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        labelCol={{ flex: '82px' }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name='type'
                            label='项目类型'
                            rules={[{ required: true, message: '请选择项目类型！' }]}
                            className='row10'
                        >
                            <Select
                                options={this.state.typeList}
                                fieldNames={{ label: 'name', value: 'id' }}
                                placeholder='请选择项目类型'
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name='school_id'
                            label='学校'
                            rules={[{ required: true, message: '请选择学校！' }]}
                            className='row10'
                        >
                            <SelectList
                                type='allschool'
                                placeholder='请选择学校'
                            />
                        </Form.Item>
                        <Form.Item
                            name='month'
                            label='日期'
                            rules={[{ required: true, message: '请选择日期！' }]}
                            className='row10'
                        >
                            <DatePicker picker='month' style={{ width: '100%' }} />
                        </Form.Item>
                        <Button loading={this.state.loading} type='primary' htmlType='submit' style={{ display: 'block', marginLeft: 'auto' }}>确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.month = req.month.format('YYYY-MM');
            global.Ajax.hhtc_request('patrol/exportReport', req).then(res => {
                if (res.code == 1) {
                    if (res.data.logs.length > 0) {
                        let type_name = (this.state.typeList.find(item => item.id == req.type)).name;
                        let title = `${res.data.logs[0].school_name}${req.month}月${type_name}记录`
                        this.exportData(res.data.keys, res.data.logs, title)
                        // setTimeout(() => {
                        //     this.setState({
                        //         exportVisible: false,
                        //     })
                        // }, 100);
                    }else{
                        message.error('暂无数据',1.5)
                    }
                } else {
                    message.error(res.msg, 1.5)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    exportData(keys, lists, title) {
        const workbook = new Excel.Workbook();
        workbook.creator = "admin";
        workbook.lastModifiedBy = "admin";
        workbook.created = new Date();
        workbook.modified = new Date();
        // 添加工作表
        let sheet = workbook.addWorksheet("sheet", { properties: { defaultRowHeight: 21 } });
        const headStyle = {
            alignment: {
                horizontal: "center",
                vertical: "middle"
            }, font: {
                name: '微软雅黑',
                size: 12,
                bold: false,
            },
        }
        let columns = [];
        for (let i in keys) {
            let width=40;
            if(keys[i].name=='检查人'||keys[i].name=='日期'){
                width=20;
            }
            columns.push({
                header: keys[i].name, key: keys[i].key, width: width, style: headStyle
            })
        }
        sheet.columns = columns;
        sheet.insertRow(1, [title]);
        sheet.mergeCells('A1:' + String.fromCharCode(columns.length + 'A'.charCodeAt() - 1) + '1');
        sheet.addRows(lists);
        workbook.xlsx.writeBuffer().then((buffer) => {
            this.writeFile(`${title}.xlsx`, buffer);
        });
    }
    writeFile(fileName, content) {
        let a = document.createElement("a");
        let blob = new Blob([content], { type: "text/plain" });

        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.click();
        this.setState({ exportVisible: false, })
    }
    onCancel() {
        this.setState({
            visible: false,
            exportVisible: false,
        })
    }
}