import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [],
            targetKeys: [],
            keyword: '',
            // school_id: props.schoolId
            school_id: undefined
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '手机号',
                dataIndex: 'mobile',
                align: 'center',
            }, {
                title: '保育员姓名',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '所属学校',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '下属班级',
                dataIndex: 'class_names',
                align: 'center',
                className: 'name',
                render: (_, item) => (
                    <span onClick={this.go.bind(this, 'class', item)}>{item.class_names}</span>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>编辑</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    getAllClass(id, targetKeys) {
        global.Ajax.hhtc_request('teacher/addGetClass', { school_id: id }).then(res => {
            if (res.code == 1) {
                let arryNew = []
                res.data.map(item => {
                    arryNew.push(Object.assign({}, { key: item.class_id, title: item.school_name + item.class_name }))
                })
                this.setState({
                    // classList: arryNew,
                    mockData: arryNew,
                    targetKeys: targetKeys || [],
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            school_id: this.state.school_id || '',
            pword: this.state.keyword

        }
        global.Ajax.hhtc_request('teacher/teacherList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row15'>手机号</p>
                <p className='row15'>保育员名称</p>
                <p className='row15'>所属学校</p>
                <p className='rowFlex'>下属班级</p>
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <p className='row15'>{item.mobile}</p>
                        <p className='row15'>{item.name}</p>
                        <p className='row15'>{item.school_name}</p>
                        <p className='rowFlex name'>
                            <span onClick={this.go.bind(this, 'class', item)}>{item.class_names}</span>
                        </p>
                        <div className='row15 flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    go(type, data) {
        let row, item = this.props.schoolRow;
        if (type == 'class') {
            row = this.props.classPageId;
            this.props.onChange({ row, item, teacher_id: data.name })
        }
    }
    // 编辑
    edit(data) {
        this.getAllClass(data.school_id, data.class_ids.split(',').map(Number));
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
        setTimeout(() => {
            this.formRef.setFieldsValue({
                name: data.name,
                mobile: data.mobile,
                password: '',
                school_id: data.school_id,
                class_ids: data.class_ids.split(',').map(Number),
            })
        }, 100);
    }
    // 删除
    del(data) {
        var url = 'teacher/delTeacher', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入保育员姓名/手机号查询'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                keyword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                        style={{ width: 260 }}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        value={this.state.school_id}
                        onChange={(school_id) => {
                            school_id = school_id || '';
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>新增保育员</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>保育员列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    width={720}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}保育员`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    className='addbyybox'
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='name'
                                label='姓名'
                                rules={[{ required: true, message: '请输入姓名！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入姓名' />
                            </Form.Item>
                            <Form.Item
                                name='mobile'
                                label='手机号'
                                className='item48'
                                rules={[{ required: true, message: '请输入正确的手机号！' }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                                        if (!value || myreg.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入正确的手机号！'));
                                    }
                                })]}
                            >
                                <Input maxLength={11} autoComplete='off' placeholder='请输入联系电话' />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                label='登录密码'
                                className='item48'
                            >
                                <Input.Password autoComplete='new-password' placeholder='非必填，默认密码为123456' />
                            </Form.Item>
                            <Form.Item
                                name='school_id'
                                label='所属学校'
                                rules={[{ required: true, message: '请选择所属学校！' }]}
                                className='item48'
                            >
                                <SelectList
                                    type='allschool'
                                    placeholder='请选择所属学校'
                                    allowClear={false}
                                    onChange={(id) => {
                                        this.getAllClass(id)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name='class_ids'
                                label='选择班级'
                                rules={[{ required: true, message: '请选择班级！' }]}
                            >
                                <Transfer
                                    dataSource={this.state.mockData}
                                    showSearch
                                    targetKeys={this.state.targetKeys}
                                    render={item => item.title}
                                    operations={['添加', '删除']}
                                    onChange={this.handleChange}
                                    selectAllLabels={[({ selectedCount, totalCount }) => (
                                        <span>可选班级({totalCount}个)</span>
                                    ), ({ selectedCount, totalCount }) => (
                                        <span>已选班级({this.state.targetKeys.length || 0}个)</span>
                                    )]}
                                />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    handleChange = (targetKeys, direction, moveKeys) => {
        this.setState({ targetKeys });
    };
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'teacher/addTeacher';
            if (this.state.type == 'edit') {
                url = 'teacher/editTeacher';
                req.id = this.state.editData.id;
            }
            req.password = req.password || '';
            req.class_ids = req.class_ids.join(',');
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            mockData: [],
            editData: {},
            type: ''
        })
    }
}