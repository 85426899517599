import React from 'react';
import { Button, Input, message, Tooltip, Form, Switch, Upload, DatePicker, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import QRCode from 'qrcode.react';
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import moment from 'moment'
import GuardianList from './GuardianList';  //监护人列表
import CustomTable from '../../common/Table';
import * as echarts from 'echarts';

const { Dragger } = Upload;
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            // pword: props.teacherName,
            // class_id: props.classId,
            // school_id: props.school_id,
            pword: undefined,
            class_id: undefined,
            school_id: undefined,
            src: '',
            list: [
                { src: 'https://www.baidu.com/', },
                { src: 'https://ant.design/index-cn', }
            ],
            fileList: [],
            upVisible: false,
            lists: [],
            editData: {},
            code_url: '',
            classLists: []
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学生姓名',
                dataIndex: 'name',
                align: 'center',
                className: 'name',
                render: (_, item) => (
                    <span onClick={() => {
                        let code_url = JSON.stringify({
                            student_id: item.id,
                            class_id: item.class_id,
                            student_name: item.name,
                            class_name: item.class_name,
                            idcard: item.idcard,
                            school_id: item.school_id,
                        })
                        this.setState({
                            code_url,
                            editData: item,
                            ewmVisible: true,
                        })
                    }}>{item.name}</span>
                )
            }, {
                title: '性别',
                dataIndex: 'sex',
                align: 'center',
                render: sex => `${sex == 1 ? '男' : '女'}`
            }, {
                title: '年龄',
                dataIndex: 'student_name',
                align: 'center',
                render: (_, item) => `${Helper.getAge(item.idcard)}岁`
            }, {
                title: '身份证号',
                dataIndex: 'idcard',
                align: 'center',
            }, {
                title: '所属学校',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '所属班级',
                dataIndex: 'class_name',
                align: 'center',
            }, {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                render: status => (
                    <span className={`${status == 1 ? 'color1' : 'color3'}`}>{status == 1 ? '正常' : '请假'}</span>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={() => {
                            this.setState({
                                editData: item,
                                show: true,
                            })
                        }}>监护人列表</p>
                        <p onClick={() => {
                            this.setState({
                                szVisible: true,
                            }, () => {
                                setTimeout(() => {
                                    this.getSgz(item.id)
                                }, 100);
                            })
                        }}>生长情况</p>
                        <p onClick={this.edit.bind(this, item)}>修改</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    getSgz(student_id) {
        global.Ajax.hhtc_request('student/getFayuInfo', { student_id }).then(res => {
            if (res.code == 1) {
                let chartDom = document.getElementById('box11');
                let myChart = echarts.init(chartDom);
                myChart.setOption({
                    title: {
                        left: '15',
                        top: '0'
                    },
                    legend: {
                        data: ['访问量'],
                        y: 'bottom'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c}"
                    },
                    xAxis: {
                        type: 'category',
                        data: res.data.titles
                        // data: ['1号','2号','2号','2号']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '身高',
                        type: 'line',
                        data: res.data.sgs
                        // data: [1,33,2,56]
                    }, {
                        name: '体重',
                        type: 'line',
                        data: res.data.tzs
                        // data: [6,23,2,4]
                    }]
                });
            }
        })
    }
    getAllClass(id, type) {
        global.Ajax.hhtc_request('teacher/addGetClass', { school_id: id }).then(res => {
            if (res.code == 1) {
                let arryNew = []
                res.data.map(item => {
                    arryNew.push(Object.assign({}, { value: item.class_id, label: item.class_name }))
                })
                // this.setState({
                //     classLis1t: arryNew,
                // })
                if (type == 'search') {
                    this.setState({
                        classLists: arryNew,
                    })
                    // this.seltClassRef && this.seltClassRef.setState({
                    //     data: arryNew
                    // })
                } else {
                    this.seltRef && this.seltRef.setState({
                        data: arryNew
                    })
                }
            } else {
                this.seltRef && this.seltRef.setState({
                    data: []
                })
                this.seltClassRef && this.seltClassRef.setState({
                    data: []
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || '',
            class_id: this.state.class_id || ''
        }
        global.Ajax.hhtc_request('student/studentList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor row1'>
                    学号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row1'>学生姓名</p>
                <p className='row_6'>性别</p>
                <p className='row_6'>年龄</p>
                <p className='row15'>身份证号</p>
                <p className='row15'>所属学校</p>
                <p className='rowFlex'>所属班级</p>
                <p className='row_6'>状态</p>
                <p className='row2'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p className='row1'>{item.student_no}</p>
                        <p className='row1'>{item.name}</p>
                        <p className='row_6'>{Helper.getSex(item.idcard)}</p>
                        <p className='row_6'>{Helper.getAge(item.idcard)}</p>
                        <p className='row15'>{item.idcard}</p>
                        <p className='row15'>{item.school_name}</p>
                        <Tooltip title={item.class_name}>
                            <p className='rowFlex'>{item.class_name || '-'}</p>
                        </Tooltip>
                        <p className={`row_6 ${item.status == 0 ? 'color1' : 'color3'}`}>{item.status == 1 ? '正常' : '请假'}</p>
                        <div className='row2 flexAllCenter pubbtnbox'>
                            <p onClick={() => {
                                this.setState({
                                    editData: item,
                                    show: true,
                                })
                            }}>监护人列表</p>
                            <p onClick={() => {
                                let code_url = JSON.stringify({
                                    student_id: item.id,
                                    class_id: item.class_id,
                                    student_name: item.name,
                                    class_name: item.class_name,
                                    idcard: item.idcard,
                                    school_id: item.school_id,
                                })
                                this.setState({
                                    code_url,
                                    editData: item,
                                    ewmVisible: true,
                                })
                            }}>查看二维码</p>
                            <p onClick={this.edit.bind(this, item)}>修改</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    // 编辑
    edit(data) {
        this.getAllClass(data.school_id)
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
        setTimeout(() => {
            let rows = JSON.parse(JSON.stringify(data));
            rows.status = rows.status == 1 ? true : false;
            rows.birthday = rows.birthday ? moment(rows.birthday, 'YYYY-MM-DD') : undefined;
            this.formRef.setFieldsValue(rows)
        }, 100);
    }
    // 删除
    del(data) {
        var url = 'student/delStudent', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    DPR() { // 获取设备dpi
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        }
        return 1;
    }
    async toBase64(data,callback) {
        let content = document.getElementById('qrCode');
        const canvas = await html2canvas(content, {
            useCORS: true, // 【重要】开启跨域配置
            allowTaint: true, // 允许跨域图片
            // foreignObjectRendering: true, // 是否在浏览器支持的情况下使用ForeignObject渲染
            // async: false, // 是否异步解析和呈现元素
            /** 以下字段必填 */
            backgroundColor: null, // 画布背景颜色（若为null，则是透明色）Canvas background color, if none is specified in DOM. Set null for transparent
            scale: this.DPR(), // 处理模糊问题
            dpi: 500, // 处理模糊问题
        })
        const result = canvas.toDataURL("png"); // 获取到图片地址(base64格式)
        let html = `<div style="text-align: center;paddingg-top: 40px;">
            <img src="${result}" style="width: 90px;height: 90px;" />
            <p style="font-size: 14px;color: #333;margin: 4px 0 0;">姓名：${data.name}</p>
            <p style="font-size: 14px;color: #333;margin: 2px 0 0;">班级：${data.class_name}</p>
            <p style="font-size: 14px;color: #333;margin: 2px 0 0;">学号：${data.student_no}</p>
        </div>`;
        // console.log(html);
        // callback()
        // let html=document.getElementById('eee').innerHTML;
        data.code_url=result;
        window.printtt(data,()=>{
            callback()
        })
        // window.prn1_print(html, () => {
        //     callback()
        // })
    }
    onPrint() {
        if(!this.state.class_id||this.state.class_id==''){
            return message.info('请选择班级')
        }
        this.setState({
            loading: true,
        }, () => {
            let req = {
                page: 1,
                size: 999999,
                orderBy: '',
                pword: this.state.pword || '',
                school_id: this.state.school_id || '',
                class_id: this.state.class_id || ''
            }
            global.Ajax.hhtc_request('student/studentList', req).then(res => {
                if (res.code == 1) {
                    let _this = this;
                    let i = 0;
                    let list = res.data.datas;
                    if (i < list.length) {
                        let item = list[i];
                        let code_url = JSON.stringify({
                            student_id: item.id,
                            class_id: item.class_id,
                            student_name: item.name,
                            class_name: item.class_name,
                            idcard: item.idcard,
                            school_id: item.school_id,
                        })
                        down(item,code_url)
                    }
                    function down(data,src) {
                        _this.setState({
                            src
                        }, () => {
                            setTimeout(() => {
                                _this.toBase64(data,() => {
                                    i += 1;
                                    if (i < list.length) {
                                        let item = list[i];
                                        let code_url = JSON.stringify({
                                            student_id: item.id,
                                            class_id: item.class_id,
                                            student_name: item.name,
                                            class_name: item.class_name,
                                            idcard: item.idcard,
                                            school_id: item.school_id,
                                        })
                                        down(item,code_url)
                                        // down(list[i].src)
                                    } else {
                                        _this.setState({
                                            loading: false,
                                        })
                                        message.success("打印成功！");
                                    }
                                })
                            }, 1000);
                        })
                    }

                }
            })
        })



    }
    formatDate(numb, format) {
        const old = numb - 1;
        const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
        const time = new Date(1900, 0, old, 0, 0, t)
        const year = time.getFullYear();
        const month = time.getMonth() + 1;
        const date = time.getDate();
        return year + format + (month < 10 ? '0' + month : month) + format + (date < 10 ? '0' + date : date)
    }
    changeTableData(data) {
        let list = [];
        const typeList=['','父亲','母亲','爷爷','奶奶','外公','外婆','其他']
        for (let i in data) {
            if (i > 2) {
                let row = data[i];
                if (row[0] != '') {
                    let obj = {
                        name: row[0],
                        sex: row[1] == '男' ? 1 : 2,
                        birthday: this.formatDate(row[2], '-'),
                        idcard: row[3],
                        student_no: row[4],
                        nation: row[5],
                        school_name: row[6],
                        class_name: row[7],
                        address: row[8],
                        guardians: [{
                            type: typeList.findIndex(item=>item==row[9]) || 7,
                            name: row[10],
                            mobile: row[11],
                            idcard: row[12],
                        }],
                    }
                    list.push(obj)
                } else {
                    let obj = {
                        type: typeList.findIndex(item=>item==row[9]) || 7,
                        name: row[10],
                        mobile: row[11],
                        idcard: row[12],
                    }, index = list.length - 1;
                    list[index].guardians.push(obj)
                }
            }
        }
        return list;
    }
    getFileJson(info) {
        let files = info.file;
        let name = files.name;
        // 获取文件后缀
        let suffix = name.substr(name.lastIndexOf("."));
        let reader = new FileReader();
        reader.onload = (event) => {
            try {
                // 判断文件类型是否正确
                if (".xls" != suffix && ".xlsx" != suffix) {
                    message.error("选择Excel格式的文件导入!");
                    return false;
                }
                let { result } = event.target;
                // 读取文件
                let workbook = XLSX.read(result, { type: "binary" });
                let keyRows =
                    workbook.Sheets[workbook.SheetNames[0]];
                let data = XLSX.utils.sheet_to_row_object_array(keyRows, {
                    header: 1,
                    defval: "",
                });
                data = this.changeTableData(data);
                this.setState({
                    lists: data,
                })
            } catch (e) {
                message.error("文件类型不正确！");
            }
        };
        reader.readAsBinaryString(files);
    }
    save() {
        let datas = this.state.lists;
        if (datas.length == 0) {
            return message.error('不能上传空数据！', 1.2)
        }
        this.setState({
            upLoading: true,
        }, () => {
            global.Ajax.hhtc_request('student/addStudents', { datas }).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                this.setState({ upLoading: false, })
            })
        })
    }
    render() {
        const props = {
            accept: ".xls,.xlsx",
            name: "file",
            beforeUpload: (file) => {
                let houz = file.name.split(".");
                if (
                    houz[houz.length - 1] !== "xls" &&
                    houz[houz.length - 1] !== "xlsx"
                ) {
                    message.error("上传文件格式有误！", 1.2);
                    return Upload.LIST_IGNORE;
                }
                return false;
            },
            onChange: (e) => {
                this.setState({
                    fileList: e.fileList,
                });
                this.getFileJson(e);
            },
            onDrop(e) { },
            onRemove: (file) => {
                const index = this.state.fileList.indexOf(file);
                const newFileList = this.state.fileList.slice();
                newFileList.splice(index, 1);
                this.setState({
                    fileList: newFileList,
                    answer: [],
                });
            },
        };
        return (
            <React.Fragment>
                <div id='eee' style={{ width: 0, height: 0, overflow: 'hidden', }}>
                    <QRCode
                        id="qrCode"
                        value={this.state.src}
                        size={200} // 二维码的大小
                        fgColor="#000000" // 二维码的颜色
                        style={{ margin: 'auto' }}
                    />
                    {/* <p style={{fontSize: 14,color: '#333'}}>姓名：张三</p>
                    <p style={{fontSize: 14,color: '#333'}}>班级：一年级</p>
                    <p style={{fontSize: 14,color: '#333'}}>学号：20220123</p> */}
                </div>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入学生姓名'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                        value={this.state.pword}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        value={this.state.school_id}
                        onChange={(school_id) => {
                            this.setState({
                                school_id,
                            }, () => {
                                if (school_id) {
                                    this.getAllClass(school_id, 'search')
                                } else {
                                    this.setState({
                                        classLists: []
                                    })
                                    // this.seltClassRef.setState({
                                    //     data: []
                                    // })
                                }
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Select
                        options={this.state.classLists || []}
                        placeholder='请选择班级'
                        className='marginr12 borderbai'
                        bordered={false}
                        style={{ width: 160,background: '#fff' }}
                        value={this.state.class_id}
                        allowClear
                        onChange={(class_id) => {
                            this.setState({
                                class_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    ></Select>
                    {/* <SelectList
                        ref={dom => this.seltClassRef = dom}
                        placeholder='请选择班级'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        value={this.state.class_id}
                        onChange={(class_id) => {
                            this.setState({
                                class_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    /> */}
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')} className='marginr12'>新增学生</Button>
                    <Button type='primary' className='fu1 marginr12' onClick={() => {
                        let a = document.createElement("a");
                        a.download = '批量导入学生模板.xlsx';
                        a.href = './student.xlsx';
                        a.click();
                    }}>下载模板</Button>
                    <Button type='primary' className='fu2 marginr12' onClick={() => {
                        this.setState({
                            upVisible: true,
                        })
                    }}>批量导入</Button>
                    <Button type='primary' onClick={this.onPrint.bind(this)} loading={this.state.loading}>打印</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学生列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}学生`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    width={890}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                        initialValues={{
                            status: true,
                        }}
                        onValuesChange={this.onValuesChange}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='school_id'
                                label='所属学校'
                                rules={[{ required: true, message: '请选择所属学校！' }]}
                                className='item48'
                            >
                                <SelectList
                                    allowClear={false}
                                    type='allschool'
                                    placeholder='请选择所属学校'
                                    onChange={(id) => {
                                        if (id) {
                                            this.getAllClass(id)
                                        } else {
                                            this.seltRef.setState({
                                                data: []
                                            })
                                        }
                                        this.formRef.setFieldsValue({
                                            class_id: undefined
                                        })
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name='class_id'
                                label='所属班级'
                                rules={[{ required: true, message: '请选择所属班级！' }]}
                                className='item48'
                            >
                                <SelectList
                                    allowClear={false}
                                    ref={dom => this.seltRef = dom}
                                    placeholder='请选择所属班级'
                                />
                            </Form.Item>
                            <Form.Item
                                name='student_no'
                                label='学号'
                                rules={[{ required: true, message: '请输入学号！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入学号' />
                            </Form.Item>
                            <Form.Item
                                name='name'
                                label='学生姓名'
                                rules={[{ required: true, message: '请输入学生姓名！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入学生姓名' />
                            </Form.Item>
                            <Form.Item
                                name='idcard'
                                label='身份证号'
                                rules={[{ required: true, message: '请输入身份证号！' }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        var myreg = /^[1-9]\d{5}(18|19|20|21)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                                        if (!value || myreg.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入正确的身份证号！'));
                                    }
                                })]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入身份证号' />
                            </Form.Item>
                            <Form.Item
                                name='status'
                                label='状态'
                                valuePropName='checked'
                                className='item48'
                            >
                                <Switch checkedChildren='正常' unCheckedChildren='请假' />
                            </Form.Item>
                            <Form.Item
                                name='address'
                                label='居住地址'
                                rules={[{ required: true, message: '请输入居住地址！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入居住地址' />
                            </Form.Item>
                            <Form.Item
                                name='nation'
                                label='民族'
                                rules={[{ required: true, message: '请输入民族！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入民族' />
                            </Form.Item>
                            <Form.Item
                                name='sex'
                                label='性别'
                                rules={[{ required: true, message: '请选择性别！' }]}
                                className='item48'
                            >
                                <SelectList
                                    allowClear={false}
                                    placeholder='请选择性别'
                                    data={[
                                        { value: 1, label: '男' },
                                        { value: 2, label: '女' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                name='birthday'
                                label='出生日期'
                                rules={[{ required: true, message: '请选择出生日期！' }]}
                                className='item48'
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 上传文件 */}
                <CustomModal
                    visible={this.state.upVisible}
                    width={480}
                    title="导入数据"
                    onCancel={this.onCancel.bind(this)}
                >
                    <Dragger fileList={this.state.fileList} maxCount={1} {...props}>
                        <p className="ant-upload-drag-icon">
                            <img alt="" src={require("../../imgs/up.png").default} />
                        </p>
                        <p
                            className="ant-upload-text"
                            style={{ fontSize: 16, color: "#666" }}
                        >
                            将文件拖到此处，或{" "}
                            <span style={{ color: "#1E7FFF" }}>点击上传</span>
                        </p>
                    </Dragger>
                    <div className="flexAllCenter" style={{ marginTop: 50 }}>
                        <Button
                            onClick={this.onCancel.bind(this)}
                            style={{
                                background: "#F6F6F6",
                                borderColor: "#F6F6F6",
                                marginRight: 20,
                            }}
                        >
                            关闭
                        </Button>
                        <Button type="primary" loading={this.state.upLoading} onClick={this.save.bind(this)}>
                            确定
                        </Button>
                    </div>
                </CustomModal>
                {/* 监护人列表 */}
                <CustomModal
                    title='监护人列表'
                    visible={this.state.show}
                    width={1000}
                    onCancel={this.onCancel.bind(this)}
                >
                    <GuardianList data={this.state.editData} />
                </CustomModal>
                {/* 二维码 */}
                <CustomModal
                    visible={this.state.ewmVisible}
                    title='二维码'
                    width={400}
                    onCancel={this.onCancel.bind(this)}
                >
                    <div id='contentss' style={{ textAlign: 'center', padding: '10px 0' }}>
                        <QRCode
                            id="qrCode"
                            value={this.state.code_url}
                            size={200} // 二维码的大小
                            fgColor="#000000" // 二维码的颜色
                            style={{ margin: 'auto' }}
                        />
                        <p style={{ fontSize: 16, color: '#333', marginTop: 6 }}>姓名：{this.state.editData.name}</p>
                        <p style={{ fontSize: 16, color: '#333', marginTop: 6 }}>班级：{this.state.editData.class_name}</p>
                        <p style={{ fontSize: 16, color: '#333', marginTop: 6 }}>学号：{this.state.editData.student_no}</p>
                    </div>
                    <Button loading={this.state.downing} onClick={this.downloadPic.bind(this)} type='primary' style={{ display: 'block', margin: '40px auto 0' }}>下载</Button>
                </CustomModal>
                {/* 生长情况 */}
                <CustomModal
                    visible={this.state.szVisible}
                    title='生长情况'
                    width={900}
                    onCancel={this.onCancel.bind(this)}
                >
                    <div style={{ height: '50vh' }} id='box11'></div>
                    <Button onClick={this.onCancel.bind(this)} type='primary' style={{ display: 'block', margin: '40px auto 0' }}>关闭</Button>
                </CustomModal>
            </React.Fragment>
        )
    }
    onValuesChange = (res) => {
        let key = (Object.keys(res))[0];
        if (key == 'student_no') {
            var value = Helper.getNumber(res[key]);
            this.formRef.setFieldsValue({
                [key]: value
            })
        }
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'student/addStudent';
            if (this.state.type == 'edit') {
                url = 'student/editStudent';
                req.id = this.state.editData.id;
            }
            req.status = req.status ? 0 : 1;
            req.birthday = req.birthday ? req.birthday.format('YYYY-MM-DD') : '';
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
            classList: [],
            upVisible: false,
            fileList: [],
            lists: [],
            show: false,
            ewmVisible: false,
            code_url: '',
            szVisible: false,
        })
    }
    async downloadPic() {
        this.setState({
            downing: true,
        })
        let content = document.getElementById('contentss');
        const canvas = await html2canvas(content, {
            useCORS: true, // 【重要】开启跨域配置
            allowTaint: true, // 允许跨域图片
            // foreignObjectRendering: true, // 是否在浏览器支持的情况下使用ForeignObject渲染
            // async: false, // 是否异步解析和呈现元素
            /** 以下字段必填 */
            backgroundColor: '#fff', // 画布背景颜色（若为null，则是透明色）Canvas background color, if none is specified in DOM. Set null for transparent
            scale: this.DPR(), // 处理模糊问题
            dpi: 500, // 处理模糊问题
        })
        const result = canvas.toDataURL("png"); // 获取到图片地址(base64格式)
        this.downloadExportFile(result)
    }
    downloadExportFile(base64) {
        var eventPayload = {
            "content": base64,
            //图片base64格式太多，此处省略。不包含前缀：data:image/png;base64,
            "fileName": this.state.editData.name,
            "imageType": "png"
        };
        var content = eventPayload.content;
        var imageType = eventPayload.imageType;
        var fileName = eventPayload.fileName;
        if (content) { // 接口返回的数据部分
            // 解析图片
            // 因接口直接返回了base64代码部分，所以不需要截取，如果含"data:image/png;base64," 则需要自己做截取处理
            var raw = window.atob(content.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
            var rawLength = raw.length;
            var uInt8Array = new Uint8Array(rawLength);
            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            var blob = new Blob([uInt8Array], { type: 'image/' + imageType });
            //保存图片
            var aLink = document.createElement('a');
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true);
            aLink.download = fileName;
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        } else {
            console.log('没有base64代码');
        }
        this.setState({
            downing: false,
        })
    }
}