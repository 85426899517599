import React from 'react';
import { Checkbox } from 'antd';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            data: JSON.parse(JSON.stringify(this.props.data)),
        }
        // console.log(props)
    }
    componentDidMount() {
        var data = JSON.parse(JSON.stringify(this.props.data)),
            values = this.props.values;
        if (this.props.values) {  //编辑
            for (var i in data) {  //循环第一级
                var index = values.findIndex(item => item == data[i].value);
                if (index != -1) {  //一级选中再循环二级
                    for (var j in data[i].child) {  //循环第二级
                        var jj = values.findIndex(item => item == data[i].child[j].value)
                        if (jj != -1) {  //二级选中再循环第三级
                            data[i].child[j].checked = true;
                            for (var m in data[i].child[j].child) {  //循环第三级
                                var mm = values.findIndex(item => item == data[i].child[j].child[m].value)
                                if (mm != -1) {
                                    data[i].child[j].child[m].checked = true;
                                }
                            }
                            // 判断三级是否全部选中
                            var num = this.getCheckedNum(data[i].child[j].child).num
                            if (num == data[i].child[j].child.length) {  //全选
                                data[i].child[j].checked = true;
                                data[i].child[j].indeterminate = false;
                            } else if (num > 0) {  //部分选中
                                data[i].child[j].checked = false;
                                data[i].child[j].indeterminate = true;
                            }
                        }
                    }
                    // 判断二级是否全选中 设置一级的选中状态
                    var sec = this.getCheckedNum(data[i].child);
                    data[i].indeterminate = true
                    data[i].checked = false;
                    if (sec.num == data[i].child.length && sec.num > 0) {  //全选
                        data[i].checked = true;
                        data[i].indeterminate = false;
                    }
                    if (sec.num == 0 && sec.num2 == 0) {
                        data[i].indeterminate = false;
                    }
                    // 没有子级
                    if(data[i].child.length==0){
                        data[i].checked=true;
                    }
                }
            }
        }
        this.setState({ data, checkedValues: values })
    }
    // 
    getCheckedNum(data) {
        var num = 0, num2 = 0;
        for (var i in data) {
            if (data[i].checked) {
                num++
            }
            if (data[i].indeterminate) {
                num2++
            }
        }
        return { num, num2 }
    }
    onChange(ppindex, pindex, index, e) {
        this.state.data[ppindex].child[pindex].child[index].checked = e.target.checked;
        // 判断是否全选
        var data = this.state.data;
        for (var i in data) {
            var child = data[i].child;
            // 根据三级是否全选判断二级显示状态
            for (var j in child) {  //判断三级是否全选
                var num = this.getCheckedNum(child[j].child).num
                if (num == child[j].child.length && num > 0) {  //全选
                    data[i].child[j].checked = true;
                    data[i].child[j].indeterminate = false;
                } else if (num > 0) {  //部分选中
                    data[i].child[j].checked = false;
                    data[i].child[j].indeterminate = true;
                } else {  //未选
                    if (data[i].child[j].checked === false) {
                        data[i].child[j].checked = false;
                    }
                    data[i].child[j].indeterminate = false;
                }
            }
            // 根据二级是否全选判断一级显示状态
            var sec = this.getCheckedNum(child)
            data[i].indeterminate = true
            data[i].checked = false;
            if (sec.num == child.length && sec.num > 0) {  //全选
                data[i].checked = true;
                data[i].indeterminate = false;
            }
            if (sec.num == 0 && sec.num2 == 0) {
                data[i].indeterminate = false;
            }
        }
        // 获取已选中的目录id
        var checkedValues = this.getIds();
        this.setState({
            data: data,
            checkedValues,
        })
    }
    getAll(data) {
        var res = [];
        for (var i in data) {
            res.push(data[i].value)
        }
        return res;
    }
    getChecked(data) {
        var res = [];
        for (var i in data) {
            if (data[i].checked) {
                res.push(data[i].value)
            }
        }
        return res;
    }
    getIds() {
        var data = this.state.data, checkedValues = [];
        for (var i in data) {
            for (var j in data[i].child) {
                for (var m in data[i].child[j].child) {
                    // 判断三级每一项
                    if (data[i].child[j].child[m].checked) {
                        checkedValues.push(data[i].child[j].child[m].value)
                    }
                }
                // 判断二级每一项
                if (data[i].child[j].checked || data[i].child[j].indeterminate) {
                    checkedValues.push(data[i].child[j].value)
                }
            }
            // 判断一级每一项
            if (data[i].checked || data[i].indeterminate) {
                checkedValues.push(data[i].value)
            }
        }
        return checkedValues;
    }
    // 一级全选
    firCheckAll(index, e) {
        var checked = e.target.checked;
        this.state.data[index].checked = checked;
        this.state.data[index].indeterminate = false;
        if (checked) {  //全部选中
            for (var i in this.state.data[index].child) {  //循环二级
                this.state.data[index].child[i].checked = true;
                this.state.data[index].child[i].indeterminate = false;
                for (var j in this.state.data[index].child[i].child) {  //改变三级
                    this.state.data[index].child[i].child[j].checked = true;
                }
            }
        } else {
            for (var i in this.state.data[index].child) {  //循环二级
                this.state.data[index].child[i].checked = false;
                this.state.data[index].child[i].indeterminate = false;
                for (var j in this.state.data[index].child[i].child) {  //改变三级
                    this.state.data[index].child[i].child[j].checked = false;
                }
            }
        }
        // 获取已选中的目录id
        var checkedValues = this.getIds();
        this.setState({
            checkedValues,
            data: this.state.data,
        })
    }
    // 二级全选
    secCheckAll(pindex, index, e) {
        var checked = e.target.checked;
        this.state.data[pindex].child[index].indeterminate = false;
        this.state.data[pindex].child[index].checked = checked;
        var allChecked = true;
        for (var i in this.state.data[pindex].child[index].child) {
            if (checked) {
                this.state.data[pindex].child[index].child[i].checked = true;
            } else {
                this.state.data[pindex].child[index].child[i].checked = false;
            }
            if (!this.state.data[pindex].child[index].checked) {
                allChecked = false;
            }
        }
        var obj = this.state.data[pindex].child.findIndex(item => !item.checked);
        var obj1 = this.state.data[pindex].child.findIndex(item => item.checked);
        var indeterminate = false
        if (obj > -1 && obj1 > -1) {
            indeterminate = true;
        }
        this.state.data[pindex].checked = allChecked;
        this.state.data[pindex].indeterminate = indeterminate;
        // 获取已选中的目录id
        var checkedValues = this.getIds();
        this.setState({
            data: this.state.data,
            checkedValues,
        })
    }
    render() {
        return (
            <React.Fragment>
                {this.state.data.map((r, i) => (
                    <div className='flexCenter checklist' key={'' + i}>
                        <Checkbox value={r.value} indeterminate={r.indeterminate} checked={r.checked} onChange={this.firCheckAll.bind(this, i)}>{r.label}</Checkbox>
                        <div>
                            {r.child.map((item, index) => (
                                <div className='flexCenter checklist' key={'' + index}>
                                    <Checkbox name='sec' indeterminate={item.indeterminate} checked={item.checked} onChange={this.secCheckAll.bind(this, i, index)}>{item.label}</Checkbox>
                                    <div>
                                        {item.child.map((row, k) => (
                                            <Checkbox key={'' + k} checked={row.checked} onChange={this.onChange.bind(this, i, index, k)}>{row.label}</Checkbox>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}