import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Ueditor from '../../common/Ueditor';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '单位名称',
                dataIndex: 'name',
                align: 'center',
                ellipsis: {
                    showTitle: false,
                },
                render: title => (
                    <Tooltip title={title} placement="topLeft">{title || '-'}</Tooltip>
                )
            }, {
                title: '上级单位',
                dataIndex: 'sup_name',
                align: 'center',
                render: name => `${name || '-'}`
            }, {
                title: '单位简介',
                dataIndex: 'introduce',
                align: 'center',
                ellipsis: {
                    showTitle: false,
                },
                render: (name, item) => (
                    <React.Fragment>
                        <span className={`${(name && name != '') ? 'color1' : 'color2'} cursor`} onClick={() => {
                            if (name && name != '') {
                                Modal.info({
                                    title: '单位简介',
                                    centered: true,
                                    okText: '确定',
                                    className: 'nrbox',
                                    content: (
                                        <React.Fragment>
                                            <div dangerouslySetInnerHTML={{ __html: name }}></div>
                                        </React.Fragment>
                                    )
                                })
                            } else {
                                this.edit(item)
                            }
                        }}>{(name && name != '') ? '已设置' : '未设置'}</span>
                    </React.Fragment>
                    // <Tooltip title={<div className='ccc' style={{color: '#fff'}} dangerouslySetInnerHTML={{__html: name || ''}}></div>} placement="top">
                    //     <div dangerouslySetInnerHTML={{__html: name || ''}}></div>
                    // </Tooltip>
                )
            }, {
                title: '创建时间',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: 150,
                render: (_, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || ''
        }
        global.Ajax.hhtc_request('department/departList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        }, () => {
            let rows = JSON.parse(JSON.stringify(data));
            rows.pid = rows.pid > 0 ? rows.pid : undefined;
            setTimeout(() => {
                this.formRef.setFieldsValue(rows);
                this.ueditor && this.ueditor.setContent(rows.introduce);
            }, 100);
        })
    }
    // 删除
    del(data) {
        var url = 'department/delDepart', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入单位名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value,
                            }, () => {
                                this.tabblelist.refresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加单位</Button>
                </div>
                <div className='bgbai flexColumn'>
                    <h2 className='pubTit'>单位列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    width={540}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}单位`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <Form.Item
                            name='pid'
                            label='上级单位'
                        >
                            <SelectList
                                type='allcompany_auth'
                                placeholder='请选择上级单位'
                            />
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label='单位名称'
                            rules={[{ required: true, message: '请输入单位名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入单位名称' />
                        </Form.Item>
                        <Form.Item
                            name='introduce'
                            label='单位简介'
                            rules={[{ required: true, message: '请输入单位简介！' }]}
                        >
                            <Input className='noHeight' />
                            <Ueditor ref={e => this.ueditor = e} className="myarea" style={{ height: 200, flex: 1, border: '1px solid #d9d9d9' }} changeValue={(val) => {
                                this.formRef.setFieldsValue({
                                    introduce: val.toHTML(),
                                })
                            }} />
                            {/* <Input.TextArea rows={6} autoComplete='off' placeholder='请输入单位简介' /> */}
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            let url = 'department/addDepart';
            if (this.state.type == 'edit') {
                url = 'department/editDepart';
                req.id = this.state.editData.id;
            }
            req.pid = req.pid || 0;
            req.ptree = '';
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: ''
        })
    }
}