import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data || []
        }
    }
    componentDidMount(){
        if(this.props.type){
            var url='',
                req={};
            if(this.props.type=='allrole'){
                url='admin/getSearchRoleList';
            }else if(this.props.type=='alladmin'){
                url='admin/getSearchAdminList';
            }else if(this.props.type=='allcompany'){
                url='job/addJobGetDe'
            }else if(this.props.type=='allcompany_auth'){
                url='department/addJobGetDe'
            }else if(this.props.type=='allschool'){
                url='teacher/addTeacherGetSchool'
            }else if(this.props.type=='allclass'){
                url='teacher/addGetClass';
                req={
                    school_id: ''
                }
            }else if(this.props.type=='department'){
                url='article/getSelectDepartment'
            }else if(this.props.type=='ArticleType'){
                url='article/getArticleTypeSelect'
            }else if(this.props.type=='Tag'){
                url='article/getSelectTag'
            }else if(this.props.type=='WeatherTag'){
                url='article/getSelectWeatherTag'
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    var data = this.initData(res.data);
                    this.setState({
                        data,
                    })
                }
            })
        }
    }
	initData(arry) {
		let arryNew = []
		arry.map((item, index) => {
            let obj={
                value: item.id
            }
            if(item.name){
                obj.label=item.name
            }else if(item.class_name){
                obj.label=item.class_name
                obj.value=item.class_id
            }else if(item.school_name&&!item.class_name){
                obj.label=item.school_name
            }
			arryNew.push(Object.assign({}, obj))
		})
		return arryNew
	}
    // setData(data){
    //     this.setState({
    //         data
    //     })
       
    // }
    render() {
        return (
            <Select
                ref={ref=>this.selectRef=ref}
                placeholder='请选择角色'
                suffixIcon={(<span className='iconfont icon-xia'></span>)}
                allowClear
                {...this.props}
                className={`pubSelt ${this.props.className}`}
                
            >
                {this.state.data.map((item, index) => (
                    <Option value={item.value*1} key={''+index}>{item.label}</Option>
                ))}
            </Select>
        )
    }
}