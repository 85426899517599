import React from 'react';
import { Button, Input, message, Tooltip, Form, Upload } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import * as XLSX from "xlsx";
import CustomTable from '../../common/Table';

const { Dragger } = Upload;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            school_name: '',
            pid: '',
            fileList: [],
            upVisible: false,
            lists: [],
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '学校负责人',
                dataIndex: 'person',
                align: 'center',
            }, {
                title: '归属单位',
                dataIndex: 'sup_name',
                align: 'center',
            }, {
                title: '联系电话',
                dataIndex: 'mobile',
                align: 'center',
            }, {
                title: '联系地址',
                dataIndex: 'address',
                align: 'center',
            }, {
                title: '班级（个）',
                dataIndex: 'class_count',
                align: 'center',
                className: 'name',
                render: (num, item) => (
                    <span onClick={this.go.bind(this, 'class', item)}>{num || 0}</span>
                )
            }, {
                title: '保育员（人）',
                dataIndex: 'teacher_count',
                align: 'center',
                className: 'name',
                render: (num, item) => (
                    <span onClick={this.go.bind(this, 'byy', item)}>{num || 0}</span>
                )
            }, {
                title: '学生（人）',
                dataIndex: 'student_count',
                align: 'center',
                className: 'name',
                render: (num, item) => (
                    <span onClick={this.go.bind(this, 'student', item)}>{num || 0}</span>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>修改登录密码</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            school_name: this.state.school_name,
            pid: this.state.pid,
        }
        global.Ajax.hhtc_request('school/schoolList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row1'>学校名称</p>
                <p className='row_6'>学校负责人</p>
                <p className='row1'>归属单位</p>
                <p className='row1'>联系电话</p>
                <p className='row15'>联系地址</p>
                <p className='row_6'>班级（个）</p>
                <p className='row1'>保育员（人）</p>
                <p className='row_6'>学生（人）</p>
                <p className='rowFlex'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <Tooltip title={item.school_name}>
                            <p className='row1'>{item.school_name}</p>
                        </Tooltip>
                        <p className='row_6'>{item.person}</p>
                        <Tooltip title={item.sup_name}>
                            <p className='row1'>{item.sup_name}</p>
                        </Tooltip>
                        <p className='row1'>{item.mobile}</p>
                        <Tooltip title={item.address}>
                            <p className='row15'>{item.address}</p>
                        </Tooltip>
                        <p className='row_6 name'>
                            <span onClick={this.go.bind(this, 'class', item)}>{item.class_count}</span>
                        </p>
                        <p className='row1 name'>
                            <span onClick={this.go.bind(this, 'byy', item)}>{item.teacher_count}</span>
                        </p>
                        <p className='row_6 name'>
                            <span onClick={this.go.bind(this, 'student', item)}>{item.student_count}</span>
                        </p>
                        <div className='rowFlex flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>修改登录密码</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    go(type, data) {
        let row, item = this.props.schoolRow;
        if (type == 'class') {
            row = this.props.classPageId;
            this.props.onChange({ row, item, school_id: data.id })
        } else if (type == 'byy') {
            row = this.props.byyPageId
            this.props.onChange({ row, item, school_id: data.id })
        } else if (type == 'student') {
            row = this.props.studentPageId;
            this.props.onChange({ row, item, school_id: data.id })
        }
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            visible: true,
        })
    }
    // 删除
    del(data) {
        var url = 'school/delSchool', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    changeTableData(data) {
        function trimAll(ele) {
            if (typeof ele === 'string') {
                return ele.split(/[\t\r\f\n\s]*/g).join('');
            } else {
                return ele
                // console.error(`${typeof ele} is not the expected type, but the string type is expected`)
            }
        }
        let list = [];
        for (let i in data) {
            if (i > 1) {
                let row = data[i];
                let obj = {
                    school_name: row[0],
                    department: row[1],
                    person: row[2],
                    mobile: row[3],
                    address: row[4],
                    introduce: row[5],
                    // month: row[1],
                    // '-3sd': trimAll(row[2]),
                    // '-2sd': trimAll(row[3]),
                    // '-1sd': trimAll(row[4]),
                    // 'middle': trimAll(row[5]),
                    // '1sd': trimAll(row[6]),
                    // '2sd': trimAll(row[7]),
                    // '3sd': trimAll(row[8]),
                    // type: 3,
                    // sex: 2,
                }
                list.push(obj)
            }
        }
        return list;
    }
    getFileJson(info) {
        let files = info.file;
        let name = files.name;
        // 获取文件后缀
        let suffix = name.substr(name.lastIndexOf("."));
        let reader = new FileReader();
        reader.onload = (event) => {
            try {
                // 判断文件类型是否正确
                if (".xls" != suffix && ".xlsx" != suffix) {
                    message.error("选择Excel格式的文件导入!");
                    return false;
                }
                let { result } = event.target;
                // 读取文件
                let workbook = XLSX.read(result, { type: "binary" });
                let keyRows =
                    workbook.Sheets[workbook.SheetNames[0]];
                let data = XLSX.utils.sheet_to_row_object_array(keyRows, {
                    header: 1,
                    defval: "",
                });
                data = this.changeTableData(data);
                this.setState({
                    lists: data,
                })
            } catch (e) {
                message.error("文件类型不正确！");
            }
        };
        reader.readAsBinaryString(files);
    }
    save() {
        let datas = this.state.lists;
        if (datas.length == 0) {
            return message.error('不能上传空数据！', 1.2)
        }
        this.setState({
            upLoading: true,
        }, () => {
            let url='school/addSchools';
            // let url = 'admin/addBiaozhuan';
            global.Ajax.hhtc_request(url, { datas }).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                this.setState({ upLoading: false, })
            })
        })
    }
    render() {
        const props = {
            accept: ".xls,.xlsx",
            name: "file",
            beforeUpload: (file) => {
                let houz = file.name.split(".");
                if (
                    houz[houz.length - 1] !== "xls" &&
                    houz[houz.length - 1] !== "xlsx"
                ) {
                    message.error("上传文件格式有误！", 1.2);
                    return Upload.LIST_IGNORE;
                }
                return false;
            },
            onChange: (e) => {
                this.setState({
                    fileList: e.fileList,
                });
                this.getFileJson(e);
            },
            onDrop(e) { },
            onRemove: (file) => {
                const index = this.state.fileList.indexOf(file);
                const newFileList = this.state.fileList.slice();
                newFileList.splice(index, 1);
                this.setState({
                    fileList: newFileList,
                    answer: [],
                });
            },
        };
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入学校名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                school_name: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allcompany'
                        placeholder='请选择归属单位'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(pid) => {
                            pid = pid || '';
                            this.setState({
                                pid
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    {/* <SelectList
                        data={[]}
                        placeholder='请选择行政区域'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(role_id) => {
                            this.setState({
                                role_id,
                                page: 1,
                            }, () => {
                                this.refresh()
                            })
                        }}
                    /> */}
                    <Button type='primary' className='marginr12' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加学校</Button>
                    <Button type='primary' className='fu1 marginr12' onClick={() => {
                        let a = document.createElement("a");
                        a.download = '批量导入学校模板.xlsx';
                        a.href = './school1.xlsx';
                        a.click();
                    }}>下载模板</Button>
                    <Button type='primary' className='fu2 marginr12' onClick={() => {
                        this.setState({
                            upVisible: true,
                        })
                    }}>批量导入</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学校列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    width={720}
                    title='添加学校'
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='pid'
                                label='所属单位'
                                rules={[{ required: true, message: '请选择所属单位！' }]}
                                className='item48'
                            >
                                <SelectList
                                    type='allcompany'
                                    placeholder='请选择所属单位'
                                />
                            </Form.Item>
                            <Form.Item
                                name='school_name'
                                label='学校名称'
                                rules={[{ required: true, message: '请输入学校名称！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入学校名称' />
                            </Form.Item>
                            <Form.Item
                                name='person'
                                label='负责人'
                                rules={[{ required: true, message: '请输入负责人！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入负责人' />
                            </Form.Item>
                            <Form.Item
                                name='mobile'
                                label='联系电话'
                                className='item48'
                                rules={[{ required: true, message: '请输入联系电话！' }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                                        if (!value || myreg.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入正确的手机号!'));
                                    }
                                })]}
                            >
                                <Input maxLength={11} autoComplete='off' placeholder='请输入联系电话' />
                            </Form.Item>
                            <Form.Item
                                name='address'
                                label='联系地址'
                                rules={[{ required: true, message: '请输入联系地址！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入联系地址' />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                label='登录密码'
                                className='item48'
                            >
                                <Input.Password autoComplete='new-password' placeholder='非必填，默认密码为123456' />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 修改登录密码 */}
                <CustomModal
                    width={360}
                    title='修改登录密码'
                    visible={this.state.visible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        onFinish={(req) => {
                            this.setState({
                                loading: true,
                            }, () => {
                                req.id = this.state.editData.id;
                                global.Ajax.hhtc_request('school/editSchoolPwd', req).then(res => {
                                    if (res.code == 1) {
                                        message.success(res.msg, 1.2)
                                        this.onCancel()
                                        this.refresh()
                                    } else {
                                        message.error(res.msg, 1.2)
                                    }
                                    this.setState({ loading: false, })
                                })
                            })
                        }}
                    >
                        <Form.Item
                            name='pwd'
                            label='登录密码'
                            rules={[{ required: true, message: '请设置登录密码！' }]}
                        >
                            <Input.Password autoComplete='new-password' placeholder='请设置登录密码' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 上传文件 */}
                <CustomModal
                    visible={this.state.upVisible}
                    width={480}
                    title="导入数据"
                    onCancel={this.onCancel.bind(this)}
                >
                    <Dragger fileList={this.state.fileList} maxCount={1} {...props}>
                        <p className="ant-upload-drag-icon">
                            <img alt="" src={require("../../imgs/up.png").default} />
                        </p>
                        <p
                            className="ant-upload-text"
                            style={{ fontSize: 16, color: "#666" }}
                        >
                            将文件拖到此处，或{" "}
                            <span style={{ color: "#1E7FFF" }}>点击上传</span>
                        </p>
                    </Dragger>
                    <div className="flexAllCenter" style={{ marginTop: 50 }}>
                        <Button
                            onClick={this.onCancel.bind(this)}
                            style={{
                                background: "#F6F6F6",
                                borderColor: "#F6F6F6",
                                marginRight: 20,
                            }}
                        >
                            关闭
                        </Button>
                        <Button type="primary" loading={this.state.upLoading} onClick={this.save.bind(this)}>
                            确定
                        </Button>
                    </div>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'school/addschool';
            if (this.state.type == 'edit') {
                url = 'school/editSchool';
                req.id = this.state.editData.id;
            }
            req.password = req.password || '';
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            visible: false,
            upVisible: false,
            fileList: [],
            lists: [],
        })
    }
}