import React from 'react';
import { Button, Tooltip, Pagination, Modal, Empty, Spin } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			orderBy: '',
			admin_id: '',
			address: '',
			ip: '',
			desc: '',
		}
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	initData(arry) {
		let arryNew = []
		arry.map((item, index) => {
			arryNew.push(Object.assign({}, item, { value: item.admin_id, label: item.username }))
		})
		return arryNew
	}
	getList(page,callback) {
		var orderBy = '';
		if (this.state.orderBy != '') {
			orderBy =  this.state.orderBy;
		}
		var req = {
			page: page,
			size: this.state.size,
			admin_id: this.state.admin_id || '',
			address: this.state.address,
			ip: this.state.ip,
			desc: this.state.desc,
			orderBy
		}
		global.Ajax.hhtc_request('admin/adminLog', req).then(res => {
			callback(res)
		})
	}
	
	// 搜索
	search(name, key) {
		this.setState({
			[key]: name,
		}, () => {
			this.tabblelist.refresh()
		})
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	renderHead() {
		return (
			<div className='head flex'>
				<p className='flexAllCenter cursor' style={{ width: 80 }}>
					序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
						var orderBy = this.state.orderBy;
						if (orderBy == 'desc') {
							orderBy = 'asc'
						} else {
							orderBy = 'desc'
						}
						this.setState({
							orderBy,
							
						}, () => {
							this.refresh()
						})
					}}></span>
				</p>
				<p className='row2'>操作人</p>
				<p className='rowFlex'>操作内容</p>
				<p className='row15'>操作IP</p>
				<p className='row15'>操作地址</p>
				<p className='row2'>操作时间</p>
			</div>
		)
	}
	renderItem(data) {
		return (
			<React.Fragment>
				{data.map((item, index) => (
					<div className='flex' key={'' + index}>
						<p style={{ width: 80 }}>{Helper.getNum(index,this.tabblelist.state.total,this.tabblelist.state.size,this.tabblelist.state.page,this.state.orderBy)}</p>
						<p className='row2'>{item.username}</p>
						<Tooltip
							placement='top'
							title={`${item.desc}`}
						>
							<p className='rowFlex'>{item.desc}</p>
						</Tooltip>
						<p className='row15'>{item.ip}</p>
						<p className='row15'>{item.address}</p>
						<p className='row2'>{item.atime}</p>
					</div>
				))}
			</React.Fragment>
		)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入操作内容关键词'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'desc')
						}}
						
					/>
					<SelectList
						type="alladmin"
						placeholder='请选择操作人'
						className='marginr12 borderbai'
						style={{ width: 160 }}
						onChange={(admin_id) => {
							this.setState({
								admin_id,
							}, () => {
								this.tabblelist.refresh()
							})
						}}
					/>
					<Input
						placeholder='请输入操作地址'
						prefix={null}
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'address')
						}}
						
					/>
					<Input
						placeholder='请输入操作IP'
						prefix={null}
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'ip')
						}}
						
					/>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>操作日志列表</h2>
					<TabbleList
						ref={dom => this.tabblelist = dom}
						size={this.state.size}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						renderHead={this.renderHead.bind(this)}  //表头
						renderItem={this.renderItem.bind(this)}  //表内容
					/>
				</div>
			</React.Fragment>
		)
	}
}
