import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';

const typeList = [
    { label: '父亲', value: 1 },
    { label: '母亲', value: 2 },
    { label: '爷爷', value: 3 },
    { label: '奶奶', value: 4 },
    { label: '外公', value: 5 },
    { label: '外婆', value: 6 },
    { label: '其他', value: 7 },
]
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '监护人关系',
                dataIndex: 'type',
                align: 'center',
                render: type => {
                    let data = typeList.find(item => item.value == type),
                        name = data ? data.label : '-';
                    return (
                        name
                    )
                }
            }, {
                title: '监护人姓名',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '监护人电话',
                dataIndex: 'mobile',
                align: 'center',
            }, {
                title: '监护人身份证号',
                dataIndex: 'idcard',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (_, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            {/* <p onClick={this.edit.bind(this, item)}>编辑</p> */}
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            student_id: this.props.data.id || 0,
        }
        global.Ajax.hhtc_request('student/guardianList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
    }
    // 删除
    del(data) {
        var url = 'student/delGuardian', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加监护人</Button>
                </div>
                <div className='bgbai flexColumn' style={{ height: '60vh' }}>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={480}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '124px' }}
                    >
                        <Form.Item label='监护人类型' name='type' rules={[{ required: true, message: '请选择监护人类型！' }]}>
                            <SelectList
                                data={typeList}
                            />
                        </Form.Item>
                        <Form.Item label='监护人姓名' name='name' rules={[{ required: true, message: '请输入监护人姓名！' }]}>
                            <Input autoComplete='off' placeholder='请输入监护人姓名' />
                        </Form.Item>
                        <Form.Item label='监护人电话' name='mobile' rules={[{ required: true, message: '请输入监护人电话！' }]}>
                            <Input autoComplete='off' placeholder='请输入监护人电话' maxLength={11} />
                        </Form.Item>
                        <Form.Item label='监护人身份证号' name='idcard' rules={[{ required: true, message: '请输入身份证号！' }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                var myreg = /^[1-9]\d{5}(18|19|20|21)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                                if (!value || myreg.test(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('请输入正确的身份证号！'));
                            }
                        })]}>
                            <Input autoComplete='off' placeholder='请输入监护人身份证号' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            let url = 'student/addGuardian';
            req.student_id = this.props.data.id;
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            editData: {},
            type: ''
        })
    }
}