import React from 'react';
import { Button, Input, message, Tooltip, Form, Switch, Checkbox } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import AddProj from '../CheckManage/AddProj';  //添加项目
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            visible: false,
        }
    }
    refresh() {
        this.tabblelist.refresh()
    }
    getList(page, callback) {
        let url,
            req = {
                school_id: this.props.id,
            };
        if (this.props.type == 'schoolPatrol') {
            url = 'patrol/schoolPatrolItemList';
        } else if (this.props.type == 'malist') {
            url = 'dayinspect/dayInspectItemList';
            req.type_id = this.props.typeId;
        }
        global.Ajax.hhtc_request(url, req).then(res => {
            let datas = {
                code: res.code,
                data: {
                    all: 0
                }
            }
            if (this.props.type == 'schoolPatrol') {
                // datas.data.datas=res.data.patrol_items;
                datas.data.datas = res.data;
            } else if (this.props.type == 'malist') {
                datas.data.datas = res.data
            }
            callback(datas)
            if (res.code == 1) {
                if (this.props.type == 'schoolPatrol') {
                    // this.initData(res.data.patrol_items, res.data.pratrol_ids.split(','))
                    this.initData(res.data)
                } else if (this.props.type == 'malist') {
                    this.initData(res.data)
                }
            }
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <Checkbox onChange={this.changeAll.bind(this)} checked={this.state.allChecked} indeterminate={this.state.indeterminate} className='marginl8'></Checkbox>
                <p className='rowFlex'>项目名称</p>
                {this.props.type == 'schoolPatrol' && <p className='rowFlex'>项目类型</p>}
                {this.props.type != 'schoolPatrol' && <p className='rowFlex'>项目简介</p>}
            </div>
        )
    }
    renderItem(data) {
        return (
            <div className='boxx'>
                {data && data.map((item, index) => (
                    <div className='flex' key={String(index)}>
                        <Checkbox checked={item.selected} onChange={this.change.bind(this, index)} className='marginl8'></Checkbox>
                        <p className='rowFlex'>{item.name}</p>
                        {this.props.type == 'schoolPatrol' && <p className='rowFlex'>{item.type_name}</p>}
                        {this.props.type != 'schoolPatrol' && <Tooltip title={item.interduce}>
                            <p className='rowFlex'>{item.interduce}</p>
                        </Tooltip>}
                    </div>
                ))}
            </div>
        )
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    // 全选
    changeAll() {
        let data = this.tabblelist.state.data;
        let allChecked = this.state.allChecked;
        for (let i in data) {
            if (!allChecked) {
                data[i].selected = true;
            } else {
                data[i].selected = false;
            }
        }
        if(data.length==0){
            allChecked=true;
        }
        this.setState({
            allChecked: !allChecked,
            indeterminate: false,
        },()=>{
            // console.log(this.state.allChecked);
        })
        this.tabblelist.setState({
            data
        })
    }
    // 单选
    change(index) {
        let data = this.tabblelist.state.data;
        data[index].selected = !data[index].selected;
        let allChecked = data.every(item => item.selected);
        let indeterminate = data.some(item => item.selected);
        indeterminate = allChecked ? false : indeterminate;
        this.setState({
            allChecked,
            indeterminate
        })
        this.tabblelist.setState({
            data
        })
    }
    initData(data, ids) {
        if (ids && ids.length == 0) {
            return;
        }
        if (ids && ids.length > 0) {
            for (let i in ids) {
                let index = data.findIndex(item => item.id == ids[i]);
                if (index > -1) {
                    data[index].selected = true
                }
            }
        } else {
            for (let i in data) {
                data[i].selected = data[i].isset == 1 ? true : false;
            }
        }
        let allChecked = data.every(item => item.selected);
        let indeterminate = data.some(item => item.selected);
        indeterminate = allChecked ? false : indeterminate;
        if(data.length==0){
            allChecked=false;
        }
        this.setState({
            allChecked,
            indeterminate
        })
        this.tabblelist.setState({
            data
        })
    }
    getIds() {
        let data = this.tabblelist.state.data,
            ids = [];
        for (let i in data) {
            if (data[i].selected) {
                ids.push(data[i].id)
            }
        }
        return ids;
    }
    render() {
        return (
            <React.Fragment>
                <TabbleList
                    ref={dom => this.tabblelist = dom}
                    size={this.state.size}
                    onRefresh={this.onRefresh.bind(this)}  //刷新
                    renderHead={this.renderHead.bind(this)}  //表头
                    renderItem={this.renderItem.bind(this)}  //表内容
                    className='projListbox'
                />
                <Button loading={this.state.loading} type='primary' style={{ margin: '30px 0 0 auto', display: 'block' }} onClick={this.add.bind(this)}>确认</Button>
            </React.Fragment>
        )
    }
    add() {
        let ids = this.getIds();
        // if (ids.length == 0) {
        //     this.props.onCancel()
        //     return
        // }
        this.setState({
            loading: true,
        }, () => {
            let url, req = {};
            if (this.props.type == 'schoolPatrol') {
                url = 'patrol/addPatrolItem';
                req = {
                    school_id: this.props.id,
                    pratrol_ids: ids.join(','),
                }
            } else if (this.props.type == 'malist') {
                url = 'dayinspect/addPatrolDayInspectItem';
                req = {
                    school_id: this.props.id,
                    item_ids: ids.join(','),
                    type_id: this.props.typeId
                }
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.props.onOk()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    // 删除
    del(data) {
        var url = 'dayinspect/delDayInspectItem', req = { id: data.id }
        this.delmask.del(url, req)
    }
    onCancel() {
        this.setState({
            visible: false,
            editData: {}
        })
    }
    onClose() {
        this.setState({
            addVisible: false,
        })
    }
}