export default class Helper{
    static getNum(index,total,size,page,orderBy){
       
        var num=index+1;
		if(orderBy=="desc"){
			num=total-(page-1)*size-(num-1);
		}else{
			num=(page-1)*size+num
		}
		if (num < 10) {
			num = '0' + num;
		}
		return num
    }
	static getSex(idcard) {
		if (parseInt(idcard.substr(16, 1)) % 2 == 1) {
			return "男"
		} else {
			return "女"
		}
	}
	static getAge(idcard){
		//获取出生年月日
        var yearBirth = idcard.substring(6, 10);
        var monthBirth = idcard.substring(10, 12);
        var dayBirth = idcard.substring(12, 14);
        //获取当前年月日并计算年龄
        var myDate = new Date();
        var monthNow = myDate.getMonth() + 1;
        var dayNow = myDate.getDay();
        var age = myDate.getFullYear() - yearBirth;
        if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
            age--;
		}
		return age
	}
	static getNumber(value){
		// 只允许输入数字
        value = value.replace(/[^\d]/g, '')
        return value
	}
	static getFloat(obj) { // 值允许输入一个小数点和数字 
		if(obj !=''&& obj.substr(0,1) == '.'){
			obj="";
		}
		obj = obj.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
		obj = obj.replace(/[^-\d.]/g,"");  //清除“数字”和“.”以外的字符
		obj = obj.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
		obj = obj.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
		obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
		if(obj.indexOf(".")< 0 && obj !=""){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			if(obj.substr(0,1) == '0' && obj.length == 2){
				obj= obj.substr(1,obj.length);
			}
		}
		// obj = obj.replace(/[^\d.]/g, ""); //先把非数字的都替换掉，除了数字和. 
		// obj = obj.replace(/^\./g, ""); //必须保证第一个为数字而不是. 
		// obj = obj.replace(/\.{2,}/g, "."); //保证只有出现一个.而没有多个. 
		// obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //保证.只出现一次，而不能出现两次以上 
		return obj
	}
	static getValue(value) {
		// 去掉富文本所有标签
		value = value.replace(/(\n)/g, "");
		value = value.replace(/(\t)/g, "");
		value = value.replace(/(\r)/g, "");
		value = value.replace(/<\/?[^>]*>/g, "");
		value = value.replace(/\s*/g, "");
		return value
	}
}