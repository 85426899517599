import React from 'react';
import { Button, Input, message, Tooltip, Form, Upload } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import * as XLSX from "xlsx";
import CustomTable from '../../common/Table';

const { Dragger } = Upload;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            // school_id: props.schoolId,
            // pword: props.teacherName,
            school_id: undefined,
            pword: undefined,
            fileList: [],
            upVisible: false,
            lists: [],
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '所属学校',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '班级名称',
                dataIndex: 'class_name',
                align: 'center',
            }, {
                title: '班级人数（人）',
                dataIndex: 'student_count',
                align: 'center',
            }, {
                title: '保育员',
                dataIndex: 'teacher_names',
                align: 'center',
            }, {
                title: '联系电话',
                dataIndex: 'teacher_mobiles',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>编辑</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || ''
        }
        global.Ajax.hhtc_request('classes/classList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row15'>所属学校</p>
                <p className='row15'>班级名称</p>
                <p className='row1'>班级人数（人）</p>
                {/* <p className='row1'>班级联系人</p> */}
                <p className='row15'>保育员</p>
                <p className='rowFlex'>联系电话</p>
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <p className='row15'>{item.school_name}</p>
                        <p className='row15 name'>
                            <span onClick={this.go.bind(this, 'student', item)}>{item.class_name}</span>
                        </p>
                        <p className='row1'>{item.student_count}</p>
                        {/* <p className='row1'>班级联系人</p> */}
                        <Tooltip title={item.teacher_names}>
                            <p className='row15'>{item.teacher_names || '-'}</p>
                        </Tooltip>
                        <Tooltip title={item.teacher_mobiles}>
                            <p className='rowFlex'>{item.teacher_mobiles || '-'}</p>
                        </Tooltip>
                        <div className='row15 flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    go(type, data) {
        let row, item = this.props.schoolRow;
        console.log(item)
        if (type == 'student') {
            row = this.props.studentPageId;
            this.props.onChange({ row, item, class_id: data.id, school_id: data.school_id })
        }
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
        setTimeout(() => {
            this.formRef.setFieldsValue({
                school_id: data.school_id,
                class_name: data.class_name,
            })
        }, 100);
    }
    // 删除
    del(data) {
        var url = 'classes/delClass', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    changeTableData(data) {
        let list = [];
        for (let i in data) {
            if (i > 1) {
                let row = data[i];
                // let obj={
                //     sex: 2,
                //     type: 2,
                //     month: row[1],
                //     '-3sd': row[2],
                //     '-2sd': row[3],
                //     '-1sd': row[4],
                //     middle: row[5],
                //     '3sd': row[6],
                //     '2sd': row[7],
                //     '1sd': row[8],
                // }
                let obj = {
                    school_name: row[0],
                    class_name: row[1],
                }
                list.push(obj)
            }
        }
        return list;
    }
    getFileJson(info) {
        let files = info.file;
        let name = files.name;
        // 获取文件后缀
        let suffix = name.substr(name.lastIndexOf("."));
        let reader = new FileReader();
        reader.onload = (event) => {
            try {
                // 判断文件类型是否正确
                if (".xls" != suffix && ".xlsx" != suffix) {
                    message.error("选择Excel格式的文件导入!");
                    return false;
                }
                let { result } = event.target;
                // 读取文件
                let workbook = XLSX.read(result, { type: "binary" });
                let keyRows =
                    workbook.Sheets[workbook.SheetNames[0]];
                let data = XLSX.utils.sheet_to_row_object_array(keyRows, {
                    header: 1,
                    defval: "",
                });
                data = this.changeTableData(data);
                this.setState({
                    lists: data,
                })
            } catch (e) {
                message.error("文件类型不正确！");
            }
        };
        reader.readAsBinaryString(files);
    }
    save() {
        let datas = this.state.lists;
        if (datas.length == 0) {
            return message.error('不能上传空数据！', 1.2)
        }
        this.setState({
            upLoading: true,
        }, () => {
            global.Ajax.hhtc_request('classes/addClasss', { datas }).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                this.setState({ upLoading: false, })
            })
        })
    }
    render() {
        const props = {
            accept: ".xls,.xlsx",
            name: "file",
            beforeUpload: (file) => {
                let houz = file.name.split(".");
                if (
                    houz[houz.length - 1] !== "xls" &&
                    houz[houz.length - 1] !== "xlsx"
                ) {
                    message.error("上传文件格式有误！", 1.2);
                    return Upload.LIST_IGNORE;
                }
                return false;
            },
            onChange: (e) => {
                this.setState({
                    fileList: e.fileList,
                });
                this.getFileJson(e);
            },
            onDrop(e) { },
            onRemove: (file) => {
                const index = this.state.fileList.indexOf(file);
                const newFileList = this.state.fileList.slice();
                newFileList.splice(index, 1);
                this.setState({
                    fileList: newFileList,
                    answer: [],
                });
            },
        };
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入保育员姓名/手机号查询'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                        value={this.state.pword}
                        style={{ width: 260 }}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        value={this.state.school_id}
                        onChange={(school_id) => {
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' className='marginr12' onClick={this.chahgeVisible.bind(this, 'addVisible')}>新增班级</Button>
                    <Button type='primary' className='fu1 marginr12' onClick={() => {
                        let a = document.createElement("a");
                        a.download = '批量导入班级模板.xlsx';
                        a.href = './class.xlsx';
                        a.click();
                    }}>下载模板</Button>
                    <Button type='primary' className='fu2 marginr12' onClick={() => {
                        this.setState({
                            upVisible: true,
                        })
                    }}>批量导入</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>班级列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}班级`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    width={360}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <Form.Item
                            name='school_id'
                            label='所属学校'
                            rules={[{ required: true, message: '请选择所属单位！' }]}
                        >
                            <SelectList
                                type='allschool'
                                placeholder='请选择所属单位'
                                disabled={this.state.type == 'edit' ? true : false}
                            />
                        </Form.Item>
                        <Form.Item
                            name='class_name'
                            label='班级名称'
                            rules={[{ required: true, message: '请输入班级名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入班级名称' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 上传文件 */}
                <CustomModal
                    visible={this.state.upVisible}
                    width={480}
                    title="导入数据"
                    onCancel={this.onCancel.bind(this)}
                >
                    <Dragger fileList={this.state.fileList} maxCount={1} {...props}>
                        <p className="ant-upload-drag-icon">
                            <img alt="" src={require("../../imgs/up.png").default} />
                        </p>
                        <p
                            className="ant-upload-text"
                            style={{ fontSize: 16, color: "#666" }}
                        >
                            将文件拖到此处，或{" "}
                            <span style={{ color: "#1E7FFF" }}>点击上传</span>
                        </p>
                    </Dragger>
                    <div className="flexAllCenter" style={{ marginTop: 50 }}>
                        <Button
                            onClick={this.onCancel.bind(this)}
                            style={{
                                background: "#F6F6F6",
                                borderColor: "#F6F6F6",
                                marginRight: 20,
                            }}
                        >
                            关闭
                        </Button>
                        <Button type="primary" loading={this.state.upLoading} onClick={this.save.bind(this)}>
                            确定
                        </Button>
                    </div>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'classes/addClass';
            if (this.state.type == 'edit') {
                url = 'classes/editClass';
                req.id = this.state.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
            upVisible: false,
            fileList: [],
            lists: [],
        })
    }
}