import React from 'react';
import { Layout, Menu, Breadcrumb, Button, Dropdown, Modal, message, Tabs } from 'antd';
import {
    Route,
    Switch,
    HashRouter as Router,
    Redirect,
} from 'react-router-dom';

import RoleList from './Basic/RoleList';  //角色列表
import AdminList from './Basic/AdminList';  //管理员列表
import OperationLog from './Basic/OperationLog';  //操作日志
import MenuSet from './Set/MenuSet';  //菜单设置
import UploadSet from './Set/UploadSet';  //上传设置
import BasicInfo from './Set/BasicInfo';  //基本信息设置配置

import SetColor from './SetColor';  //主题
import EditPwd from './EditPwd';  //修改密码
import UserInfo from './UserInfo';  //个人信息

import Datas from './Datas';  //数据统计
import SchoolList from './SchoolManage/SchoolList';  //学校列表
import NurseList from './SchoolManage/NurseList';  //保育员列表
import ClassList from './SchoolManage/ClassList';  //班级列表
import StudentList from './SchoolManage/StudentList';  //学生列表
import CheckType from './CheckManage/CheckType';  //检查类型管理
import CheckList from './CheckManage/CheckList';  //体检项目列表
import MAList from './MAmanage/MAList';  //学校晨午检项目
import MARecord from './MAmanage/MARecord';  //学校晨午检记录
import StudentMARecord from './MAmanage/StudentMARecord';  //学生晨午检记录
import Notice from './Physical/Notice';  //体检通知
import Plan from './Physical/Plan';  //体检计划安排
import Record from './Physical/Record';  //体检明细
import ProjList from './InspectManage/ProjList';  //检查项目列表
import SchoolCheckProj from './InspectManage/SchoolCheckProj';  //学校检查项目列表
import CheckReport from './InspectManage/CheckReport';  //检查报告记录列表
import CompanyList from './Set/CompanyList';  //单位管理
import JobList from './Set/JobList';  //职务管理
import UserList from './Set/UserList';  //人员列表
import TimeSet from './Set/TimeSet';  //晨午检配置
import Tige from './Set/Tige';  //体格数据管理
import Health from './Labels/Health';  // 健康标签
import Weather from './Labels/Weather';  // 天气标签
import ArticleType from './Article/ArticleType';  // 文章分类
import ArticleList from './Article/ArticleList';  // 文章列表
import Height from './Labels/Height';  // 身高标签
import Weight from './Labels/Weight';  // 体重
import BMI from './Labels/BMI';  // BMI
import TypeList from './InspectManage/TypeList';  // 巡检类目管理

import CustomModal from '../common/Modal';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const Components = {
    'Rolelist': RoleList,
    "Adminlist": AdminList,
    "Operationlog": OperationLog,
    "Menuset": MenuSet,
    "Uploadset": UploadSet,
    "Basicinfo": BasicInfo,
    "Datas": Datas,
    "Schoollist": SchoolList,
    "Nurselist": NurseList,
    "Classlist": ClassList,
    "Studentlist": StudentList,
    "Checktype": CheckType,
    "Checklist": CheckList,
    "Malist": MAList,
    "Marecord": MARecord,
    "Studentmarecord": StudentMARecord,
    "Notice": Notice,
    "Plan": Plan,
    "Record": Record,
    "Projlist": ProjList,
    "Schoolcheckproj": SchoolCheckProj,
    "Checkreport": CheckReport,
    "Companylist": CompanyList,
    "Joblist": JobList,
    "Userlist": UserList,
    "Timeset": TimeSet,
    "Tige": Tige,
    "Health": Health,
    "Weather": Weather,
    "ArticleType": ArticleType,
    "ArticleList": ArticleList,
    "Height": Height,
    "Weight": Weight,
    "TypeList": TypeList,
    "BMI": BMI,
}

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            menu: [],
            headerIcon: 'icon-jibenguanli',
            headerFirstName: '基本管理',
            headerSecName: '角色列表',
            visible: false,
            pwdVisible: false,
            infoVisible: false,
            systemName: "鸿鹄科技管理后台",
            username: "",
            avatar: "",
            selectKey: '',
            openKey: '',
            reqFinished: false,
            tabs: []
        }
        this.tabRef = [];
    }
    componentDidMount() {
        this.getUserInfo();
        setTimeout(() => {
            window.delDom()
        }, 2000);
    }
    toggle = () => {
        var zancOpenKey = '', openKey = '';
        let collapsed = !this.state.collapsed;
        if (collapsed) {  // 
            zancOpenKey = this.state.openKey;
            openKey = ''
        } else {
            openKey = this.state.zancOpenKey;
            zancOpenKey = ''
        }
        this.setState({
            collapsed: !this.state.collapsed,
            openKey,
            zancOpenKey,
        });
        // var zancOpenKey = '', openKey = '';
        // if (!this.state.collapsed) {
        //     zancOpenKey = this.state.openKey;
        //     openKey = ''
        // } else {
        //     openKey = this.state.zancOpenKey;
        //     zancOpenKey = ''
        // }
        // this.setState({
        //     collapsed: !this.state.collapsed,
        //     // openKey,
        //     // zancOpenKey,
        // });
    };
    changeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    getUserInfo() {
        global.Ajax.hhtc_request("admin/getLoginInfo", {}).then(data => {
            if (data.code === 1) {
                // let menu=[
                //     { id: 26,path: 'datas', title: '数据统计', icon: 'icon-wenzhang', child: [] },
                //     { id: 27,path: '', title: '学校管理', icon: 'icon-wenzhang', child: [
                //         {id: 28,path: 'schoollist',title: '学校列表',icon: '',child: []},
                //         {id: 29,path: 'nurselist',title: '保育员列表',icon: '',child: []},
                //         {id: 30,path: 'classlist',title: '班级列表',icon: '',child: []},
                //         {id: 31,path: 'studentlist',title: '学生列表',icon: '',child: []},
                //     ] },
                //     { id: 32,path: '', title: '体检项目管理', icon: 'icon-wenzhang', child: [
                //         {id: 33,path: 'checktype',title: '检查类型管理',icon: '',child: []},
                //         {id: 34,path: 'checklist',title: '体检项目列表',icon: '',child: []},
                //     ] },
                //     { id: 35,path: '', title: '晨午检管理', icon: 'icon-wenzhang', child: [
                //         {id: 36,path: 'malist',title: '学校晨午检项目',icon: '',child: []},
                //         {id: 37,path: 'marecord',title: '学校晨午检记录',icon: '',child: []},
                //         {id: 50,path: 'studentmarecord',title: '学生晨午检记录',icon: '',child: []},
                //     ] },
                //     { id: 38,path: '', title: '体检管理', icon: 'icon-wenzhang', child: [
                //         {id: 39,path: 'notice',title: '体检通知',icon: '',child: []},
                //         {id: 40,path: 'plan',title: '体检计划安排',icon: '',child: []},
                //         {id: 41,path: 'record',title: '体检明细',icon: '',child: []},
                //     ] },
                //     { id: 42,path: '', title: '常规检查', icon: 'icon-wenzhang', child: [
                //         {id: 43,path: 'projlist',title: '检查项目列表',icon: '',child: []},
                //         {id: 44,path: 'schoolcheckproj',title: '学校检查项目',icon: '',child: []},
                //         {id: 45,path: 'checkreport',title: '检查报告项目',icon: '',child: []},
                //     ] },
                //     { id: 46,path: '', title: '系统设置', icon: 'icon-wenzhang', child: [
                //         {id: 52,path: 'menuset',title: '菜单管理',icon: '',child: []},
                //         {id: 47,path: 'companylist',title: '单位管理',icon: '',child: []},
                //         {id: 51,path: 'joblist',title: '职务管理',icon: '',child: []},
                //         {id: 48,path: 'userlist',title: '人员管理',icon: '',child: []},
                //         {id: 49,path: 'timeset',title: '晨午检配置',icon: '',child: []},
                //     ] },
                // ]
                let menu = data.data.menus;
                document.title = data.data.name;
                // 
                let row = menu.find(item => item.title == '学校管理');
                if (row) {
                    let classPageId = row.child.find(item => item.title == '班级列表'),
                        byyPageId = row.child.find(item => item.title == '保育员列表'),
                        studentPageId = row.child.find(item => item.title == '学生列表');
                    this.setState({
                        classPageId, byyPageId, studentPageId, schoolRow: row
                    })
                }

                var tabs = [], activeKey = '', selectKey, openKey;
                if (menu.length > 0) {
                    var path;
                    if (menu[0].child.length > 0) {
                        path = (menu[0].child[0].path.split('/'))[menu[0].child[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].child[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].child[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].child[0].id
                        }]
                    } else {
                        path = (menu[0].path.split('/'))[menu[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].id,
                        }]
                    }
                    activeKey = tabs[0].key;
                    selectKey = tabs[0].menuKey;
                    openKey = tabs[0].subKey;
                }
                console.log(data);
                this.setState({
                    systemName: data.data.name,
                    username: data.data.username,
                    avatar: data.data.avatar,
                    menu,
                    tabs,
                    activeKey,
                    selectKey,
                    openKey,
                    reqFinished: true,
                })
            }
        })
    }
    handMenu(e) {
        let openKey = this.state.openKey,
            zancOpenKey = this.state.openKey;
        if (this.state.collapsed || e.keyPath.length == 1) {
            openKey = '';
        }
        this.setState({
            selectKey: e.key,
            zancOpenKey,
            openKey,
        })
        // this.setState({
        //     selectKey: e.key
        // })
    }
    handTitle(e) {
        let openKey = ''
        if (this.state.openKey != e.key) {
            openKey = e.key;
        }
        this.setState({
            openKey
        })
        // this.setState({
        //     openKey: e.key
        // })
    }
    // 刷新
    onRefresh = () => {
        this.tabRef[this.state.selectKey].refresh && this.tabRef[this.state.selectKey].refresh()
    }
    render() {
        const { collapsed } = this.state;
        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.changeVisible.bind(this, 'pwdVisible')}>修改密码</Menu.Item>
                <Menu.Item key="2" onClick={this.changeVisible.bind(this, 'infoVisible')}>个人信息</Menu.Item>
                <Menu.Item key="3" onClick={() => {
                    window.reset()
                    localStorage.removeItem('honghuToken')
                    message.success("再见", 1, () => {
                        window.location.href = ''
                    })
                }}>退出登录</Menu.Item>
            </Menu>
        )
        const list = (path, id) => {
            var MyComponentt = Components[path];
            return <MyComponentt
                ref={dom => this.tabRef[id] = dom}
                classPageId={this.state.classPageId}
                byyPageId={this.state.byyPageId}
                studentPageId={this.state.studentPageId}
                schoolRow={this.state.schoolRow}
                schoolId={this.state.school_id}
                teacherName={this.state.teacher_id}
                classId={this.state.class_id}
                onChange={({ row, item, school_id, teacher_id, class_id }) => {
                    this.setState({
                        school_id,
                        teacher_id,
                        class_id
                    }, () => {
                        let type = '';
                        if (school_id && !class_id) {
                            type = 'school'
                        } else if (teacher_id) {
                            type = 'teacher'
                        } else if (class_id) {
                            type = 'class'
                        }
                        this.add(row, item, type)
                    })
                }}
            />;
        }
        return (
            <Layout style={{ minHeight: '100vh', _height: '100vh' }}>
                <Sider trigger={null} theme='light' width={240} collapsible collapsed={collapsed} onCollapse={this.toggle} className='leftMenu'>
                    <div className="logo">
                        <p>{this.state.systemName && this.state.systemName.substr(0, 2)}</p>
                        <h1>{this.state.systemName}</h1>
                    </div>
                    {this.state.reqFinished && <Menu openKeys={[this.state.openKey]} selectedKeys={[String(this.state.selectKey)]} mode="inline" onClick={this.handMenu.bind(this)}>
                        {this.state.menu.map((item, index) => (
                            <React.Fragment key={'' + index}>
                                {item.child.length > 0 ?
                                    <SubMenu key={`sub${item.id}`} icon={<p className={`iconfont ${item.icon}`}></p>} title={item.title} onTitleClick={this.handTitle.bind(this)}>
                                        {item.child.map((row, k) => (
                                            <Menu.Item key={String(row.id)} onClick={this.add.bind(this, row, item)}>{row.title}</Menu.Item>
                                        ))}
                                    </SubMenu>
                                    :
                                    <Menu.Item className='firr' key={String(item.id)} icon={<p className={`iconfont ${item.icon}`}></p>} onClick={this.add.bind(this, item, item)}>{item.title}</Menu.Item>
                                }
                            </React.Fragment>
                        ))}
                    </Menu>}
                </Sider>
                <Layout className="site-layout">
                    <Header>
                        <div className='topnav'>
                            <p className={`iconfont ${this.state.collapsed ? 'icon-zhankai' : 'icon-shouqi'} leftMenuToggle`} onClick={this.toggle.bind(this)}></p>
                            <p className={`iconfont icon-shuaxin leftMenuToggle`} onClick={this.onRefresh}></p>
                            <div className='zhut flexCenter' onClick={this.changeVisible.bind(this, 'visible')}>
                                <p className='iconfont icon-zhuti'></p>
                                <p>主题</p>
                            </div>
                            {this.state.avatar == "" ?
                                <img alt='' src={require('../imgs/default.png').default} className='avatar' />
                                :
                                <img alt='' src={this.state.avatar} className='avatar' />
                            }
                            <Dropdown placement='bottomCenter' overlay={menu}>
                                <div className='flexCenter'>
                                    <p>{this.state.username}</p>
                                    <span className='iconfont icon-jiantou-shang'></span>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content>
                        {this.state.reqFinished && <Tabs
                            type="editable-card"
                            hideAdd={true}
                            activeKey={this.state.activeKey || 'tab0'}
                            className='asdTabs'
                            onChange={this.onChange}
                            onEdit={this.onEdit}
                        >
                            {this.state.tabs.map(item => (
                                <TabPane closable={this.state.tabs.length == 1 ? false : true} closeIcon={(<p className='iconfont icon-guanbi closeTab'></p>)} tab={item.title} key={item.key}>
                                    {list(item.component, item.menuKey)}
                                </TabPane>
                            ))}
                        </Tabs>}
                        {/* <Router>
                            <Switch>
                                <Route path="/rout/rolelist" component={RoleList} />
                                <Route path="/rout/adminlist" component={AdminList} />
                                <Route path="/rout/operationlog" component={OperationLog} />
                                <Route path="/rout/menuset" component={MenuSet} />
                                <Route path="/rout/uploadset" component={UploadSet} />
                                <Route path="/rout/basicinfo" component={BasicInfo} />
                            </Switch>
                        </Router> */}
                    </Content>
                </Layout>
                {/* 修改主题 */}
                <CustomModal
                    visible={this.state.visible}
                    width={1172}
                    title='主题配色'
                    onCancel={this.onCancel.bind(this)}
                >
                    <SetColor ref={dom => this.setcolor = dom} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
                </CustomModal>
                {/* 修改密码 */}
                <CustomModal
                    visible={this.state.pwdVisible}
                    width={360}
                    title='修改密码'
                    onCancel={this.onCancel.bind(this)}
                >
                    <EditPwd ref={dom => this.editpwd = dom} onCancel={this.onCancel.bind(this)} />
                </CustomModal>
                {/* 个人信息 */}
                <CustomModal
                    visible={this.state.infoVisible}
                    width={360}
                    title='个人信息'
                    onCancel={this.onCancel.bind(this)}
                >
                    <UserInfo
                        ref={dom => this.userinfo = dom}
                        avatar={this.state.avatar}
                        username={this.state.username}
                        onCancel={this.onCancel.bind(this)}
                        onRefresh={this.getUserInfo.bind(this)}
                    />
                </CustomModal>
            </Layout>
        )
    }
    add(row, itemm, type) {
        // console.log(row)
        var { tabs } = this.state;
        var rowItem = tabs.find(item => item.title == row.title);
        console.log(rowItem)
        if (!rowItem) {
            var path = (row.path.split('/'))[row.path.split('/').length - 1];
            var obj = {
                title: row.title,
                component: path.substr(0, 1).toUpperCase() + path.substr(1),
                key: 'tab' + row.id,
                subKey: 'sub' + itemm.id,
                menuKey: row.id,
            }
            tabs.push(obj);
            this.setState({
                tabs,
                activeKey: obj.key,
                selectKey: obj.menuKey,
            }, () => {
                console.log(type);
                if (type == 'school') {
                    this.tabRef[obj.menuKey].setState({
                        school_id: this.state.school_id,
                    }, () => {
                        this.tabRef[obj.menuKey].getAllClass&&this.tabRef[obj.menuKey].getAllClass(this.state.school_id, 'search')
                        this.tabRef[obj.menuKey].refresh();
                    })
                } else if (type == 'teacher') {
                    this.tabRef[obj.menuKey].setState({
                        pword: this.state.teacher_id,
                    }, () => {
                        this.tabRef[obj.menuKey].refresh()
                    })
                } else if (type == 'class') {
                    this.tabRef[obj.menuKey].setState({
                        school_id: this.state.school_id,
                        class_id: this.state.class_id,
                    }, () => {
                        this.tabRef[obj.menuKey].getAllClass(this.state.school_id, 'search')
                        this.tabRef[obj.menuKey].refresh()
                    })
                }
            })
        } else {
            this.setState({
                activeKey: rowItem.key,
                selectKey: rowItem.menuKey,
            }, () => {
                console.log(type);
                if (type == 'school') {
                    this.tabRef[rowItem.menuKey].setState({
                        school_id: this.state.school_id,
                    }, () => {
                        this.tabRef[rowItem.menuKey].refresh()
                        this.tabRef[rowItem.menuKey].getAllClass&&this.tabRef[rowItem.menuKey].getAllClass(this.state.school_id, 'search')
                    })
                } else if (type == 'teacher') {
                    this.tabRef[rowItem.menuKey].setState({
                        pword: this.state.teacher_id,
                    }, () => {
                        this.tabRef[rowItem.menuKey].refresh()
                    })
                } else if (type == 'class') {
                    this.tabRef[rowItem.menuKey].setState({
                        school_id: this.state.school_id,
                        class_id: this.state.class_id,
                    }, () => {
                        this.tabRef[rowItem.menuKey].getAllClass(this.state.school_id, 'search')
                        this.tabRef[rowItem.menuKey].refresh()
                    })
                }
            })
        }
        // if (row.path != '') {
        //     // this.props.history.push(row.path)
        //     this.setState({
        //         headerIcon: itemm.icon,
        //         headerFirstName: itemm.title,
        //         headerSecName: row.title
        //     })
        // }
    }
    onEdit = (targetKey, action) => {
        this[action](targetKey)
    }
    remove = targetKey => {
        const { tabs, activeKey } = this.state;
        let newActiveKey = activeKey, selectKey = '', openKey = '';
        let lastIndex;
        tabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = tabs.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
                selectKey = newPanes[lastIndex].menuKey;
                openKey = newPanes[lastIndex].subKey;
            } else {
                newActiveKey = newPanes[0].key;
            }
        } else {
            newActiveKey = newPanes[newPanes.length - 1].key;
            selectKey = newPanes[newPanes.length - 1].menuKey;
            openKey = newPanes[newPanes.length - 1].subKey;
        }
        let zancOpenKey = openKey;
        if (!this.state.collapsed) {
            // openKey=openKey;
        } else {
            openKey = '';
        }
        this.setState({
            tabs: newPanes,
            activeKey: newActiveKey,
            openKey,
            selectKey,
            zancOpenKey
        });
    }
    onChange = (activeKey) => {
        const { tabs } = this.state;
        var row = tabs.find(item => item.key == activeKey);
        let openKey = '',
            zancOpenKey = row.subKey;
        if (!this.state.collapsed) {
            openKey = row.subKey;
        }
        this.setState({
            activeKey,
            selectKey: row.menuKey,
            openKey,
            zancOpenKey
        })
    }
    onOk() {
        this.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            pwdVisible: false,
            infoVisible: false,
        })
    }
}
