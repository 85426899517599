import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch,Popconfirm } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import ProjList from '../MAmanage/ProjList';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
            editData: {}
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '检查项目数',
                dataIndex: 'p_count',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.chahgeVisible.bind(this,'visible',item)}>检查项目详情</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || ''
        }
        global.Ajax.hhtc_request('patrol/schoolPatrolList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='rowFlex'>学校名称</p>
                {/* <p className='rowFlex'>行政区域</p> */}
                <p className='rowFlex'>检查项目数</p>
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <Tooltip title={item.school_name}>
                            <p className='rowFlex'>{item.school_name}</p>
                        </Tooltip>
                        {/* <Tooltip title={item.address}>
                            <p className='rowFlex'>{item.address}</p>
                        </Tooltip> */}
                        <p className='rowFlex'>{item.p_count}</p>
                        <div className='row15 flexAllCenter pubbtnbox'>
                            <p onClick={this.chahgeVisible.bind(this,'visible',item)}>检查项目详情</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    chahgeVisible(key,data) {
        this.setState({
            [key]: true,
            editData: data
        })
    }
    tongbu() {
        global.Ajax.hhtc_request('patrol/setSchoolPatrol', {}).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 1.2)
                this.refresh()
            } else {
                message.error(res.msg, 1.2)
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入项目名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            },()=>{
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allschool'
                        className='borderbai marginr12'
                        placeholder='请选择学校'
                        style={{ width: 160 }}
                        onChange={(school_id)=>{
                            this.setState({
                                school_id,
                            },()=>{
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Popconfirm
                        title='确定要同步所有项目？'
                        onConfirm={this.tongbu.bind(this)}
                    >
                        <Button type='primary'>同步所有项目</Button>
                    </Popconfirm>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学校检查项目列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                <CustomModal
                    width={900}
                    visible={this.state.visible}
                    title={`${this.state.editData.school_name}项目列表`}
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <ProjList
                        id={this.state.editData.school_id}
                        type='schoolPatrol'
                        onCancel={this.onCancel.bind(this)}
                        onOk={this.onOk.bind(this)}
                    />
                </CustomModal>
            </React.Fragment>
        )
    }
    onOk(){
        this.onCancel()
        this.refresh()
    }
    onCancel(){
        this.setState({
            visible: false,
            editData: {}
        })
    }
}