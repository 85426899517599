import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch,Spin } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CheckBox from '../../common/CheckBox';  //多选框

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            list: [],
            requestLoadingShow: true,
            editData: {
                roles: ''
            }
        }
    }
    componentDidMount() {
        // 获取所有菜单
        global.Ajax.hhtc_request('job/addRoleGetMenus', {}).then(res => {
            if (res.code == 1) {
                var data = this.initData(res.data);
                for (var i in data) {
                    for (var j in data[i].child) {
                        data[i].child[j].child = this.initData(data[i].child[j].child)
                    }
                    data[i].child = this.initData(data[i].child)
                }
                this.setState({
                    list: data,
                })
            }
            this.setState({
                requestLoadingShow: false,
            })
        })
        // 获取职务类型
        global.Ajax.hhtc_request('job/jobTypeList',{}).then(res=>{
            if(res.code==1){
                let arryNew = []
                res.data.map((item, index) => {
                    arryNew.push(Object.assign({}, { value: item.id, label: item.type_name }))
                })
                this.setState({
                    typeList: arryNew,
                })
            }
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            arryNew.push(Object.assign({}, item, { value: item.id, label: item.name }))
        })
        return arryNew
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(page, callback) {
        var orderBy = '';
        if (this.state.orderBy != '') {
            orderBy = this.state.orderBy;
        }
        var req = {
            page: page,
            size: this.state.size,
            orderBy
        }
        global.Ajax.hhtc_request('job/jobList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='rowFlex'>职务名称</p>
                {/* <p className='rowFlex'>所属单位</p>
                <p className='row1'>职务类型</p> */}
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <Tooltip title={item.name}>
                            <p className='rowFlex'>{item.name}</p>
                        </Tooltip>
                        {/* <Tooltip title={item.sup_name}>
                            <p className='rowFlex'>{item.sup_name}</p>
                        </Tooltip>
                        <p className='row1'>
                            {item.type == 1 && <Button type='primary'>单位领导</Button>}
                            {item.type == 2 && <Button type='primary' className='fu1'>普通职务</Button>}
                            {item.type == 3 && <Button type='primary' className='fu2'>体检人员</Button>}
                        </p> */}
                        <div className='row15 flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
        setTimeout(() => {
            this.formRef.setFieldsValue(data)
        }, 100);
    }
    // 删除
    del(data) {
        var url = 'job/delJob', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加职务</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>职务列表</h2>
                    <TabbleList
                        ref={dom => this.tabblelist = dom}
                        size={this.state.size}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        renderHead={this.renderHead.bind(this)}  //表头
                        renderItem={this.renderItem.bind(this)}  //表内容
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    width={800}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}职务`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='name'
                                label='职务名称'
                                rules={[{ required: true, message: '请输入职务名称！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入职务名称' />
                            </Form.Item>
                            {/* <Form.Item
                                name='department_id'
                                label='所属单位'
                                rules={[{ required: true, message: '请选择所属单位！' }]}
                                className='item48'
                            >
                                <SelectList
                                    type='allcompany'
                                    placeholder='请选择所属单位'
                                />
                            </Form.Item>
                            {this.state.typeList&&<Form.Item
                                name='type'
                                label='职务类型'
                                rules={[{ required: true, message: '请选择职务类型！' }]}
                                className='item48'
                            >
                                <SelectList
                                    data={this.state.typeList}
                                    placeholder='请选择职务类型'
                                />
                            </Form.Item>} */}
                            {this.state.requestLoadingShow && <Spin tip="加载中..." style={{ display: 'block', margin: '50px auto 0' }} />}
                            {!this.state.requestLoadingShow && <Form.Item style={{width: '100%'}} className='qxlistt' name="ids" label="职务权限：" rules={[{ validator: this.checkIds,required: true }]}>
                                <CheckBox ref={dom => this.role = dom} data={this.state.list} values={this.state.editData.roles.split(',').map(Number)} />
                            </Form.Item>}
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    checkIds=()=>{
        // console.log(this.role.state.checkedValues)
        if(this.role.state.checkedValues.length==0){
            return Promise.reject(new Error('请选择角色权限!'));
        }
        return Promise.resolve();
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true
        },()=>{
            let url='job/addJob';
            if(this.state.type=='edit'){
                url='job/editJob';
                req.id=this.state.editData.id;
            }
            req.roles=this.role.state.checkedValues.join(',');
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.5)
                    this.onCancel()
                    this.refresh()
                }else{
                    message.error(res.msg,1.5)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            editData: {
                roles: ''
            },
            type: ''
        })
    }
}