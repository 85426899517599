import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal, DatePicker, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Ueditor from '../../common/Ueditor';
import AddArticle from './AddArticle';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'sort',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '文章标题',
                dataIndex: 'title',
                align: 'center',
            }, {
                title: '文章内容',
                dataIndex: 'content',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: title => (
                    <Tooltip title={
                        <div className='xxd' dangerouslySetInnerHTML={{ __html: Helper.getValue(title) }}></div>
                    }>
                        {/* {Helper.getValue(title)} */}
                        <div className='xxxxx' dangerouslySetInnerHTML={{ __html: Helper.getValue(title) }}></div>
                    </Tooltip>
                )
            }, {
                title: '分类',
                dataIndex: 'type_names',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: title => (
                    <Tooltip title={title} placement='top'>
                        <p className='xxxxx'>{title}</p>
                    </Tooltip>
                )
            }, {
                title: '天气标签',
                dataIndex: 'weather_tags_name',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: title => (
                    <Tooltip title={title} placement='top'>
                        <p className='xxxxx'>{title}</p>
                    </Tooltip>
                )
            }, {
                title: '健康标签',
                dataIndex: 'tags_name',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: title => (
                    <Tooltip title={title} placement='top'>
                        <p className='xxxxx'>{title}</p>
                    </Tooltip>
                )
            }, {
                title: '是否显示',
                dataIndex: 'display',
                align: 'center',
                render: e => (
                    <Switch disabled checked={e == 1 ? true : false} unCheckedChildren='隐藏' checkedChildren='显示' />
                )
            }, {
                title: '是否置顶',
                dataIndex: 'is_top',
                align: 'center',
                render: e => (
                    <Switch disabled checked={e == 1 ? true : false} unCheckedChildren='未置顶' checkedChildren='已置顶' />
                )
            }, {
                title: '发布日期',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '作者',
                dataIndex: 'author',
                align: 'center',
                width: 150
            }, {
                title: '浏览量',
                dataIndex: 'look_num',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (_, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={() => {
                                this.setState({
                                    editData: item,
                                    pushVisible: true,
                                })
                            }}>推送文章</p>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            title: this.state.title || '',
            departmen_id: this.state.departmen_id || 0,
            tag_id: this.state.tag_id || 0,
            weather_tag_id: this.state.weather_tag_id || 0,
            type_id: this.state.type_id || 0,
            date: this.state.date || '',
        }
        global.Ajax.hhtc_request('article/articleList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
    }
    // 删除
    del(data) {
        var url = 'article/delArticle', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入文章标题'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                title: e.target.value
                            }, () => {
                                this.refresh()
                            })
                        }}
                    />
                    <SelectList
                        type='ArticleType'
                        placeholder='请选择文章分类'
                        onChange={(type_id) => {
                            this.setState({
                                type_id
                            }, () => {
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{ width: 160 }}
                    />
                    <SelectList
                        type='WeatherTag'
                        placeholder='请选择天气标签'
                        onChange={(weather_tag_id) => {
                            this.setState({
                                weather_tag_id
                            }, () => {
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{ width: 160 }}
                    />
                    <SelectList
                        type='Tag'
                        placeholder='请选择健康标签'
                        onChange={(tag_id) => {
                            this.setState({
                                tag_id
                            }, () => {
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{ width: 160 }}
                    />
                    <SelectList
                        type='department'
                        placeholder='请选择部门'
                        onChange={(departmen_id) => {
                            this.setState({
                                departmen_id
                            }, () => {
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{ width: 160 }}
                    />
                    <DatePicker
                        className='borderbai marginr12'
                        onChange={(_, date) => {
                            this.setState({
                                date
                            }, () => {
                                this.refresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加文章</Button>
                </div>
                <div className='bgbai flexColumn'>
                    <h2 className='pubTit'>文章列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                        scroll={{ x: 1600 }}
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={890}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}文章`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <AddArticle
                        type={this.state.type}
                        data={this.state.editData}
                        onOk={() => {
                            this.onCancel()
                            this.refresh()
                        }}
                    />
                </CustomModal>
                {/* 推送 */}
                <CustomModal
                    visible={this.state.pushVisible}
                    width={540}
                    title='推送文章'
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '70px' }}
                        onValuesChange={(res, allValues) => {
                            let key = (Object.keys(res))[0];
                            if (key == 'type_config') {
                                let values = allValues.type_config;
                                let key1 = (Object.keys(res[key]))[0];
                                let value = Helper.getNumber(res[key][key1]);
                                values[key1] = value;
                                this.formRef.setFieldsValue({
                                    type_config: values
                                })
                            }
                        }}
                    >
                        <Form.Item
                            name='type_config'
                            label='年龄段'
                            className='row10'
                        >
                            <Input.Group compact>
                                <Form.Item
                                    name={['type_config', 'min']}
                                    noStyle
                                >
                                    <Input autoComplete='off' style={{ width: 196, textAlign: 'center', borderRight: 0 }} placeholder="最小值不能小于0" />
                                </Form.Item>
                                <Input
                                    className="site-input-split"
                                    style={{
                                        width: 30,
                                        borderLeft: 0,
                                        borderRight: 0,
                                        pointerEvents: 'none', background: 'transparent'
                                    }}
                                    placeholder="~"
                                    disabled
                                />
                                <Form.Item
                                    name={['type_config', 'max']}
                                    noStyle
                                >
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 196,
                                            textAlign: 'center', borderLeft: 0
                                        }}
                                        placeholder="最大值不能大于7" autoComplete='off'
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item name='sex' label='性别' className='row10'>
                            <Select
                                options={[
                                    { value: 1, label: '男' },
                                    { value: 2, label: '女' },
                                ]}
                                placeholder='请选择性别'
                                allowClear
                            ></Select>
                        </Form.Item>
                        <Form.Item name='tags' label='健康标签' className='row10'>
                            <SelectList
                                type='Tag'
                                placeholder='请选择'
                                mode='multiple'
                            />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        if (req.type_config && req.type_config.min && req.type_config.max) {
            req.min = Number(req.type_config.min);
            req.max = Number(req.type_config.max);
            if (req.min < 0) {
                return message.error('最小值不能小于0')
            }
            if (req.max > 7) {
                return message.error('最大值不能超过7')
            }
            if (req.max <= req.min) {
                return message.error('最大值不能小于最小值')
            }
        } else {
            // return message.error('年龄段不正确', 1.5)
        }
        this.setState({
            loading: true,
        }, () => {
            req.id = this.state.editData.id;
            req.tags = req.tags ? req.tags.join(',') : '';
            delete req.type_config;
            global.Ajax.hhtc_request('article/sendArticleNotice', req).then(res => {
                if (res.code == 1) {
                    message.success('推送成功', 1.2)
                    this.onCancel()
                } else {
                    message.error(res.msg, 1.5)
                }
                this.setState({ loading: false })
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            editData: {},
            type: '',
            pushVisible: false,
        })
    }
}