import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Ueditor from '../../common/Ueditor';

const typeList=[
    { label: '身高类', value: 1 },
    { label: '体重类', value: 2 },
    { label: '其他类', value: 3 },
];
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '月龄',
                dataIndex: 'month',
                align: 'center',
            }, {
                title: '性别',
                dataIndex: 'sex',
                align: 'center',
                render: sex=>`${sex==1?'男':'女'}`
            }, {
                title: '-3SD',
                dataIndex: '-3sd',
                align: 'center',
            }, {
                title: '-2SD',
                dataIndex: '-2sd',
                align: 'center',
            }, {
                title: '-1SD',
                dataIndex: '-1sd',
                align: 'center',
            }, {
                title: '中位数',
                dataIndex: 'middle',
                align: 'center',
            }, {
                title: '+1SD',
                dataIndex: '1sd',
                align: 'center',
            }, {
                title: '+2SD',
                dataIndex: '2sd',
                align: 'center',
            }, {
                title: '+3SD',
                dataIndex: '3sd',
                align: 'center',
            },
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            sex: this.state.sex || ''
        }
        global.Ajax.hhtc_request('tag/tizhong', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <SelectList
                        data={[
                            {label: '男',value: 1,},
                            {label: '女',value: 2,},
                        ]}
                        placeholder='请选择性别'
                        onChange={(sex)=>{
                            this.setState({
                                sex
                            },()=>{
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{width: 160}}
                    />
                </div>
                <div className='bgbai flexColumn'>
                    <h2 className='pubTit'>列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
            </React.Fragment>
        )
    }
}