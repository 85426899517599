import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            datas: props.data
        }
        console.log(props.data)
    }
    refresh() {
        this.tabblelist.refresh()
    }
    getList(page, callback) {
        var req = {
            log_id: this.state.datas.id || '',
        }
        global.Ajax.hhtc_request('patrol/schoolReportItemList', req).then(res => {
            let dataRow={
                code: res.code,
                data: {
                    all: 0,
                    datas: res.data
                }
            }
            callback(dataRow)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='rowFlex'>项目名称</p>
                <p className='row2'>检查情况</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <div className='boxx'>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <Tooltip title={item.name}>
                            <p className='rowFlex'>{item.name}</p>
                        </Tooltip>
                        <p className='row2'>{item.value}</p>
                    </div>
                ))}
            </div>
        )
    }
    render() {
        const datas = this.state.datas;
        return (
            <React.Fragment>
                <h2 className='pubTit' style={{ paddingTop: 0 }}>基本信息</h2>
                <div className='flwp'>
                    <p className='item24'>检查日期：<span>{datas.date}</span></p>
                    <p className='item24'>检查人员：<span>{datas.teacher_name}</span></p>
                    <p className='item24'>联系电话：<span>{datas.teacher_mobile}</span></p>
                    <p className='item24'>提交时间：<span>{datas.atime}</span></p>
                </div>
                <h2 className='pubTit'>检查列表</h2>
                <TabbleList
                    ref={dom => this.tabblelist = dom}
                    size={this.state.size}
                    onRefresh={this.onRefresh.bind(this)}  //刷新
                    renderHead={this.renderHead.bind(this)}  //表头
                    renderItem={this.renderItem.bind(this)}  //表内容
                    className='projListbox'
                />
            </React.Fragment>
        )
    }
}