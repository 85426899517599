import React from 'react';
import { Button, Input, message, Tooltip, Form, Upload } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import * as XLSX from "xlsx";
import CustomTable from '../../common/Table';

const { Dragger } = Upload;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'sort',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '添加时间',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>编辑</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
        }
        global.Ajax.hhtc_request('patrol/typeList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
        setTimeout(() => {
            this.formRef.setFieldsValue(data)
        }, 100);
    }
    // 删除
    del(data) {
        var url = 'patrol/delType', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Button type='primary' className='marginr12' onClick={this.chahgeVisible.bind(this, 'addVisible')}>新增</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>巡检类目列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}类目`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    width={360}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                        initialValues={{
                            sort: 0,
                        }}
                    >
                        <Form.Item
                            name='name'
                            label='类目名称'
                            rules={[{ required: true, message: '请输入类目名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入类目名称' />
                        </Form.Item>
                        <Form.Item
                            name='sort'
                            label='排序'
                        >
                            <Input autoComplete='off' placeholder='值越小越靠前' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'patrol/addType';
            if (this.state.type == 'edit') {
                url = 'patrol/editType';
                req.id = this.state.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
        })
    }
}