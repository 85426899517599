import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import Report from './Report';  //体检报告
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            visible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学生',
                dataIndex: 'student_name',
                align: 'center',
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
                render: (_, item) => (
                    <Tooltip title={item.school_name}>
                        <p>{item.school_name}</p>
                    </Tooltip>
                )
            }, {
                title: '班级',
                dataIndex: 'class_name',
                align: 'center',
            }, {
                title: '检查任务名称',
                dataIndex: 'name',
                align: 'center',
                render: (_, item) => (
                    <Tooltip title={item.name}>
                        <p>{item.name}</p>
                    </Tooltip>
                )
            }, {
                title: '体检单位名称',
                dataIndex: 'department_name',
                align: 'center',
            }, {
                title: '医生意见',
                dataIndex: 'option',
                align: 'center',
                render: (_, item) => (
                    <Tooltip title={item.option}>
                        <p>{item.option || '-'}</p>
                    </Tooltip>
                )
            }, {
                title: '体检日期',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={() => {
                            this.setState({
                                editData: item,
                                visible: true,
                            })
                        }}>查看体检报告</p>
                    </div>
                )
            }
        ]
    }
    getAllClass(id) {
        global.Ajax.hhtc_request('teacher/addGetClass', { school_id: id }).then(res => {
            if (res.code == 1) {
                let arryNew = []
                res.data.map(item => {
                    arryNew.push(Object.assign({}, { value: item.class_id, label: item.class_name }))
                })
                // this.setState({
                //     classList: arryNew,
                // })
                this.seltRef && this.seltRef.setState({
                    data: arryNew
                })
            } else {
                this.seltRef && this.seltRef.setState({
                    data: []
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || '',
            class_id: this.state.class_id || '',
        }
        global.Ajax.hhtc_request('inspectnotice/studentInspctList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row1'>学生</p>
                <p className='rowFlex'>学校名称</p>
                <p className='row1'>班级</p>
                <p className='rowFlex'>体检任务名称</p>
                <p className='row15'>体检单位名称</p>
                <p className='rowFlex'>医生意见</p>
                <p className='row1'>体检日期</p>
                <p className='row1'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <p className='row1'>{item.student_name}</p>
                        <Tooltip title={item.school_name}>
                            <p className='rowFlex'>{item.school_name}</p>
                        </Tooltip>
                        <p className='row1'>{item.class_name}</p>
                        <Tooltip title={item.name}>
                            <p className='rowFlex'>{item.name}</p>
                        </Tooltip>
                        <p className='row15'>{item.department_name}</p>
                        <Tooltip title={item.option}>
                            <p className='rowFlex'>{item.option}</p>
                        </Tooltip>
                        <p className='row1'>{item.atime}</p>
                        <div className='row1 flexAllCenter pubbtnbox'>
                            <p onClick={() => {
                                this.setState({
                                    editData: item,
                                    visible: true,
                                })
                            }}>查看体检报告</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入学生姓名'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(school_id) => {
                            this.getAllClass(school_id)
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        ref={dom => this.seltRef = dom}
                        placeholder='请选择班级'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(class_id) => {
                            this.setState({
                                class_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>体检明细列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 体检报告 */}
                <CustomModal
                    visible={this.state.visible}
                    width={800}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Report ref={dom => this.rptRef = dom} data={this.state.editData} onCancel={this.onCancel.bind(this)} />
                    <Button loading={this.state.loading} onClick={this.export.bind(this)} type='primary' style={{ display: 'block', margin: '30px auto 0' }}>下载体检报告</Button>
                </CustomModal>
            </React.Fragment>
        )
    }
    export() {
        this.setState({
            // loading: true,
        })
        this.rptRef.export()
    }
    onCancel() {
        this.setState({
            visible: false,
            loading: false,
        })
    }
}