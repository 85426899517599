import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
        }
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(page, callback) {
        let url,req;
        if(this.props.type=='notice'){
            url='inspectnotice/noticeItemList';
            req={
                notice_id: this.props.id
            }
        }else{
            url='inspectnotice/schoolPlanItemlList';
            req={
                plan_id: this.props.id
            }
        }
        global.Ajax.hhtc_request(url, req).then(res => {
            let datas={
                code: res.code,
                data: {
                    all: 0
                }
            }
            if(this.props.type=='notice'){
                datas.data.datas=res.data;
            }else{
                datas.data.datas=res.data.inspect_data;
            }
            callback(datas)
            if (res.code == 1) {
                if(this.props.type=='notice'){ }else{
                    this.setState({
                        date: res.data.date,
                        doctor_info: res.data.doctor_info,
                    })   
                }
            }
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                {this.props.type != 'notice' && <p className='row1'>负责人</p>}
                <p className='row2'>项目名称</p>
                <p className='row2'>检查类型</p>
                <p className='rowFlex'>项目简介</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <div className='boxx' style={{maxHeight: 'initial',overflow: 'hidden'}}>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        {this.props.type != 'notice' && <p className='row1'>{item.doctor_name}</p>}
                        <p className='row2'>{item.name}</p>
                        <p className='row2'>{item.type_name}</p>
                        <Tooltip title={item.interduce}>
                            <p className='rowFlex'>{item.interduce}</p>
                        </Tooltip>
                    </div>
                ))}
            </div>
        )
    }
    render() {
        return (
            <div className='gdt' style={{maxHeight: '70vh',overflow: 'auto'}}>
                {this.props.type != 'notice' && <React.Fragment>
                    <h2 className='pubTit' style={{ paddingTop: 0 }}>体检基本信息</h2>
                    <div className='flwp'>
                        <p className='item32'>体检日期：<span>{this.state.date || ''}</span></p>
                        <p className='item32'>体检人员：<span>{this.state.doctor_info || ''}</span></p>
                    </div>
                    <h2 className='pubTit'>项目列表</h2>
                </React.Fragment>}
                <TabbleList
                    ref={dom => this.tabblelist = dom}
                    size={this.state.size}
                    onRefresh={this.onRefresh.bind(this)}  //刷新
                    renderHead={this.renderHead.bind(this)}  //表头
                    renderItem={this.renderItem.bind(this)}  //表内容
                    className='projListbox'
                />
            </div>
        )
    }
}