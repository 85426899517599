import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import StudentForm from './StudentForm';  //检查表单详情
import CustomTable from '../../common/Table';

const { RangePicker } = DatePicker;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学生姓名',
                dataIndex: 'student_name',
                align: 'center',
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '班级名称',
                dataIndex: 'class_name',
                align: 'center',
            }, {
                title: '保育员',
                dataIndex: 'teacher_name',
                align: 'center',
            }, {
                title: '类型',
                dataIndex: 'type',
                align: 'center',
                render: (_,item) => (
                    <React.Fragment>
                        {item.type == 1 && <Button type='primary' size='small'>晨检</Button>}
                        {item.type == 2 && <Button type='primary' size='small' className='fu1'>午检</Button>}
                    </React.Fragment>
                )
            }, {
                title: '录入时间',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '检查状态',
                dataIndex: 'has_error',
                align: 'center',
                render: has_error => (
                    <span className={`${has_error == 1 ? 'color2' : 'color1'}`}>{has_error == 1 ? '有异常' : '无异常'}</span>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.changeVisible.bind(this, 'visible', item)}>检查表单</p>
                    </div>
                )
            }
        ]
    }
    getAllClass(id) {
        global.Ajax.hhtc_request('teacher/addGetClass', { school_id: id }).then(res => {
            if (res.code == 1) {
                let arryNew = []
                res.data.map(item => {
                    arryNew.push(Object.assign({}, { value: item.class_id, label: item.class_name }))
                })
                this.seltRef.setState({
                    data: arryNew
                })
            } else {
                this.seltRef.setState({
                    data: []
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || '',
            class_id: this.state.class_id || '',
            start_date: this.state.start_date || '',
            end_date: this.state.end_date || '',
        }
        global.Ajax.hhtc_request('dayinspect/studentDayInspctList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    changeVisible(key, data) {
        this.setState({
            [key]: true,
            editData: data,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入学生姓名'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(school_id) => {
                            this.getAllClass(school_id)
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        ref={dom => this.seltRef = dom}
                        placeholder='请选择班级'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(class_id) => {
                            this.setState({
                                class_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <RangePicker
                        className='borderbai marginr12'
                        onChange={(d, date) => {
                            this.setState({
                                start_date: date[0],
                                end_date: date[1]
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学生晨午检记录</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 检查表单 */}
                <CustomModal
                    visible={this.state.visible}
                    width={800}
                    onCancel={this.onCancel.bind(this)}
                >
                    <StudentForm data={this.state.editData} />
                    <Button onClick={this.onCancel.bind(this)} type='primary' style={{ display: 'block', margin: '30px auto 0' }}>确定</Button>
                </CustomModal>
            </React.Fragment>
        )
    }
    onCancel() {
        this.setState({
            visible: false,
            editData: {}
        })
    }
}