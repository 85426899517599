import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Ueditor from '../../common/Ueditor';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            articleType: [],
            healthTags: [],
            weatherTags: []
        }
    }
    componentDidMount() {
        if (this.props.type == 'edit') {
            let data = JSON.parse(JSON.stringify(this.props.data));
            data.tag_ids = (data.tag_ids&&data.tag_ids!='')?data.tag_ids.split(',').map(Number):undefined;
            data.weather_tag_ids = (data.weather_tag_ids&&data.weather_tag_ids!='')?data.weather_tag_ids.split(',').map(Number):undefined;
            data.type_ids = data.type_ids.split(',').map(Number);
            this.formRef.setFieldsValue(data);
            this.ueditor && this.ueditor.setContent(data.content);
        }
    }
    render() {
        const { articleType, weatherTags, healthTags } = this.state;
        return (
            <Form
                ref={dom => this.formRef = dom}
                onFinish={this.onFinish}
                labelCol={{ flex: '82px' }}
                initialValues={{
                    sort: 0,
                    display: 1,
                }}
            >
                <div className='flwp'>
                    <Form.Item
                        name='title'
                        label='文章标题'
                        rules={[{ required: true, message: '请输入文章标题！' }]}
                        className='item48'
                    >
                        <Input autoComplete='off' placeholder='请输入文章标题' />
                    </Form.Item>
                    <Form.Item
                        name='type_ids'
                        label='文章分类'
                        rules={[{ required: true, message: '请选择文章分类！' }]}
                        className='item48'
                    >
                        <SelectList
                            type='ArticleType'
                            placeholder='请选择'
                            mode='multiple'
                        />
                    </Form.Item>
                    <Form.Item
                        name='tag_ids'
                        label='健康标签'
                        // rules={[{ required: true, message: '请选择健康标签！' }]}
                        className='item48'
                    >
                        <SelectList
                            type='Tag'
                            placeholder='请选择'
                            mode='multiple'
                        />
                    </Form.Item>
                    <Form.Item
                        name='weather_tag_ids'
                        label='天气标签'
                        // rules={[{ required: true, message: '请选择天气标签！' }]}
                        className='item48'
                    >
                        <SelectList
                            type='WeatherTag'
                            placeholder='请选择'
                            mode='multiple'
                        />
                    </Form.Item>
                    <Form.Item
                        name='sort'
                        label='排序'
                        className='item48'
                    >
                        <Input autoComplete='off' placeholder='值越小越靠前' />
                    </Form.Item>
                    <Form.Item
                        name='display'
                        label='是否显示'
                        valuePropName='checked'
                        className='item48'
                    >
                        <Switch unCheckedChildren='隐藏' checkedChildren='显示' />
                    </Form.Item>
                    <Form.Item
                        name='is_top'
                        label='是否置顶'
                        valuePropName='checked'
                        className='item48'
                    >
                        <Switch unCheckedChildren='不置顶' checkedChildren='置顶' />
                    </Form.Item>
                    <Form.Item
                        name='content'
                        label='文章内容'
                        className='row10'
                        rules={[{ required: true, message: '请输入文章内容！' }]}
                    >
                        <Input className='noHeight' />
                        <Ueditor
                            ref={e => this.ueditor = e}
                            className="myarea"
                            style={{ height: 400, flex: 1, border: '1px solid #d9d9d9' }}
                            changeValue={(val) => {
                                this.formRef.setFieldsValue({
                                    content: val.toHTML(),
                                })
                            }}
                        />
                    </Form.Item>
                </div>
                <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
            </Form>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.is_top = req.is_top ? 1 : 0;
            req.display = req.display ? 1 : 0;
            req.type_ids = req.type_ids ? req.type_ids.join(',') : '';
            req.tag_ids = req.tag_ids ? req.tag_ids.join(',') : '';
            req.weather_tag_ids = req.weather_tag_ids ? req.weather_tag_ids.join(',') : '';
            let url = 'article/addArticle';
            if (this.props.type == 'edit') {
                url = 'article/editArticle';
                req.id = this.props.data.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.props.onOk && this.props.onOk();
                } else {
                    message.error(res.msg, 1.5)
                }
                this.setState({ loading: false })
            })
        })
    }
}