import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Select, DatePicker } from 'antd'
import SelectList from '../../common/SelectList';
import moment from 'moment'

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            doctorList: [],
            projList: [],
            mockData: [],
            targetKeys: [],
            allDoctorList: [],  // 所有体检人员
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.btnRef.click()
        }, 100);
        // 获取体检通知模板
        global.Ajax.hhtc_request('inspectnotice/getNotice', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    data: res.data,
                })
            }
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            let obj = {
                value: item.id,
                label: item.name
            }
            arryNew.push(Object.assign({}, obj))
        })
        return arryNew
    }
    render() {
        return (
            <Form
                ref={dom => this.formRef = dom}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
                labelCol={{ flex: '82px' }}
            >
                <div className='pubovao'>
                    <Form.Item label='体检通知' name='notice_id' rules={[{ required: true, message: '请选择体检通知！' }]}>
                        <Select placeholder='请选择体检通知'>
                            {this.state.data.map(item => (
                                <Option value={item.id} key={String(item.id)}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className='flwp'>
                        <Form.Item className='item48' label='学校' name='school_id' rules={[{ required: true, message: '请选择学校！' }]}>
                            <Select
                                options={this.state.schoolList || []}
                                placeholder='请选择学校'
                                fieldNames={{ label: 'name', value: 'id' }}
                            ></Select>
                        </Form.Item>
                        <Form.Item className='item48' label='体检日期' name='date' rules={[{ required: true, message: '请选择体检日期！' }]}>
                            <DatePicker style={{ width: '100%' }} disabledDate={(current) => {
                                return current && current < moment().startOf('day');
                            }} />
                        </Form.Item>
                    </div>
                    {this.state.mbList && this.state.mbList.length > 0 && <Form.Item label='历史记录' name='his'>
                        <Select placeholder='请选择历史记录模板' mode='multiple'>
                            {this.state.mbList.map(item => (
                                <Option value={item.id} key={String(item.id)}>{item.school_name}-{item.disc_names}</Option>
                            ))}
                        </Select>
                    </Form.Item>}
                    <Form.List name='users'>
                        {(fields, { add, remove }) => (
                            <React.Fragment>
                                {fields.map(({ key, name, ...restField }) => {
                                    let list = this.state.doctorList[name] || [];
                                    return (
                                        <React.Fragment key={String(key)}>
                                            <div className='flex'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'doctor_id']}
                                                    label='体检人员'
                                                    rules={[{ required: true, message: '请选择体检人员！' }]}
                                                    className='item48'
                                                >
                                                    <Select
                                                        placeholder='请选择体检人员'
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children == input || option.children.indexOf(input) != -1
                                                        }
                                                    >
                                                        {list.map((item, index) => (
                                                            <Option key={String(index)} value={item.value}>{item.label}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                {fields.length<this.state.allDoctorList.length&&<span style={{ fontSize: 20 }} className='iconfont icon-tianjia2' onClick={() => add()}></span>}
                                                {key > 0 && <span style={{ fontSize: 20, marginLeft: 12 }} className='iconfont icon-jianshao' onClick={() => {
                                                    // 删除当前行
                                                    remove(name)
                                                    // 如果选了值，删除值
                                                    let targetKeys = this.state.targetKeys;
                                                    this.handleChange(name, [], 'left', targetKeys[name])
                                                }}></span>}
                                            </div>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'inspect_items_ids']}
                                                label='体检项目'
                                                rules={[{ required: true, message: '请选择体检项目！' }]}
                                            >
                                                <Transfer
                                                    dataSource={this.state.projList[name]}
                                                    // dataSource={this.state.mockData}
                                                    showSearch
                                                    targetKeys={this.state.targetKeys[name]}
                                                    render={item => item.title}
                                                    operations={['添加', '删除']}
                                                    onChange={this.handleChange.bind(this, name)}
                                                    selectAllLabels={[({ selectedCount, totalCount }) => (
                                                        <span>可选项目({totalCount}个)</span>
                                                    ), ({ selectedCount, totalCount }) => (
                                                        <span>已选项目({this.state.targetKeys[name] && this.state.targetKeys[name].length || 0}个)</span>
                                                    )]}
                                                />
                                            </Form.Item>
                                        </React.Fragment>
                                    )
                                })}
                                {fields.length == 0 && <span style={{ fontSize: 0, opacity: 0, }} ref={dom => this.btnRef = dom} className='iconfont icon-tianjia2' onClick={() => add()}></span>}
                            </React.Fragment>
                        )}
                    </Form.List>
                </div>
                <Button type='primary' loading={this.state.loading} htmlType='submit' style={{ display: 'block', marginLeft: 'auto' }}>确定</Button>
            </Form>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.dist_doc = [];
            for (let i in req.users) {
                // let doctor_name = (this.state.userData.find(item => item.value == req.users[i].doctor_id)).label;
                let obj = {
                    doctor_id: req.users[i].doctor_id,
                    inspect_items_ids: req.users[i].inspect_items_ids.join(','),
                }
                req.dist_doc.push(obj)
            }
            req.date = req.date ? req.date.format('YYYY-MM-DD') : '';
            delete req.his;
            delete req.users;
            console.log(req)
            global.Ajax.hhtc_request('inspectnotice/addPlan', req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.props.onOk()
                } else {
                    message.error(res.msg, 1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    handleChange(index, keys, direction, moveKeys) {
        let projList = JSON.parse(JSON.stringify(this.state.projList)) || [];
        let data = JSON.parse(JSON.stringify(this.state.mockData));
        let targetKeys = JSON.parse(JSON.stringify(this.state.targetKeys));
        let allKeys = this.state.targetKeys;
        let newData = [];
        let newList = [];
        allKeys[index] = keys;
        // 先拿到所有未选的项
        for (let i in allKeys) {
            for (let j in allKeys[i]) {
                let m = data.findIndex(item => item.key == allKeys[i][j]);
                data.splice(m, 1)
            }
        }
        // 再获取每个体检人员已选的项
        for (let i in allKeys) {
            newList[i] = [];
            for (let j in allKeys[i]) {
                let row = this.state.mockData.find(item => item.key == allKeys[i][j]);
                if (row) {
                    newList[i].push(row)
                }
            }
            // 合并已选择的项和所有未选的项
            let arrnew = newList[i].concat(data);
            newList[i] = arrnew;
        }
        newData = JSON.parse(JSON.stringify(newList[index]));
        for (let i in keys) {
            let m = newData.findIndex(item => item.key == keys[i]);
            newData.splice(m, 1)
        }
        let ii = index * 1 + 1;
        if (!newList[ii]) {
            newList[ii] = newData;
        }
        this.setState({
            targetKeys: allKeys,
            projList: newList,
        });
    }
    onValuesChange = (value, values) => {
        let key = Object.keys(value)[0];
        console.log(key)
        if (key == 'notice_id') {
            this.getMuban(value.notice_id)
        } else if (key == 'his') {  //选择历史记录
            let mbList = JSON.parse(JSON.stringify(this.state.mbList));
            let targetKeys = JSON.parse(JSON.stringify(this.state.targetKeys));
            let arr = value.his;
            let users = values.users[0] ? values.users : [];
            for (let j in arr) {
                let row = mbList.find(item => item.id == arr[j]);
                let datas = row.dist_info;
                for (let i in datas) {
                    let obj = {
                        doctor_id: datas[i].doctor_id,
                        inspect_items_ids: datas[i].inspect_items_ids.split(',').map(Number)
                    }
                    let index = users.findIndex(item => item.doctor_id == datas[i].doctor_id);
                    if (index > -1) {
                        users.splice(index, 1);
                    }
                    users.push(obj)
                    targetKeys[users.length - 1] = datas[i].inspect_items_ids.split(',').map(Number);
                }
            }
            console.log(users)
            console.log(targetKeys)
            this.setState({
                targetKeys
            })
            this.formRef.setFieldsValue({
                users: users,
            })
        } else if (key == 'users') {
            let doctorList = JSON.parse(JSON.stringify(this.state.doctorList)) || [];
            let data = [];
            let list = value.users;
            for (let i in list) {
                if (list[i]) {
                    let keyy = Object.keys(list[i])[0];
                    let m = i * 1 + 1;
                    if (keyy == 'doctor_id') {  //体检人员
                        data = JSON.parse(JSON.stringify(doctorList[i]));
                        let index = data.findIndex(item => item.value == list[i].doctor_id);
                        data.splice(index, 1);
                        doctorList[m] = data
                    }
                }
            }
            if (doctorList.length > 0) {
                this.setState({
                    doctorList
                })
            }
        }
    }
    getMuban(id) {
        // 获取学校
        global.Ajax.hhtc_request('inspectnotice/addPlanGetSchool', { notice_id: id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    schoolList: res.data
                })
            }
        })

        global.Ajax.hhtc_request('inspectnotice/getDistDoc', { notice_id: id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    mbList: res.data,
                })
            }
        })
        // 获取体检项目列表
        global.Ajax.hhtc_request('inspectnotice/addGetNoticeInspect', { notice_id: id, }).then(res => {
            if (res.code == 1) {
                let arry = res.data;
                let arryNew = []
                arry.map(item => {
                    arryNew.push(Object.assign({}, { key: item.id, title: item.name }))
                })
                let projList = [];
                projList[0] = arryNew;
                this.setState({
                    mockData: arryNew,
                    projList
                })
            }
        })
        // 获取体检人员
        global.Ajax.hhtc_request('inspectnotice/getDoctor', {}).then(res => {
            if (res.code == 1) {
                let arry = res.data;
                let arryNew = []
                arry.map(item => {
                    arryNew.push(Object.assign({}, { value: item.admin_id, label: item.person }))
                })
                let doctorList = [];
                doctorList[0] = arryNew;
                this.setState({
                    userData: arryNew,
                    doctorList,
                    allDoctorList: res.data,
                })
            }
        })
    }
}