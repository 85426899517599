import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import Tjxm from './Tjxm';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
            checkTypeList: [],
            status: '',
            editData: {},
            ids: [],
            inspectItemList: [],
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '体检名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '体检说明',
                dataIndex: 'intriduce',
                align: 'center',
            }, {
                title: '体检项目数',
                dataIndex: 'inspect_count',
                align: 'center',
            }, {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.status == 0 && <span className='color1'>待发布</span>}
                        {item.status == 1 && <span className='color2'>已发布</span>}
                    </React.Fragment>
                )
            }, {
                title: '完成进度',
                dataIndex: 'finish_per',
                align: 'center',
                render: finish_per => `${finish_per || 0}%`
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        {item.status == 0 && <React.Fragment>
                            <p onClick={this.del.bind(this, item, 'sub')}>发布</p>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </React.Fragment>}
                        {item.status != 0 && <p onClick={this.chahgeVisible.bind(this, 'projVisible', item, 'project')}>体检项目</p>}
                    </div>
                )
            }
        ]
    }
    getType() {
        global.Ajax.hhtc_request('inspect/addInspectGetType', {}).then(res => {
            if (res.code == 1) {
                // let arryNew = []
                // res.data.map(item => {
                //     arryNew.push(Object.assign({}, { value: item.id, label: item.name }))
                // })
                this.setState({
                    checkTypeList: res.data
                })
                // this.seltRef && this.seltRef.setState({
                //     data: arryNew
                // })
            }
        })
    }
    refresh() {
        this.getType()
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            status: this.state.status
        }
        global.Ajax.hhtc_request('inspectnotice/noticeList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='rowFlex'>体检名称</p>
                <p className='rowFlex'>体检说明</p>
                <p className='row1'>体检项目数</p>
                <p className='row1'>状态</p>
                <p className='row1'>完成进度</p>
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <Tooltip title={item.name}>
                            <p className='rowFlex'>{item.name}</p>
                        </Tooltip>
                        <Tooltip title={item.intriduce}>
                            <p className='rowFlex'>{item.intriduce}</p>
                        </Tooltip>
                        <p className='row1'>{item.inspect_count || 0}</p>
                        <p className='row1'>
                            {item.status == 0 && <span className='color1'>待发布</span>}
                            {item.status == 1 && <span className='color2'>已发布</span>}
                        </p>
                        <p className='row1'>{item.finish_per || 0}%</p>
                        <div className='row15 flexAllCenter pubbtnbox'>
                            {item.status == 0 && <React.Fragment>
                                <p onClick={this.del.bind(this, item, 'sub')}>发布</p>
                                <p onClick={this.edit.bind(this, item)}>编辑</p>
                                <p onClick={this.del.bind(this, item)}>删除</p>
                            </React.Fragment>}
                            {item.status != 0 && <p onClick={this.chahgeVisible.bind(this, 'projVisible', item, 'project')}>体检项目</p>}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    // 编辑
    edit(data) {
        // 获取检查类型
        this.getType()
        // 
        let arr = JSON.parse(JSON.stringify(data.item_ids));
        arr = arr.split(',').map(Number);
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
            ids: arr,
        })
        console.log(arr);
        data.inspect_items = arr;
        setTimeout(() => {
            this.formRef.setFieldsValue(data)
        }, 100);
    }
    // 删除
    del(data, type) {
        let url = 'inspectnotice/delNotice', req = { id: data.id };
        if (type == 'sub') {
            url = 'inspectnotice/publishNotice';
            req = { notice_id: data.id }
            return this.delmask.del(url, req, '发布后将无法撤销，请谨慎操作！')
        }
        this.delmask.del(url, req)
    }
    chahgeVisible(key, data, type) {
        this.setState({
            [key]: true,
            editData: data
        })
        if (type == 'project') {
            // global.Ajax.hhtc_request('inspectnotice/addGetNoticeInspect',{notice_id: data.id}).then(res=>{})
        }
        if (key == 'addVisible') {
            // 获取检查类型
            this.getType()
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入体检名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        data={[
                            { value: 0, label: '待发布' },
                            { value: 1, label: '已发布' },
                        ]}
                        placeholder='请选择发布状态'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(status) => {
                            status = status == undefined ? '' : status;
                            this.setState({
                                status,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加通知</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>体检通知列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={720}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}体检通知`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='name'
                                label='体检名称'
                                rules={[{ required: true, message: '请输入体检名称！' }]}
                                // className={this.state.type == 'edit' ? 'item48' : ''}
                                // style={{ width: this.state.type == 'edit' ? '' : '100%' }}
                                style={{ width: '100%' }}
                            >
                                <Input autoComplete='off' placeholder='请输入体检名称' />
                            </Form.Item>
                            {/* {this.state.type == 'edit' && <Form.Item
                                name='status'
                                label='状态'
                                rules={[{ required: true, message: '请选择发布状态！' }]}
                                className='item48'
                            >
                                <SelectList
                                    data={[
                                        { value: 0, label: '待发布' },
                                        { value: 1, label: '已发布' },
                                    ]}
                                    placeholder='请选择发布状态'
                                />
                            </Form.Item>} */}
                            <Form.Item
                                name='intriduce'
                                label='体检说明'
                                rules={[{ required: true, message: '请输入体检说明！' }]}
                                style={{ width: '100%' }}
                            >
                                <Input.TextArea rows={6} className='txtarea' autoComplete='off' placeholder='请输入体检说明' />
                            </Form.Item>
                            <div style={{ width: '100%' }}>
                                <div className='flexCenter'>
                                    <p style={{ color: '#666', width: 82, textAlign: 'right' }}><span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: 4, }}>*</span>体检项目：</p>
                                    <CustomInput
                                        placeholder='请输入项目名称'
                                        onChange={(e) => {
                                            this.setState({
                                                projName: e.target.value,
                                            }, () => {
                                                this.projlist.onRefresh()
                                            })
                                        }}
                                    />
                                    <Select
                                        options={this.state.checkTypeList}
                                        fieldNames={{label: 'name',value: 'id'}}
                                        placeholder='请选择检查类型'
                                        className='marginr12'
                                        style={{ width: 160 }}
                                        onChange={(type_id) => {
                                            this.setState({
                                                type_id
                                            }, () => {
                                                this.projlist.onRefresh()
                                            })
                                        }}
                                    ></Select>
                                    {/* <SelectList
                                        data={this.state.checkTypeList}
                                        placeholder='请选择检查类型'
                                        className='marginr12'
                                        style={{ width: 160 }}
                                        onChange={(type_id) => {
                                            this.setState({
                                                type_id
                                            }, () => {
                                                this.projlist.onRefresh()
                                            })
                                        }}
                                    /> */}
                                </div>
                                <TabbleList
                                    ref={dom => this.projlist = dom}
                                    size={this.state.size}
                                    onRefresh={this.projRefresh.bind(this)}  //刷新
                                    renderHead={this.projRenderHead.bind(this)}  //表头
                                    renderItem={this.projRenderItem.bind(this)}  //表内容
                                    className='projListbox1'
                                />
                            </div>
                            <Form.Item
                                name='inspect_items'
                                className='noHeight'
                                rules={[{ required: true, message: '请选择体检项目！' }]}
                                style={{ marginLeft: 82 }}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                                name='opinion'
                                label='意见配置'
                                style={{ width: '100%' }}
                                rules={[{ required: true, message: '请输入指导意见配置项！' }]}
                            >
                                <Input autoComplete='off' placeholder='请输入指导意见配置选项，用空格隔开（如：合理膳食 疾病预防）' />
                            </Form.Item> */}
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto',marginTop: 20 }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 体检项目列表 */}
                <CustomModal
                    visible={this.state.projVisible}
                    title={`${this.state.editData.name}项目列表`}
                    width={900}
                    onCancel={this.onCancel.bind(this)}
                    className='infoboxx addprojbox'
                >
                    <Tjxm
                        type='notice'
                        id={this.state.editData.id}
                    />
                    <Button onClick={this.onCancel.bind(this)} type='primary' style={{ display: 'block', margin: '30px 0 0 auto' }}>确定</Button>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'inspectnotice/addNotice';
            if (this.state.type == 'edit') {
                url = 'inspectnotice/editNotice';
                req.id = this.state.editData.id;
            } else {
                req.status = 0;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
            allChecked: false,
            indeterminate: false,
            projVisible: false,
            ids: [],
            type_id: ''
        })
    }
    getProj(page, callback) {
        var req = {
            page: page,
            size: 100,
            orderBy: '',
            pword: this.state.projName || '',
            type_id: this.state.type_id || '',
            add_type: '',
            status: 1
        }
        global.Ajax.hhtc_request('inspect/inspectItemList', req).then(res => {
            res.data.all = 0;
            callback(res)
            if (res.code == 1) {
                this.setState({
                    inspectItemList: res.data.datas
                })
                if (this.state.type == 'edit') {
                    console.log(this.state.editData.item_ids.split(','))
                    let arr1 = this.state.editData.item_ids.split(',');
                    let row = this.projlist.state.data;
                    for (let i in arr1) {
                        let index = row.findIndex(item => item.id == arr1[i]);
                        if (index > -1) {
                            row[index].selected = true;
                        }
                    }
                    let allChecked = this.state.editData.item_ids.split(',').length == row.length;
                    let indeterminate = allChecked ? false : true;
                    this.setState({
                        allChecked,
                        indeterminate
                    })
                    this.projlist.setState({ row })
                }
            }
        })
    }
    projRefresh(page, callback) {
        this.getProj(page, callback)
    }
    projRenderHead() {
        console.log(this.isIndeterminate());
        return (
            <div className='head flex'>
                <Checkbox onChange={this.changeAll.bind(this)} indeterminate={this.isIndeterminate()} checked={this.isAllCheck()} className='marginl8'></Checkbox>
                <p className='row2'>项目名称</p>
                <p className='row25'>检查类型</p>
                <p className='rowFlex'>项目简介</p>
            </div>
        )
    }
    isIndeterminate = () => {
        let data = this.state.inspectItemList,
            ids = this.state.ids;
        if (this.state.ids.length == 0) {
            return false;
        }
        let num = 0;
        for (let i in data) {
            let index = ids.findIndex(item => item == data[i].id);
            if (index != -1) {
                num++;
            }
        }
        if (this.state.ids.length > 0) {
            if (num == data.length) {
                return false;
            } else {
                if (num > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    isAllCheck = () => {
        let data = this.state.inspectItemList,
            ids = this.state.ids;
        if (this.state.ids.length == 0) {
            return false;
        }
        let num = 0;
        for (let i in data) {
            let index = ids.findIndex(item => item == data[i].id);
            if (index != -1) {
                num++;
            }
        }
        if (this.state.ids.length > 0) {
            if (num == data.length && num > 0) {
                return true;
            }
        }
        return false;
    }
    isCheck = (id) => {
        return this.state.ids.indexOf(id) != -1;
    }
    projRenderItem(data) {
        return (
            <div className='boxx'>
                {data.map((item, index) => (
                    <div className='flex' key={String(index)}>
                        <Checkbox onChange={this.change.bind(this, index)} checked={this.isCheck(item.id)} className='marginl8'></Checkbox>
                        <p className='row2'>{item.name}</p>
                        <p className='row25'>{item.type_name}</p>
                        <Tooltip title={item.interduce}>
                            <p className='rowFlex'>{item.interduce}</p>
                        </Tooltip>
                    </div>
                ))}
            </div>
        )
    }
    // 全选
    changeAll() {
        let data = JSON.parse(JSON.stringify(this.state.inspectItemList)),
            ids = [];
        if (this.isAllCheck()) {

        } else {
            for (let i in data) {
                ids.push(data[i].id)
            }
        }
        this.setState({
            ids,
        })
        this.formRef.setFieldsValue({
            inspect_items: ids.join(',')
        })
        return
        // let data = this.projlist.state.data;
        let allChecked = this.state.allChecked;
        for (let i in data) {
            if (allChecked) {
                data[i].selected = false;
            } else {
                data[i].selected = true;
            }
        }
        // this.setState({
        //     allChecked: !allChecked,
        //     indeterminate: false,
        // })
        // this.projlist.setState({
        //     data
        // })
        this.getAllIds(data)
    }
    // 单选
    change(index) {
        let ids = this.state.ids;
        let data = JSON.parse(JSON.stringify(this.state.inspectItemList));
        let i = ids.findIndex(item => item == data[index].id);
        if (i > -1) {
            ids.splice(i, 1)
        } else {
            ids.push(data[index].id)
        }
        this.setState({
            ids,
        })
        this.formRef.setFieldsValue({
            inspect_items: ids.join(',')
        })
        return
        // let data = this.projlist.state.data;
        data[index].selected = !data[index].selected;
        // let allChecked = data.every(item => item.selected);
        // let indeterminate = data.some(item => item.selected);
        // indeterminate = allChecked ? false : indeterminate;
        // this.setState({
        //     indeterminate,
        //     allChecked
        // })
        // this.projlist.setState({
        //     data
        // })
        this.setState({
            inspectItemList: data
        })
        this.getAllIds(data)
    }
    getAllIds(data) {
        console.log(data);
        let ids = this.state.ids;
        for (let i in data) {
            let index = ids.findIndex(item => item == data[i].id);
            if (data[i].selected) {
                if (index == -1) {
                    ids.push(data[i].id)
                }
            } else {
                if (index > -1) {
                    ids.splice(index, 1)
                }
            }
        }
        this.setState({
            ids,
        })
        this.formRef.setFieldsValue({
            inspect_items: ids.join(',')
        })
    }
}