import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Ueditor from '../../common/Ueditor';

const { Option } = Select;
const typeList = [
    { label: '温差', value: 1 },
    { label: '天气', value: 2 },
    { label: '空气质量', value: 3 },
];
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            weatherType: [],
            airLevel: [],
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '标签类型',
                dataIndex: 'type',
                align: 'center',
                render: index => typeList[index - 1].label,
            }, {
                title: '标签名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '备注',
                dataIndex: 'remark',
                align: 'center',
                render: (_,item)=>(
                    <React.Fragment>
                        {item.type==1?`温差值：${item.type_tag}`:item.type_tag}
                    </React.Fragment>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: 150,
                render: (_, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    componentDidMount() {
        // 获取天气类型
        global.Ajax.hhtc_request('tag/getWeatherType', {}).then(res => {
            if (res.code == 1) {
                let weatherType=[]
                res.data.map((item) => {
                    weatherType.push(Object.assign({}, {
                        value: item,
                        label: item,
                    }))
                })
                this.setState({
                    weatherType
                })
            }
        })
        // 获取空气质量等级
        global.Ajax.hhtc_request('tag/getAirLevel', {}).then(res => {
            if (res.code == 1) {
                let airLevel=[]
                res.data.map((item) => {
                    airLevel.push(Object.assign({}, {
                        value: item,
                        label: item,
                    }))
                })
                this.setState({
                    airLevel
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            type: this.state.h_type || 0
        }
        global.Ajax.hhtc_request('tag/getWeatherTagList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        }, () => {
            let rows = JSON.parse(JSON.stringify(data));
            setTimeout(() => {
                this.formRef.setFieldsValue(rows);
                this.ueditor && this.ueditor.setContent(rows.introduce);
            }, 100);
        })
    }
    // 删除
    del(data) {
        var url = 'tag/delWeatherTag', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        const { weatherType, airLevel } = this.state;
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <SelectList
                        data={typeList}
                        placeholder='请选择类型'
                        onChange={(h_type) => {
                            this.setState({
                                h_type
                            }, () => {
                                this.refresh()
                            })
                        }}
                        className='borderbai marginr12'
                        style={{ width: 160 }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加标签</Button>
                </div>
                <div className='bgbai flexColumn'>
                    <h2 className='pubTit'>列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={540}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}标签`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                        onValuesChange={(res)=>{
                            let key = Object.keys(res)[0];
                            if (key == 'type') {
                                this.formRef.setFieldsValue({
                                    type_tag: undefined,
                                })
                            }
                        }}
                    >
                        <Form.Item
                            name='type'
                            label='标签类型'
                            rules={[{ required: true, message: '请选择标签类型！' }]}
                        >
                            <SelectList
                                data={typeList}
                                placeholder='请选择类型'
                            />
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label='标签名称'
                            rules={[{ required: true, message: '请输入标签名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入标签名称' />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.type != cur.type}>
                            {({ getFieldValue }) => {
                                let list=[];
                                if(getFieldValue('type') == 2){
                                    list=weatherType;
                                }else if(getFieldValue('type') == 3){
                                    list=airLevel;
                                }
                                return (
                                    getFieldValue('type')&&<Form.Item
                                        name='type_tag'
                                        label={getFieldValue('type') == 1 ? '温差值' : (
                                            getFieldValue('type') == 2 ? '天气类型' : '空气质量'
                                        )}
                                        rules={[{ required: true, message: '请输入最低sd！' }]}
                                    >
                                        {getFieldValue('type') == 1 ?
                                            <Input autoComplete='off' placeholder='请输入' />
                                            :
                                            <Select
                                                placeholder='请选择'
                                                options={list}
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            >
                                                {/* {list.map((item,index)=>(
                                                    <Option value={item} key={String(index)}>{item}</Option>
                                                ))} */}
                                            </Select>
                                        }
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            let url = 'tag/addWeatherTag';
            if (this.state.type == 'edit') {
                url = 'tag/editWeatherTag';
                req.id = this.state.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: ''
        })
    }
}