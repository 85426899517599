import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch, Modal } from 'antd';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'sort',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '分类名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '是否显示',
                dataIndex: 'display',
                align: 'center',
                render: display => (
                    <Switch disabled unCheckedChildren='隐藏' checkedChildren='显示' checked={display == 1 ? true : false} />
                )
            }, {
                title: '添加时间',
                dataIndex: 'atime',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: 200,
                render: (_, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        let req = {
            page: info.page,
            size: info.size,
            orderBy: '',
        }
        global.Ajax.hhtc_request('article/articleTypeList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        }, () => {
            let rows = JSON.parse(JSON.stringify(data));
            setTimeout(() => {
                this.formRef.setFieldsValue(rows);
            }, 100);
        })
    }
    // 删除
    del(data) {
        var url = 'article/delArticleType', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加分类</Button>
                </div>
                <div className='bgbai flexColumn'>
                    <h2 className='pubTit'>文章分类</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={540}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}标签`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                        initialValues={{
                            display: 1,
                            sort: 0,
                        }}
                    >
                        <Form.Item
                            name='name'
                            label='分类名称'
                            rules={[{ required: true, message: '请输入分类名称！' }]}
                        >
                            <Input autoComplete='off' placeholder='请输入分类名称' />
                        </Form.Item>
                        <Form.Item
                            name='display'
                            label='是否展示'
                            valuePropName='checked'
                        >
                            <Switch unCheckedChildren='隐藏' checkedChildren='显示' />
                        </Form.Item>
                        <Form.Item
                            name='sort'
                            label='排序'
                        >
                            <Input autoComplete='off' placeholder='值越小越靠前' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            let url = 'article/addArticleType';
            if (this.state.type == 'edit') {
                url = 'article/editArticleType';
                req.id = this.state.editData.id;
            }
            req.display = req.display ? 1 : 0;
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            editData: {},
            type: ''
        })
    }
}