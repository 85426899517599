import React from 'react';
import { Button, Input, message, DatePicker, Select } from 'antd'
import * as echarts from 'echarts';
import moment from 'moment';

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {},
            rcjc_date: moment(new Date()).format('YYYY-MM'),
            cj_date: moment(new Date()).format('YYYY-MM'),
            wj_date: moment(new Date()).format('YYYY-MM'),
        }
        console.log(moment(new Date()).format('YYYY-MM'));
    }
    componentDidMount() {
        // console.log(this.setJumpNumber(0,78,1,'#num1'))
        this.refresh()
    }
    refresh() {
        this.getInfo()
        this.getOptions()
    }
    getInfo() {
        global.Ajax.hhtc_request('data/getBaseData', {}).then(res => {
            if (res.code == 1) {
                this.getSg(res.data.sgs)
                this.getTz(res.data.tzs)
                this.setState({
                    info: res.data,
                })
            }
        })
    }
    getSg(data) {
        // 身高分布情况
        let chartDom = document.getElementById('box1');
        let myChart = echarts.init(chartDom);
        myChart.setOption({
            title: {
                text: '学生身高分布情况',
                left: 'center',
                top: 'bottom'
            },
            legend: {
                top: '0',
                left: 'center',
                icon: "circle",
                itemGap: 5,
                itemWidth: 10,
                itemHeight: 10,
            },
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    radius: '60%',
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        });
    }
    getTz(data) {
        // 体重分布情况
        let chartDom5 = document.getElementById('box5');
        let myChart5 = echarts.init(chartDom5);
        myChart5.setOption({
            title: {
                text: '学生体重分布情况',
                left: 'center',
                top: 'bottom'
            },
            legend: {
                top: '0',
                left: 'center',
                icon: "circle",
                itemGap: 5,
                itemWidth: 10,
                itemHeight: 10,
            },
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    type: 'pie',
                    radius: '60%',
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        });
    }
    getRcjc() {
        // 日常检查情况
        global.Ajax.hhtc_request('data/getRCJC', { month: this.state.rcjc_date }).then(res => {
            if (res.code == 1) {
                let chartDom2 = document.getElementById('box2');
                let myChart2 = echarts.init(chartDom2);
                myChart2.setOption(this.getOption(res.data.titles, res.data.values1, res.data.values2))
            }
        })
    }
    // 获取晨午检情况
    getCwj(type, date) {
        global.Ajax.hhtc_request('data/getCWJInfo', { type, date }).then(res => {
            if (res.code == 1) {
                if (type == 1) {  // 晨检
                    let chartDom4 = document.getElementById('box4');
                    let myChart4 = echarts.init(chartDom4);
                    myChart4.setOption(this.getOption(res.data.titles, res.data.values1, res.data.values2))
                } else if (type == 2) {  // 午检
                    let chartDom3 = document.getElementById('box3');
                    let myChart3 = echarts.init(chartDom3);
                    myChart3.setOption(this.getOption(res.data.titles, res.data.values1, res.data.values2))
                }
            }
        })
    }
    getOptions() {
        // -----------------------------------------------
        // 日常检查情况
        this.getRcjc()
        // 午检检查情况
        this.getCwj(2, this.state.wj_date)
        // 晨检检查情况
        this.getCwj(1, this.state.cj_date)
    }
    getOption(xData, zcValue, ycValue) {
        let option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                icon: 'circle',
                data: ['正常', '异常'],
                top: '-1%'
            },
            grid: {
                top: '8%',
                left: '7%',
                right: '6%',
                bottom: '12%'
            },
            xAxis: [{
                type: 'category',
                data: xData,
                axisLine: {
                    lineStyle: {
                        color: '#DBDBDB'
                    }
                },
                axisLabel: {
                    textStyle: {
                        color: '#999'
                    }
                },
                axisTick: {
                    show: false,
                }
            }],
            yAxis: [{
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#666'
                    }
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: '#eee'
                    }
                },
            }],
            series: [
                {
                    name: '正常',
                    type: 'bar',
                    barWidth: 16,
                    barGap: 0,
                    data: zcValue,
                    itemStyle: {
                        normal: {
                            color: '#0081FF'
                        }
                    }
                },
                {
                    name: '异常',
                    type: 'bar',
                    barWidth: 16,
                    data: ycValue,
                    itemStyle: {
                        normal: {
                            color: '#FE6737'
                        }
                    }
                }
            ]
        }
        return option;
    }
    setJumpNumber(startNum, targetNum, time = 1, selector) {
        let dom = document.getElementById(selector);
        let originNum = startNum;
        let stepNum = 0;
        let timeNum = time;
        // dom.innerHTML = startNum;
        // this.getNum(startNum)
        let timeId = setInterval(() => {
            if (originNum < targetNum) {
                timeNum -= 0.001;
                let strNum = originNum.toString();
                // 数字比较少的时候直接用 + 1; 数字很大直接 +1 要很久才能调到最对应的数字，所有后三位数随机跳动的方式进行模拟生成
                if (targetNum.toString().length < 6) {
                    stepNum += 1; // 这样才可以实现越跳越快的效果
                    originNum = originNum + stepNum;
                    // dom.innerHTML = originNum;
                    this.getNum(originNum)
                } else {
                    stepNum += 500; // 这样才可以实现越跳越快的效果
                    originNum = originNum + stepNum;
                    // dom.innerHTML = strNum.substr(0, strNum.length - 3) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10);
                    let num = strNum.substr(0, strNum.length - 3) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10);
                    this.getNum(num)
                }
            } else {
                // dom.innerHTML = targetNum;
                this.getNum(targetNum)
                clearInterval(timeId);
            }
        }, timeNum);
    }
    getNum(num) {
        return num;
    }
    render() {
        const { info, rcjc_date } = this.state;
        return (
            <div className='datasBox'>
                <div className='flex'>
                    <div className='row12 marginr12'>
                        <h2 className='pubTit'>学校数量（所）</h2>
                        <h1 className='num' id='num1'>{info.school_num || 0}</h1>
                        <p className='p1'>总计</p>
                    </div>
                    <div className='row15 marginr12'>
                        <h2 className='pubTit'>班级数量（个）</h2>
                        <h1 className='num'>{info.class_num || 0}</h1>
                        <p className='p1'>总计</p>
                    </div>
                    <div className='rowFlex marginr12'>
                        <h2 className='pubTit'>学生数量（人）</h2>
                        <div className='flex'>
                            <div className='rowFlex'>
                                <h1 className='num'>{info.student_num || 0}</h1>
                                <p className='p1'>总计</p>
                            </div>
                            <div className='rowFlex'>
                                <h1 className='num color1'>{info.daoxiao || 0}</h1>
                                <p className='p1'>到校</p>
                            </div>
                            <div className='rowFlex'>
                                <h1 className='num color3'>{info.leave_num || 0}</h1>
                                <p className='p1'>请假</p>
                            </div>
                        </div>
                    </div>
                    <div className='row15 marginr12'>
                        <h2 className='pubTit'>保育员数量（人）</h2>
                        <h1 className='num'>{info.teacher_num || 0}</h1>
                        <p className='p1'>总计</p>
                    </div>
                    <div className='rowFlex'>
                        <h2 className='pubTit'>晨午检情况（人）</h2>
                        <div className='flex'>
                            <div className='rowFlex'>
                                <h1 className='num'>{info.cj || 0}</h1>
                                <p className='p1'>晨检</p>
                            </div>
                            <div className='rowFlex'>
                                <h1 className='num color1'>{info.wj || 0}</h1>
                                <p className='p1'>午检</p>
                            </div>
                            {/* <div className='rowFlex'>
                                <h1 className='num color2'>78</h1>
                                <p className='p1'>请假</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='ebox flwp'>
                    <div className='item'>
                        <div className='flexCenter' style={{ paddingRight: 16 }}>
                            <h2 className='pubTit'>学生情况（人）</h2>
                            {/* <DatePicker picker='year' style={{ height: 32, width: 70, marginRight: 12, marginLeft: 'auto' }} placeholder='年' /> */}
                            {/* <DatePicker picker='month' style={{ height: 32, width: 70, marginRight: 12,marginLeft: 'auto' }} placeholder='月' />
                            <Select placeholder='范围'>
                                <Option value='本周'>本周</Option>
                            </Select> */}
                        </div>
                        <div className='box flex'>
                            <div style={{ flex: 1 }} id='box1'></div>
                            <div style={{ flex: 1 }} id='box5'></div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='flexCenter' style={{ paddingRight: 16 }}>
                            <h2 className='pubTit'>日常检查情况（次）</h2>
                            {/* <DatePicker picker='year' style={{ height: 32, width: 70, marginRight: 12, marginLeft: 'auto' }} placeholder='年' /> */}
                            <DatePicker value={moment(this.state.rcjc_date, 'YYYY-MM')} onChange={(_, rcjc_date) => {
                                console.log(rcjc_date);
                                this.setState({
                                    rcjc_date
                                }, () => {
                                    this.getRcjc()
                                })
                            }} allowClear={false} picker='month' style={{ height: 32, width: 100, marginRight: 12, marginLeft: 'auto' }} placeholder='月' />
                            {/* <Select placeholder='范围'>
                                <Option value='本周'>本周</Option>
                            </Select> */}
                        </div>
                        <div className='box' id='box2'></div>
                    </div>
                    <div className='item'>
                        <div className='flexCenter' style={{ paddingRight: 16 }}>
                            <h2 className='pubTit'>午检检查情况（人）</h2>
                            {/* <DatePicker picker='year' style={{ height: 32, width: 70, marginRight: 12, marginLeft: 'auto' }} placeholder='年' /> */}
                            <DatePicker value={moment(this.state.wj_date, 'YYYY-MM')} onChange={(_, wj_date) => {
                                this.setState({
                                    wj_date
                                }, () => {
                                    this.getCwj(2,wj_date)
                                })
                            }} allowClear={false} picker='month' style={{ height: 32, width: 100, marginRight: 12, marginLeft: 'auto' }} placeholder='月' />
                            {/* <Select placeholder='范围'>
                                <Option value='本周'>本周</Option>
                            </Select> */}
                        </div>
                        <div className='box' id='box3'></div>
                    </div>
                    <div className='item'>
                        <div className='flexCenter' style={{ paddingRight: 16 }}>
                            <h2 className='pubTit'>晨检检查情况（人）</h2>
                            {/* <DatePicker picker='year' style={{ height: 32, width: 70, marginRight: 12, marginLeft: 'auto' }} placeholder='年' /> */}
                            <DatePicker value={moment(this.state.cj_date, 'YYYY-MM')} onChange={(_, cj_date) => {
                                this.setState({
                                    cj_date
                                }, () => {
                                    this.getCwj(1,cj_date)
                                })
                            }} allowClear={false} picker='month' style={{ height: 32, width: 100, marginRight: 12, marginLeft: 'auto' }} placeholder='月' />
                            {/* <Select placeholder='范围'>
                                <Option value='本周'>本周</Option>
                            </Select> */}
                        </div>
                        <div className='box' id='box4'></div>
                    </div>
                </div>
            </div>
        )
    }
}