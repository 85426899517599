import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
// global.httpUrl = "http://192.168.0.87:8899";  //java本地
// global.httpUrl="https://tuoyu.hcfybjy.cn:11443/admin";  //java线上
global.httpUrl="/admin";  //java线上
// global.httpUrl="https://bjy.honghukeji.net/admin";  //php线上
// global.uploadUrl="https://tuoyu.hcfybjy.cn:11443";  //上传
global.uploadUrl="";  //上传
ReactDOM.render(
	<ConfigProvider locale={locale}>
		<App />
	</ConfigProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
