import React from 'react';
import { Input } from 'antd';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    onChange = e => {
        const { value } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.props.onChange(value);
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = () => {
        const { value, onBlur, onChange } = this.props;
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.' || value === '-') {
            valueTemp = value.slice(0, -1);
        }
        onChange(valueTemp.replace(/0*(\d+)/, '$1'));
        if (onBlur) {
            onBlur();
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.props.typee == 'number' ?
                    <Input
                        placeholder='请输入'
                        allowClear
                        {...this.props}
                        className={`pubInpt marginr12 ${this.props.className}`}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                    />
                :
                    <Input
                        placeholder='请输入'
                        prefix={(<span className='iconfont icon-sousuo marginr4'></span>)}
                        allowClear
                        {...this.props}
                        className={`pubInpt marginr12 ${this.props.className}`}
                    />
                }
            </React.Fragment>
        )
    }
}