import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox } from 'antd'
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            info: props.data,
        }
    }
    componentDidMount() {
        global.Ajax.hhtc_request('dayinspect/dayStudentItemList', { log_id: this.props.data.id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    list: res.data
                })
            }
        })
    }
    render() {
        let { info, list } = this.state;
        return (
            <div className='exportbox'>
                <h1 style={{ fontSize: 20, fontWeight: 'bold', color: '#2C2C2C', textAlign: 'center', fontFamily: '微软雅黑', marginBottom: 30 }}>{info.atime && info.atime.substring(0, 10)}{info.type==1?'晨':'午'}检详情</h1>
                <table id='table1' style={{ width: '100%', borderCollapse: 'collapse', verticalAlign: 'middle', fontFamily: '微软雅黑' }}>
                    <tbody>
                        <tr>
                            <td colSpan={9} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', borderRight: '1px solid #333', fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'center', height: '32px' }}>基本信息</td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', width: '80px' }}>姓名</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>{info.student_name}</td>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>性别</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>{Helper.getSex(info.idcard)}</td>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center' }}>年龄</td>
                            <td colSpan={2} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', borderRight: '1px solid #333' }}>{Helper.getAge(info.idcard)}岁</td>
                        </tr>
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>就读班级</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', borderRight: '1px solid #333' }}>{info.school_name}{info.class_name}</td>
                        </tr>
                        <tr>
                            <td colSpan={9} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', borderRight: '1px solid #333', fontSize: 14, fontWeight: 'bold', color: '#333', textAlign: 'center', height: '32px' }}>检查信息</td>
                        </tr>
                        {this.state.list.map(item => (
                            <tr key={String(item.id)}>
                                <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: item.status==0?'#f00':'#333', textAlign: 'center', height: '32px' }}>{item.name}</td>
                                <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: item.status==0?'#f00':'#333', borderRight: '1px solid #333', paddingLeft: 10 }}>
                                    {item.value}
                                    {item.tag_names&&item.tag_names!=''&&<span style={{paddingLeft: 6}}>({item.tag_names})</span>}
                                </td>
                            </tr>
                        ))}

                        {/* <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px' }}>手检</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10 }}>
                                <img alt='' src={require('../../imgs/gou.png').default} style={{ width: 16, marginRight: 4 }} />
                                <span>未见异常</span>
                                <span style={{ display: 'inline-block', width: 16, height: 16, border: '1px solid #000', marginRight: 4, marginLeft: 24, marginBottom: -3 }}></span>
                                <span>异常</span>
                            </td>
                        </tr> */}
                        <tr>
                            <td style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', textAlign: 'center', height: '32px', borderBottom: '1px solid #333', width: '200px' }}>检查人员&emsp;/&emsp;检查日期</td>
                            <td colSpan={8} style={{ borderLeft: '1px solid #333', borderTop: '1px solid #333', fontSize: 14, color: '#333', borderRight: '1px solid #333', paddingLeft: 10, borderBottom: '1px solid #333' }}>{info.teacher_name}&emsp;/&emsp;{info.atime}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}