import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, Switch,Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import AddProj from './AddProj';  //添加项目
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
            typeList: []
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '项目名称',
                dataIndex: 'name',
                align: 'center',
            }, {
                title: '项目类型',
                dataIndex: 'type_name',
                align: 'center',
                render: name => `${name || '-'}`
            }, {
                title: '录入类型',
                dataIndex: 'add_type',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.add_type == 1 && <Button type='primary' size='small'>选项</Button>}
                        {item.add_type == 2 && <Button type='primary' size='small' className='fu1'>数值</Button>}
                        {item.add_type == 3 && <Button type='primary' size='small' className='fu2'>文本</Button>}
                    </React.Fragment>
                )
            }, {
                title: '默认值',
                dataIndex: 'default_value',
                align: 'center',
            }, {
                title: '项目简介',
                dataIndex: 'interduce',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: (_, item) => (
                    <Tooltip title={item.interduce}>
                        <p className='xxxxx'>{item.interduce || '-'}</p>
                    </Tooltip>
                )
            }, {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                render: (_, item) => (
                    <Switch onClick={() => {
                        global.Ajax.hhtc_request('patrol/changeStatus', { id: item.id }).then(res => {
                            if (res.code == 1) {
                                this.refresh()
                                // item.status=item.status==1?0:1;
                            } else {
                                message.error(res.msg)
                            }
                        })
                    }} checked={item.status == 1 ? true : false} checkedChildren='启用' unCheckedChildren='禁用' />
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>编辑</p>
                        <p onClick={this.del.bind(this, item)}>删除</p>
                    </div>
                )
            }
        ]
    }
    componentDidMount() {
        // 获取分类
        global.Ajax.hhtc_request('patrol/getPatrolSelectLists', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    typeList: res.data,
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            type: this.state.type || '',
        }
        global.Ajax.hhtc_request('patrol/patrolList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        })
    }
    // 删除
    del(data) {
        var url = 'patrol/delPatrol', req = { id: data.id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入项目名称'
                        className='borderbai marginr12'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Select
                        options={this.state.typeList}
                        fieldNames={{ label: 'name', value: 'id' }}
                        placeholder='请选择项目类型'
                        bordered={false}
                        allowClear
                        style={{background: '#fff',width: 160}}
                        className='marginr12'
                        onChange={(type)=>{
                            this.setState({ type },()=>{
                                this.tabblelist.onRefresh()
                            })
                        }}
                    ></Select>
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加项目</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>检查项目列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加 */}
                <CustomModal
                    width={900}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}项目`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <AddProj
                        ref={dom => this.projRef = dom}
                        // checkTypeList={this.state.checkTypeList}
                        type={this.state.type}
                        editData={this.state.editData || {}}
                        refresh={() => {
                            this.onCancel()
                            this.refresh()
                        }}
                    />
                    {/* <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '84px' }}
                        labelWrap
                    >
                        <div className='flwp'>
                            <Form.Item
                                name='name'
                                label='项目名称'
                                rules={[{ required: true, message: '请输入项目名称！' }]}
                                className='item48'
                            >
                                <Input autoComplete='off' placeholder='请输入项目名称' />
                            </Form.Item>
                            <div className='item48 flexCenter' style={{ marginBottom: 24 }}>
                                <p style={{ color: '#666', width: 84, textAlign: 'right' }}><span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: 4, lineHeight: '40px' }}>*</span>检查类别：</p>
                                <Button type='primary'>添加类别</Button>
                            </div>
                            <div style={{ width: '100%', marginBottom: 24 }}>
                                <div className='flex' style={{overflow: 'hidden'}}>
                                    <p style={{ color: '#666', width: 84, textAlign: 'right' }}><span style={{ color: '#ff4d4f', fontFamily: 'SimSun, sans-serif', marginRight: 4, lineHeight: '32px' }}>*</span>类别列表：</p>
                                    <div className='pubList' style={{ flex: 1, margin: 0, height: 'auto' }}>
                                        <div className='head flex'>
                                            <p className='row15'>类别名称</p>
                                            <p className='row1'>类别说明</p>
                                            <p className='row15'>答案类型</p>
                                            <p className='row2'>答案</p>
                                            <p className='row1'>默认值</p>
                                            <p className='row15'>是否必填</p>
                                            <p className='rowFlex'>操作</p>
                                        </div>
                                        {[1, 1, 1, 1].map((item, index) => (
                                            <div className='flex' key={String(index)}>
                                                <p className='row15'>类别名称</p>
                                                <p className='row1'>类别说明</p>
                                                <p className='row15'>
                                                    <Button type='primary' size='small'>文本</Button>
                                                </p>
                                                <Tooltip title={'答案'}>
                                                    <p className='row2'>答案</p>
                                                </Tooltip>
                                                <p className='row1'>文本信息</p>
                                                <p className='row15 flexAllCenter'>
                                                    <Switch checkedChildren='是' unCheckedChildren='否' />
                                                </p>
                                                <div className='rowFlex flexAllCenter pubbtnbox'>
                                                    <p>编辑</p>
                                                    <p>删除</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <Form.Item
                                name='phone'
                                label='项目简介'
                                rules={[{ required: true, message: '请输入项目简介！' }]}
                                style={{ width: '100%' }}
                            >
                                <Input.TextArea rows={6} className='txtarea' autoComplete='off' placeholder='请输入项目简介' />
                            </Form.Item>
                            <Form.Item
                                name='school'
                                label='同步添加至学校'
                                rules={[{ required: true, message: '请选择学校！' }]}
                            >
                                <Transfer
                                    dataSource={this.state.mockData}
                                    showSearch
                                    targetKeys={this.state.targetKeys}
                                    render={item => item.title}
                                    operations={['添加', '删除']}
                                    onChange={this.handleChange}
                                    selectAllLabels={[({ selectedCount, totalCount }) => (
                                        <span>待选学校({totalCount}所)</span>
                                    ), ({ selectedCount, totalCount }) => (
                                        <span>已选学校({selectedCount}所)</span>
                                    )]}
                                />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form> */}
                </CustomModal>
            </React.Fragment>
        )
    }
    handleChange = (targetKeys, direction, moveKeys) => {
        this.setState({ targetKeys });
    };
    onFinish = (req) => {
        console.log(req)
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: ''
        })
    }
}