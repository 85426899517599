import React from 'react';
import { Button, Input, message, Tooltip, Form, Switch, Popconfirm } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import AddProj from '../CheckManage/AddProj';  //添加项目
import Helper from '../../class/Helper';
import ProjList from './ProjList';  //检查项目列表
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            visible: false,
            editData: {}
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '晨午检类型',
                dataIndex: 'type',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.type == 1 && <Button type='primary'>晨检</Button>}
                        {item.type == 2 && <Button type='primary' className='fu1'>午检</Button>}
                    </React.Fragment>
                )
            }, {
                title: '项目数',
                dataIndex: 'p_count',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.chahgeVisible.bind(this, 'visible', item)}>检查项目列表</p>
                    </div>
                )
            }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
        }
        global.Ajax.hhtc_request('dayinspect/getDayInspectList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    chahgeVisible(key, data) {
        this.setState({
            [key]: true,
            editData: data
        })
    }
    tongbu() {
        global.Ajax.hhtc_request('dayinspect/tongbu', {}).then(res => {
            if (res.code == 1) {
                message.success(res.msg, 1.2)
                this.refresh()
            } else {
                message.error(res.msg, 1.2)
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入学校名称'
                        className='borderbai marginr12'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Popconfirm
                        title='确定要同步所有项目？'
                        onConfirm={this.tongbu.bind(this)}
                    >
                        <Button type='primary'>同步所有项目</Button>
                    </Popconfirm>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>学校晨午检项目列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 检查项目列表 */}
                <CustomModal
                    width={900}
                    visible={this.state.visible}
                    title={`${this.state.editData.school_name}${this.state.editData.type == 1 ? '晨检' : '午检'}项目列表`}
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <ProjList
                        id={this.state.editData.school_id}
                        typeId={this.state.editData.type}
                        type='malist'
                        onCancel={this.onCancel.bind(this)}
                        onOk={this.onOk.bind(this)}
                    />
                </CustomModal>
                {/* 添加项目 */}
                <CustomModal
                    width={800}
                    visible={this.state.addVisible}
                    title='添加项目'
                    onCancel={this.onClose.bind(this)}
                >
                    <AddProj />
                </CustomModal>
            </React.Fragment>
        )
    }
    // 删除
    del(data) {
        var url = 'admin/delAdmin', req = { admin_id: data.admin_id }
        this.delmask.del(url, req)
    }
    onOk() {
        this.onCancel()
        this.refresh()
    }
    onCancel() {
        this.setState({
            visible: false,
            editData: {}
        })
    }
    onClose() {
        this.setState({
            addVisible: false,
        })
    }
}