import React from 'react';
import { Button, Input, message, Tooltip, Form, Select, Checkbox, Switch } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
const { Option } = Select;
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [
                { title: '选项一', key: 1 },
                { title: '选项一', key: 2 },
                { title: '选项一', key: 3 },
            ],
            targetKeys: [],
            jobList: [],
            job_id: 0
        }
    }
    componentDidMount() {
    }
    getAllJob() {
        global.Ajax.hhtc_request('admin/getSearchRoleList', {}).then(res => {
            if (res.code == 1) {
                // console.log(this.initData(res.data))
                this.setState({
                    jobList: this.initData(res.data),
                })
            }
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            arryNew.push(Object.assign({}, item, { value: item.id, label: item.name }))
        })
        return arryNew
    }
    refresh() {
        // this.getAllJob()
        this.tabblelist.onRefresh()
    }
    getList(page, callback) {
        var orderBy = '';
        if (this.state.orderBy != '') {
            orderBy = this.state.orderBy;
        }
        var req = {
            page: page,
            size: this.state.size,
            orderBy
        }
        global.Ajax.hhtc_request('admin/adminList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='row1'>姓名</p>
                <p className='rowFlex'>账号（手机号）</p>
                <p className='rowFlex'>所属单位</p>
                <p className='row1'>职务</p>
                {/* <p className='row1'>职务类型</p> */}
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <p className='row1'>{item.person || '-'}</p>
                        <p className='rowFlex'>{item.username}</p>
                        <Tooltip title={item.sup_name}>
                            <p className='rowFlex'>{item.sup_name}</p>
                        </Tooltip>
                        <p className='row1'>{item.job_name}</p>
                        {/* <p className='row1'>
                            {item.type == 1 && <Button type='primary'>管理人员</Button>}
                            {item.type == 2 && <Button type='primary' className='fu2'>体检医师</Button>}
                        </p> */}
                        <div className='row15 flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    // 编辑
    edit(data) {
        // 获取所有职务列表
        this.getAllJob()
        console.log(data)
        this.setState({
            editData: data,
            type: 'edit',
            addVisible: true,
        }, () => {
            data.password = '';
            setTimeout(() => {
                this.formRef.setFieldsValue(data)
            }, 100);
            // this.getAllJob(data.department_id, () => {
            //     this.formRef.setFieldsValue(data)
            // })
        })
    }
    // 删除
    del(data) {
        var url = 'admin/delAdmin', req = { admin_id: data.admin_id }
        this.delmask.del(url, req)
    }
    chahgeVisible(key) {
        // 获取所有职务列表
        this.getAllJob()
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加人员</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>人员列表</h2>
                    <TabbleList
                        ref={dom => this.tabblelist = dom}
                        size={this.state.size}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        renderHead={this.renderHead.bind(this)}  //表头
                        renderItem={this.renderItem.bind(this)}  //表内容
                    />
                </div>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加学校 */}
                <CustomModal
                    width={360}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}人员`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formRef = dom}
                        onFinish={this.onFinish}
                        labelCol={{ flex: '82px' }}
                        onValuesChange={this.onValuesChange}
                    >
                        <Form.Item
                            name='person'
                            label='姓名'
                            rules={[{ required: true, message: '请输入姓名！' }]}
                        >
                            <Input maxLength={11} autoComplete='off' placeholder='请输入姓名' />
                        </Form.Item>
                        <Form.Item
                            name='username'
                            label='手机号'
                            rules={[{ required: true, message: '请输入手机号！' }]}
                        >
                            <Input maxLength={11} autoComplete='off' placeholder='请输入手机号' />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label='登录密码'
                        // rules={[{ required: true, message: '请输入登录密码！' }]}
                        >
                            <Input.Password autoComplete='new-password' placeholder='非必填，默认密码为123456' />
                        </Form.Item>
                        <Form.Item
                            name='department_id'
                            label='所属单位'
                            rules={[{ required: true, message: '请选择所属单位！' }]}
                        >
                            <SelectList
                                type='allcompany'
                                // onChange={(id) => {
                                //     this.setState({
                                //         // jobList: [],
                                //         // job_id: null
                                //     }, () => {
                                //         // if (id) {
                                //         //     this.getAllJob(id)
                                //         // }
                                //     })
                                //     this.formRef.setFields([{ job_id: null }])
                                // }}
                                placeholder='请选择所属单位'
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.children ?? '').includes(input)
                                }
                            />
                        </Form.Item>
                        {/* <Form.Item name='type' label='职务类型' rules={[{ required: true, message: '请选择职务类型！' }]}>
                            <Select
                                options={[{ label: '管理人员', value: 1 }, { label: '体检医师', value: 2 }]}
                                placeholder='请选择职务类型'
                            ></Select>
                        </Form.Item> */}
                        <Form.Item
                            name='job_id'
                            label='职务'

                            rules={[{ required: true, message: '请选择职务！' }]}
                        >
                            <Select
                                placeholder='请选择职务'
                                suffixIcon={(<span className='iconfont icon-xia'></span>)}
                                allowClear
                                className={`pubSelt ${this.props.className}`}
                                value={this.state.job_id}

                            >
                                {this.state.jobList.map((item, index) => (
                                    <Option value={item.value * 1} key={'' + index}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item noStyle shouldUpdate={(pre, cur) => pre.type != cur.type}>
                            {({getFieldValue}) => {
                                return (
                                    getFieldValue('type')==1 && <Form.Item
                                        name='job_id'
                                        label='职务'

                                        rules={[{ required: true, message: '请选择职务！' }]}
                                    >
                                        <Select
                                            placeholder='请选择职务'
                                            suffixIcon={(<span className='iconfont icon-xia'></span>)}
                                            allowClear
                                            className={`pubSelt ${this.props.className}`}
                                            value={this.state.job_id}

                                        >
                                            {this.state.jobList.map((item, index) => (
                                                <Option value={item.value * 1} key={'' + index}>{item.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }}
                        </Form.Item> */}
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onValuesChange = (res) => {
        let key = (Object.keys(res))[0];
        if (key == 'username') {
            var value = Helper.getNumber(res[key]);
            this.formRef.setFieldsValue({
                [key]: value
            })
        }
    }
    handleChange = (targetKeys, direction, moveKeys) => {
        this.setState({ targetKeys });
    };
    onFinish = (req) => {
        console.log(req)
        this.setState({
            loading: true,
        }, () => {
            let url = 'admin/addAdmin';
            if (this.state.type == 'edit') {
                url = 'admin/editAdmin';
                req.admin_id = this.state.editData.admin_id;
            }
            req.password = req.password || '';
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
        })
    }
}