import React from 'react';
import { Button, Input, message, Tooltip, Form, Transfer, Checkbox, DatePicker, Select } from 'antd'
import SelectList from '../../common/SelectList';
import CustomInput from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import Tjxm from './Tjxm';
import AddPlan from './AddPlan';  //添加计划
import moment from 'moment'
import CustomTable from '../../common/Table';

const { Option } = Select;

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 10,
            orderBy: '',
            addVisible: false,
            mockData: [],
            targetKeys: [],
            cloneVisible: false,
            visible: false,
            status: '',
            projVisible: false,
            doctorList: [],
            projList: [],
            editData: {},
            schoolList: []
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                sorter: true,
                align: 'center',
                width: 80
            }, {
                title: '体检任务名称',
                dataIndex: 'name',
                align: 'center',
                ellipsis: {
                    showTitle: true,
                },
                render: (_, item) => (
                    <Tooltip title={item.name}>
                        <p>{item.name}</p>
                    </Tooltip>
                )
            }, {
                title: '体检单位名称',
                dataIndex: 'department_name',
                align: 'center',
            }, {
                title: '学校名称',
                dataIndex: 'school_name',
                align: 'center',
            }, {
                title: '体检日期',
                dataIndex: 'date',
                align: 'center',
            }, {
                title: '概要',
                dataIndex: 'atime',
                align: 'center',
                render: (_, item) => `应检查人数：${item.student_count}/已检查人数：${item.inspect_count}`
            }, {
                title: '状态',
                dataIndex: 'atime',
                align: 'center',
                render: (_, item) => (
                    <React.Fragment>
                        {item.status == 0 && <span className='color1'>待分配</span>}
                        {item.status == 1 && <span className='color2'>待开始</span>}
                        {item.status == 2 && <span className='color3'>进行中</span>}
                        {item.status == 3 && <span className='color4'>已结束</span>}
                    </React.Fragment>
                )
            }, {
                title: '操作',
                dataIndex: 'id',
                align: 'center',
                width: '15%',
                render: (_, item) => (
                    <div className='flexAllCenter pubbtnbox'>
                        {item.status == 0 && <React.Fragment>
                            {/* <p onClick={this.changeVisible.bind(this, 'visible', item)}>分配人员</p> */}
                            <p onClick={() => {
                                // this.getInfo(item.plan_id)
                                this.setState({
                                    visible: true,
                                    editData: item
                                }, () => {
                                    setTimeout(() => {
                                        this.getOtherInfo(item)
                                    }, 50);
                                })
                                let data = JSON.parse(JSON.stringify(item));
                                let users = [], targetKeys = [];
                                for (let i in data.dist_doc) {
                                    let obj = {
                                        doctor_id: data.dist_doc[i].doctor_id,
                                        inspect_items_ids: data.dist_doc[i].inspect_items_ids.split(',').map(Number),
                                    }
                                    users.push(obj)
                                    targetKeys.push(obj.inspect_items_ids)
                                }
                                this.setState({
                                    targetKeys
                                })
                                setTimeout(() => {
                                    this.formRef.setFieldsValue({
                                        date: item.date || undefined,
                                        users,
                                    })
                                }, 100);
                            }}>编辑</p>
                        </React.Fragment>}
                        {item.status != 0 && <p onClick={this.changeVisible.bind(this, 'projVisible', item)}>体检项目</p>}
                        <p onClick={this.changeVisible.bind(this, 'cloneVisible', item)}>克隆</p>
                        {item.status == 1 && <p onClick={this.del.bind(this, item)}>删除</p>}
                    </div>
                )
            }
        ]
    }
    getSchool(notice_id) {
        global.Ajax.hhtc_request('inspectnotice/addPlanGetSchool', { notice_id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    schoolList: res.data
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: '',
            pword: this.state.pword || '',
            school_id: this.state.school_id || '',
            status: this.state.status,
        }
        global.Ajax.hhtc_request('inspectnotice/schoolPlanlList', req).then(res => {
            callback(res)
        })
    }
    onRefresh(page, callback) {
        this.getList(page, callback)
    }
    renderHead() {
        return (
            <div className='head flex'>
                <p className='flexAllCenter cursor' style={{ width: 80 }}>
                    序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
                        var orderBy = this.state.orderBy;
                        if (orderBy == 'desc') {
                            orderBy = 'asc'
                        } else {
                            orderBy = 'desc'
                        }
                        this.setState({
                            orderBy
                        }, () => {
                            this.refresh()
                        })
                    }}></span>
                </p>
                <p className='rowFlex'>体检任务名称</p>
                <p className='rowFlex'>体检单位名称</p>
                <p className='row1'>学校名称</p>
                <p className='row1'>体检日期</p>
                <p className='rowFlex'>概要</p>
                <p className='row1'>状态</p>
                <p className='row15'>操作</p>
            </div>
        )
    }
    renderItem(data) {
        return (
            <React.Fragment>
                {data.map((item, index) => (
                    <div className='flex' key={'' + index}>
                        <p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
                        <Tooltip title={item.name}>
                            <p className='rowFlex'>{item.name}</p>
                        </Tooltip>
                        <p className='rowFlex'>{item.department_name}</p>
                        <p className='row1'>{item.school_name}</p>
                        <p className='row1'>{item.date || '-'}</p>
                        <Tooltip title={item.info}>
                            <p className='rowFlex'>应检查人数：{item.student_count}/已检查人数：{item.inspect_count}</p>
                        </Tooltip>
                        <p className='row1'>
                            {item.status == 0 && <span className='color1'>待分配</span>}
                            {item.status == 1 && <span className='color2'>待开始</span>}
                            {item.status == 2 && <span className='color3'>进行中</span>}
                            {item.status == 3 && <span className='color4'>已结束</span>}
                        </p>
                        <div className='row15 flexAllCenter pubbtnbox'>
                            {item.status == 0 && <React.Fragment>
                                {/* <p onClick={this.changeVisible.bind(this, 'visible', item)}>分配人员</p> */}
                                <p onClick={() => {
                                    // this.getInfo(item.plan_id)
                                    this.setState({
                                        visible: true,
                                        editData: item
                                    }, () => {
                                        setTimeout(() => {
                                            this.getOtherInfo(item)
                                        }, 50);
                                    })
                                    let data = JSON.parse(JSON.stringify(item));
                                    let users = [], targetKeys = [];
                                    for (let i in data.dist_doc) {
                                        let obj = {
                                            doctor_id: data.dist_doc[i].doctor_id,
                                            inspect_items_ids: data.dist_doc[i].inspect_items_ids.split(',').map(Number),
                                        }
                                        users.push(obj)
                                        targetKeys.push(obj.inspect_items_ids)
                                    }
                                    this.setState({
                                        targetKeys
                                    })
                                    setTimeout(() => {
                                        this.formRef.setFieldsValue({
                                            date: item.date || undefined,
                                            users,
                                        })
                                    }, 100);
                                }}>编辑</p>
                            </React.Fragment>}
                            {item.status != 0 && <p onClick={this.changeVisible.bind(this, 'projVisible', item)}>体检项目</p>}
                            <p onClick={this.changeVisible.bind(this, 'cloneVisible', item)}>克隆</p>
                            {item.status == 1 && <p onClick={this.del.bind(this, item)}>删除</p>}
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
    del(data) {
        let url = 'inspectnotice/delPlan', req = { plan_id: data.plan_id };
        this.delmask.del(url, req)
    }
    changeVisible(key, data) {
        if (key == 'cloneVisible') {
            this.getSchool(data.notice_id)
        }
        this.setState({
            [key]: true,
            editData: data,
        }, () => {

        })
    }
    getOtherInfo(data) {
        if (this.btnRef) {
            // 获取体检项目列表
            global.Ajax.hhtc_request('inspectnotice/addGetNoticeInspect', { notice_id: data.notice_id, }).then(res => {
                if (res.code == 1) {
                    let arry = res.data;
                    let arryNew = []
                    arry.map(item => {
                        arryNew.push(Object.assign({}, { key: item.id, title: item.name }))
                    })
                    let projList = [];
                    projList[0] = arryNew;
                    this.setState({
                        mockData: arryNew,
                        projList
                    })
                }
            })
            // 获取体检人员
            global.Ajax.hhtc_request('inspectnotice/getDoctor', { department_id: data.department_id }).then(res => {
                if (res.code == 1) {
                    let arry = res.data;
                    let arryNew = []
                    arry.map(item => {
                        arryNew.push(Object.assign({}, { value: item.admin_id, label: item.person }))
                    })
                    let doctorList = [];
                    doctorList[0] = arryNew;
                    let list = this.formRef.getFieldValue('users');
                    if (list.length > 1) {
                        for (let i in list) {
                            if (list[i] && i > 0) {
                                let m = i * 1 - 1;
                                let data = JSON.parse(JSON.stringify(doctorList[m]));
                                let index = data.findIndex(item => item.value == list[m].doctor_id);
                                data.splice(index, 1);
                                doctorList[i] = data
                            }
                        }
                    }
                    console.log(doctorList)
                    this.setState({
                        userData: arryNew,
                        doctorList
                    })
                }
            })
            // 
            this.btnRef.click()
        }
        // 获取克隆学校列表
        if (this.formCloneRef) {
            global.Ajax.hhtc_request('inspectnotice/getCloneSchool', { notice_id: data.notice_id }).then(res => {
                if (res.code == 1) {
                    let arry = res.data;
                    let arryNew = []
                    arry.map(item => {
                        arryNew.push(Object.assign({}, { value: item.plan_id, label: item.school_name }))
                    })
                    this.setState({
                        schoolList: arryNew
                    })
                }
            })
        }
    }
    handleChange(index, keys, direction, moveKeys) {
        let projList = JSON.parse(JSON.stringify(this.state.projList)) || [];
        let data = JSON.parse(JSON.stringify(this.state.mockData));
        let targetKeys = JSON.parse(JSON.stringify(this.state.targetKeys));
        let allKeys = this.state.targetKeys;
        let newData = [];
        let newList = [];
        allKeys[index] = keys;
        // 先拿到所有未选的项
        for (let i in allKeys) {
            for (let j in allKeys[i]) {
                let m = data.findIndex(item => item.key == allKeys[i][j]);
                data.splice(m, 1)
            }
        }
        // 再获取每个体检人员已选的项
        for (let i in allKeys) {
            newList[i] = [];
            for (let j in allKeys[i]) {
                let row = this.state.mockData.find(item => item.key == allKeys[i][j]);
                if (row) {
                    newList[i].push(row)
                }
            }
            // 合并已选择的项和所有未选的项
            let arrnew = newList[i].concat(data);
            newList[i] = arrnew;
        }
        newData = JSON.parse(JSON.stringify(newList[index]));
        for (let i in keys) {
            let m = newData.findIndex(item => item.key == keys[i]);
            newData.splice(m, 1)
        }
        let ii = index * 1 + 1;
        if (!newList[ii]) {
            newList[ii] = newData;
        }
        this.setState({
            targetKeys: allKeys,
            projList: newList,
        });
    }
    add = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.plan_id = this.state.editData.plan_id;
            req.date = req.date.format('YYYY-MM-DD');
            req.dist_doc = [];
            for (let i in req.users) {
                // let doctor_name = (this.state.userData.find(item => item.value == req.users[i].doctor_id)).label;
                let obj = {
                    // plan_id: this.state.editData.plan_id,
                    doctor_id: req.users[i].doctor_id,
                    // date: req.date,
                    inspect_items_ids: req.users[i].inspect_items_ids.join('-'),
                    // doctor_name
                }
                req.dist_doc.push(obj)
            }
            // delete req.date;
            delete req.users;
            // req.datas=JSON.stringify(req.datas)
            console.log(req)
            global.Ajax.hhtc_request('inspectnotice/editPlan', req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    onValuesChange = (res) => {
        let key = Object.keys(res)[0];
        let list = res[key];
        let doctorList = JSON.parse(JSON.stringify(this.state.doctorList)) || [];
        if (key == 'users') {
            let data = [];
            for (let i in list) {
                if (list[i]) {
                    let keyy = Object.keys(list[i])[0];
                    let m = i * 1 + 1;
                    if (keyy == 'doctor_id') {  //体检人员
                        data = JSON.parse(JSON.stringify(doctorList[i]));
                        let index = data.findIndex(item => item.value == list[i].doctor_id);
                        data.splice(index, 1);
                        doctorList[m] = data
                    }
                }
            }
            if (doctorList.length > 0) {
                this.setState({
                    doctorList
                })
            }
        }
    }
    chahgeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <CustomInput
                        placeholder='请输入体检任务名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                pword: e.target.value
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        type='allschool'
                        placeholder='请选择学校'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(school_id) => {
                            this.setState({
                                school_id,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <SelectList
                        data={[
                            { value: 0, label: '待分配' },
                            { value: 1, label: '待开始' },
                            { value: 2, label: '进行中' },
                            { value: 3, label: '已结束' },
                        ]}
                        placeholder='请选择状态'
                        className='marginr12 borderbai'
                        style={{ width: 160 }}
                        onChange={(status) => {
                            status = status == undefined ? '' : status;
                            this.setState({
                                status,
                            }, () => {
                                this.tabblelist.onRefresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={this.chahgeVisible.bind(this, 'addVisible')}>添加计划</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>体检计划安排列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 分配人员 */}
                <CustomModal
                    width={720}
                    title={`${this.state.editData.school_name}的计划安排`}
                    visible={this.state.visible}
                    onCancel={this.onCancel.bind(this)}
                    className='addbyybox'
                >
                    <Form
                        labelCol={{ flex: '82px' }}
                        onFinish={this.add}
                        onValuesChange={this.onValuesChange}
                        ref={dom => this.formRef = dom}
                    >
                        <div className='pubovao'>
                            <Form.Item
                                name='date'
                                label='体检日期'
                                rules={[{ required: true, message: '请选择体检日期！' }]}
                                className='item48'
                            >
                                <DatePicker style={{ width: '100%' }} placeholder='请选择体检日期' />
                            </Form.Item>
                            <Form.List name='users'>
                                {(fields, { add, remove }) => (
                                    <React.Fragment>
                                        {fields.map(({ key, name, ...restField }) => {
                                            let list = this.state.doctorList[name] || [];
                                            return (
                                                <React.Fragment key={String(key)}>
                                                    <div className='flex'>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'doctor_id']}
                                                            label='体检人员'
                                                            rules={[{ required: true, message: '请选择体检人员！' }]}
                                                            className='item48'
                                                        >
                                                            <Select
                                                                placeholder='请选择体检人员'
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.children == input || option.children.indexOf(input) != -1
                                                                }
                                                            >
                                                                {list.map((item, index) => (
                                                                    <Option key={String(index)} value={item.value}>{item.label}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <span style={{ fontSize: 20 }} className='iconfont icon-tianjia2' onClick={() => add()}></span>
                                                        {key > 0 && <span style={{ fontSize: 20, marginLeft: 12 }} className='iconfont icon-jianshao' onClick={() => {
                                                            // 删除当前行
                                                            remove(name)
                                                            // 如果选了值，删除值
                                                            let targetKeys = this.state.targetKeys;
                                                            this.handleChange(name, [], 'left', targetKeys[name])
                                                        }}></span>}
                                                    </div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'inspect_items_ids']}
                                                        label='体检项目'
                                                        rules={[{ required: true, message: '请选择体检项目！' }]}
                                                    >
                                                        <Transfer
                                                            // dataSource={this.state.projList[name]}
                                                            dataSource={this.state.mockData}
                                                            showSearch
                                                            targetKeys={this.state.targetKeys[name]}
                                                            render={item => item.title}
                                                            operations={['添加', '删除']}
                                                            onChange={this.handleChange.bind(this, name)}
                                                            selectAllLabels={[({ selectedCount, totalCount }) => (
                                                                <span>可选项目({totalCount}个)</span>
                                                            ), ({ selectedCount, totalCount }) => (
                                                                <span>已选项目({this.state.targetKeys[name] && this.state.targetKeys[name].length || 0}个)</span>
                                                            )]}
                                                        />
                                                    </Form.Item>
                                                </React.Fragment>
                                            )
                                        })}
                                        {fields.length == 0 && <span ref={dom => this.btnRef = dom} className='iconfont icon-tianjia2' onClick={() => add()}></span>}
                                    </React.Fragment>
                                )}
                            </Form.List>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto', marginTop: 10 }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 克隆 */}
                <CustomModal
                    width={480}
                    title='克隆计划'
                    visible={this.state.cloneVisible}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        ref={dom => this.formCloneRef = dom}
                        onFinish={this.clone}
                    >
                        <Form.Item
                            name='school_id'
                            label='体检学校'
                            rules={[{ required: true, message: '请选择学校！' }]}
                        >
                            <Select
                                options={this.state.schoolList || []}
                                placeholder='请选择学校'
                                fieldNames={{ label: 'name', value: 'id' }}
                            ></Select>
                        </Form.Item>
                        <Form.Item label='体检日期' name='date' rules={[{ required: true, message: '请选择体检日期！' }]}>
                            <DatePicker style={{ width: '100%' }} disabledDate={(current) => {
                                return current && current < moment().startOf('day');
                            }} />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
                {/* 添加 */}
                <CustomModal
                    width={720}
                    title={`${this.state.type == 'edit' ? '编辑' : '添加'}计划`}
                    visible={this.state.addVisible}
                    onCancel={this.onCancel.bind(this)}
                    className='addprojbox'
                >
                    <AddPlan ref={dom => this.planRef = dom} onOk={() => {
                        this.onCancel()
                        this.refresh()
                    }} />
                </CustomModal>
                {/* 体检项目列表 */}
                <CustomModal
                    visible={this.state.projVisible}
                    title={`${this.state.editData.name}项目列表`}
                    width={900}
                    onCancel={this.onCancel.bind(this)}
                    className='infoboxx addprojbox'
                >
                    <Tjxm
                        id={this.state.editData.plan_id}
                    />
                    <Button onClick={this.onCancel.bind(this)} type='primary' style={{ display: 'block', margin: '30px 0 0 auto' }}>确定</Button>
                </CustomModal>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
            </React.Fragment>
        )
    }
    // 克隆
    clone = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.plan_id = this.state.editData.plan_id;
            req.date = req.date ? req.date.format('YYYY-MM-DD') : '';
            // req.school_id=this.state.editData.school_id;
            global.Ajax.hhtc_request('inspectnotice/cloneDoc', req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.5)
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.5)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    onFinish = (req) => {
        console.log(req)
    }
    onCancel() {
        this.setState({
            addVisible: false,
            targetKeys: [],
            editData: {},
            type: '',
            cloneVisible: false,
            visible: false,
            projVisible: false,
            schoolList: undefined
        })
    }
}